import {PreloadProps} from "./preloadScene";

class EndScene extends Phaser.Scene {

    victoryMusic: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    opts: PreloadProps;

    constructor() {
        super({ key: 'EndScene' });
    }

    init(opts: PreloadProps) {
      this.opts=opts
    }

    preload() {
        // Load any assets for the scene here (e.g., background images, music, etc.)
        this.load.image('background', '/images/ilmenskie.png'); // Replace with your asset path
        this.loadAudioURL('/sounds/victory', 'victoryMusic')
    }

    create() {
        // Add background
        const height = this.cameras.main.height;
        const width = this.cameras.main.width;
        const bg = this.add.image(width/2, height/2, 'background');
        
        // Scale image to fill height while maintaining aspect ratio
        const scale = height / bg.height;
        bg.setScale(scale);
        // Play victory music
        this.victoryMusic = this.sound.add('victoryMusic');
        this.victoryMusic.play({ loop: true });

        // Add congratulatory text
        const titleText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY - 100, 'Congratulations!', {
            fontSize: '64px',
            fontFamily: 'Arial',
            color: '#FFFFFF',
            fontStyle: 'bold',
        }).setOrigin(0.5);

        const subtitleText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, 'You have completed the game!', {
            fontSize: '32px',
            fontFamily: 'Arial',
            color: '#FFFF99',
        }).setOrigin(0.5);

        // Add buttons
        const replayButton = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY + 100, 'Play Again', {
            fontSize: '28px',
            fontFamily: 'Arial',
            color: '#00FF00',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 },
        }).setOrigin(0.5).setInteractive();

        const exitButton = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY + 150, 'Exit', {
            fontSize: '28px',
            fontFamily: 'Arial',
            color: '#FF0000',
            backgroundColor: '#000000',
            padding: { x: 10, y: 5 },
        }).setOrigin(0.5).setInteractive();

        // Replay button interaction
        replayButton.on('pointerdown', () => {
            this.victoryMusic.stop();
            this.scene.start('PreloadScene',this.opts);
        });

        // Exit button interaction
        exitButton.on('pointerdown', () => {
            this.victoryMusic.stop();
            // navigate to /#/games
            window.location.href = '/#/games';
        });

        // Add some simple animations for text
        this.tweens.add({
            targets: [titleText],
            scale: { from: 1, to: 1.2 },
            duration: 1000,
            yoyo: true,
            repeat: -1,
        });

        this.tweens.add({
            targets: [subtitleText],
            alpha: { from: 1, to: 0.5 },
            duration: 1000,
            yoyo: true,
            repeat: -1,
        });
    }

    async loadAudioURL(url: string, key: string){
      try {
        const binkey = `bin-${key}`;

        this.load.binary(binkey, url, Uint8Array);

        this.load.on(`filecomplete-binary-${binkey}`, (k: any, type: any, data: BlobPart) => {
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          console.log('Blob URL:', blobUrl);
          this.load.audio(key, blobUrl)
          this.load.off(`filecomplete-binary-${binkey}`);
        });
      } catch (error) {
        console.error('Error fetching and creating Blob URL:', error);
      }
    }


    update() {
        // Optional: Add dynamic background effects (e.g., stars, particle effects)
    }
}

export default EndScene;
