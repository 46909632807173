import {GlitchDecorable} from 'components/glitch/space';
import {CLICK_EVENT, COLLISION_END_EVENT, COLLISION_START_EVENT, HOVER_EVENT} from 'game/game_constants';
import {CATEGORY_PLATFORM} from 'game/scenes/mainScene';
import Phaser from 'phaser';
import {performAction} from './deco';

export function applyEventActions(event_actions, offset_x,offset_y){
  if(!event_actions) return

  event_actions.forEach(({event,action,parameters}) => {
    if(event == CLICK_EVENT){
      this.setInteractive();
      this.on('pointerdown', () => {
        performAction(this, action,parameters, [offset_x,offset_y])
      })
    }

    if(event == HOVER_EVENT){
      this.setInteractive();
      this.on('pointerover', () => {
        performAction(this, action,parameters, [offset_x,offset_y])
      })
    }

    if(event == COLLISION_START_EVENT){
      this.setCollisionable();
      this.collisionHandlers.push(({bodyA, bodyB}) => {
        if(bodyA === this.body || bodyB === this.body){
          performAction(this, action,parameters, [offset_x,offset_y])
        }
      })
    }

    if(event == COLLISION_END_EVENT){
      this.setCollisionable();
      this.collisionEndHandlers.push(({bodyA, bodyB}) => {
        if(bodyA === this.body || bodyB === this.body){
          performAction(this, action,parameters, [offset_x,offset_y])
        }
      })
    }
  })

}


export default class Decorable extends Phaser.GameObjects.Sprite  {

  public offset_x: number
  public offset_y: number
  public decorable: GlitchDecorable

  collisionHandlers: Array<any> = [];
  collisionEndHandlers: Array<any> = [];
  isPlaying: any
  applyEventActions=applyEventActions;

  constructor(scene: Phaser.Scene, decorable: GlitchDecorable, offset_x: number, offset_y: number) {
    super(scene, decorable.x+offset_x, decorable.y+offset_y, decorable.filename || 'goal')
    this.offset_x = offset_x;
    this.offset_y = offset_y;
    this.decorable = decorable;
    this.setOrigin(0.5, 1)

    let key;
    if(decorable.sprites){
      key = `${decorable.filename}-0`;
      //const s = 'walk'; //Object.keys(decorable.sprites[0].map.sequences)[0];
      const s = 'right'; //playerData.meta.frameTags.find(({name}) => name == 'right').name;
      const playerData = scene.cache.json.get(key);

      const xx=this.anims.createFromAseprite(key,[s])

      let walkAnimation = this.anims.get(s);
      walkAnimation.repeat = -1;
      this.anims.play('right');
    } else {
      key = decorable.filename;
    }

    //const {height:naturalHeight,width:naturalWidth} = scene.textures.get(key).getSourceImage()

    const flipY = decorable.w < 0;
    const flipX = decorable.h < 0;
    const absWidth = Math.abs(decorable.w);
    const absHeight = Math.abs(decorable.h);

    this.displayWidth=absWidth;
    this.displayHeight=absHeight;

    this.doPosition();

    this.setAngle((-decorable.r||0));
    this.setFlipX(flipX || decorable.h_flip);
    this.setFlipY(flipY || decorable.v_flip);

    this.applyEventActions(decorable.event_actions||[], offset_x, offset_y);
  }

  doPosition(offsetX=0,offsetY=0){
    this.x = this.decorable.x + this.offset_x + offsetX;
    this.y = this.decorable.y + this.offset_y + offsetY

    const absWidth = Math.abs(this.decorable.w);
    const absHeight = Math.abs(this.decorable.h);

    //if(this.decorable.sprites){
    //  const key = `${this.decorable.filename}-0`;
    //  const {height:naturalHeight,width:naturalWidth} = this.scene.textures.get(key).getSourceImage()
    //  this.scaleX = absWidth/naturalWidth;
    //  this.scaleY = absHeight/naturalHeight;
    //} else {
      this.displayWidth=absWidth;
      this.displayHeight=absHeight;
    //}
  }

  setCollisionable() {
    // account for the setOrigin 0.5,1 on the deco
    const b = this.scene.matter.add.rectangle(this.x, this.y - this.displayHeight/2, this.displayWidth, this.displayHeight, {
      isStatic: true,
      isSensor: true,
      label: 'deco',
      collisionFilter: {
        category: CATEGORY_PLATFORM,
      },
      onCollideCallback: ({bodyA, bodyB}) => {
        console.log('AQUI collision', bodyA, bodyB)
        this.collisionHandlers.forEach(handler => handler({bodyA, bodyB}))
      },
      onCollideEndCallback: ({bodyA, bodyB}) => {
        console.log('AQUI collision end', bodyA, bodyB)
        this.collisionEndHandlers.forEach(handler => handler({bodyA, bodyB}))
      }
    });

    // TODO Phaser.GameObjects.Sprite invokes destroy on the body when the sprite is destroyed
    const x = b as any;
    x.destroy = x.destroy || (() => {})

    this.body=b;

  }


}
