import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {useDesignContext} from 'contexts/design_context';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@material-ui/core';
import {Games } from 'api/agent';
import {SoundAttrs} from 'models/sound';
import {VideoAttrs} from 'models/video';
import {GlitchEventAction, GlitchSignpost} from './glitch/space';
import {SpaceAttributes} from 'models/space';
import {useDispatch, useSelector} from 'react-redux';
import SelectTextModal from './texts/select_text_modal';
import SelectSoundModal from './sounds/select_sound_modal';
import SelectVideoModal from './videos/select_video_modal';
import SelectWebResourceModal from './web_resources/select_web_resource_modal';
import {APPLY_EFFECT, CLICK_EVENT, COLLISION_END_EVENT, COLLISION_START_EVENT, HOVER_EVENT, PLAY_SOUND_ACTION, PLAY_VIDEO_ACTION, SHOW_TEXT_ACTION,SHOW_WEB_RESOURCE_ACTION, TRAVEL_ACTION} from 'game/game_constants';
import {effects} from 'game/effects';
import {WebResourceAttributes} from 'models/web_resource';
import {T} from './utils/t';
import { TextAttrs } from 'models/text';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  card: {
    width: '100%',
    maxWidth: 400,
  },
  songInfo: {
    marginBottom: theme.spacing(1),
  },
  selectButton: {
    marginTop: theme.spacing(1),
  },

  buttonGroup: {
    marginTop: theme.spacing(2),
  },
}));

const ConfigureEffect = ({classes, eventConfig, setEventConfig}:any & {setEventConfig:any, eventConfig: GlitchEventAction}) => {

  return (<>
  <Typography variant="h6"><T k="actions_config.select_effect.title">Select Effect</T></Typography>
  <FormControl className={classes.formControl} fullWidth>
    <InputLabel id="effect-select-label">Effect</InputLabel>
    <Select labelId="effect-select-label" value={eventConfig.parameters?.effect || ''} onChange={(event) => setEventConfig({ ...eventConfig, parameters: {effect: event.target.value} })}>
      {Object.keys(effects).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</>)

}

const SelectSound = ({classes, eventConfig, setEventConfig}:any & {setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const [selectedSong, setSelectedSong] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedSong(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            <T k="actions_config.select_sound.selected_sound">Selected Song</T>
          </Typography>
          {selectedSong ? (
            <div>
              <Typography variant="subtitle1" className={classes.songInfo}>
                {selectedSong.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedSong.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Duration: {selectedSong.duration} sec
              </Typography>
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No song selected
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>setModalOpen(true)}
            className={classes.selectButton}
          >
            {selectedSong ? 'Change Song' : 'Select Song'}
          </Button>
        </CardActions>
      </Card>

      <SelectSoundModal
        open={modalOpen}
        onClose={()=>setModalOpen(false)}
        onSelect={(sound: SoundAttrs) => {
          setEventConfig({ ...eventConfig, parameters: sound });
          setModalOpen(false)
        }}
      />
  </FormControl>
  </>)
}

const SelectVideo = ({classes, eventConfig, setEventConfig,createVideo}:any & {classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const videos:Array<VideoAttrs> = useSelector((state:any) => state.editor?.videos);
  const [selectedVideo, setSelectedVideo] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedVideo(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
    <Card className={classes.card}>
      <CardContent>
        {selectedVideo ? (
          <div>
            <Typography variant="subtitle1" className={classes.songInfo}>
              {selectedVideo.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedVideo.description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Duration: {selectedVideo.duration} sec
            </Typography>
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No video selected
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setModalOpen(true)}
          className={classes.selectButton}
        >
          {selectedVideo ? 'Change Video' : 'Select Video'}
        </Button>
      </CardActions>
    </Card>

    <SelectVideoModal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      onSelect={(video: VideoAttrs) => {
        setEventConfig({ ...eventConfig, parameters: video });
        setModalOpen(false)
      }}
    />

</FormControl>
</>)
}

const SelectWebResource = ({classes, eventConfig, setEventConfig}:any & {classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const web_resources:Array<VideoAttrs> = useSelector((state:any) => state.editor?.web_resources);
  const [selectedWebResource, setSelectedWebResource] = useState<SoundAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedWebResource(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
    <Card className={classes.card}>
      <CardContent>
        {selectedWebResource ? (
          <div>
            <Typography variant="subtitle1" className={classes.songInfo}>
              {selectedWebResource.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedWebResource.description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              URL: {selectedWebResource.url} sec
            </Typography>
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No web resource selected
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setModalOpen(true)}
          className={classes.selectButton}
        >
          {selectedWebResource ? 'Change Web Resource' : 'Select Web Resource'}
        </Button>
      </CardActions>
    </Card>

    <SelectWebResourceModal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      onSelect={(web_resource: WebResourceAttributes) => {
        setEventConfig({ ...eventConfig, parameters: web_resource });
        setModalOpen(false)
      }}
    />

</FormControl>
</>)
}

const SelectText = ({classes, eventConfig, setEventConfig}:any & {classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const [selectedText, setSelectedText] = useState<TextAttrs | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(eventConfig.parameters?.id){
      setSelectedText(eventConfig.parameters)
    }
  }, [eventConfig.parameters])

  return (<>
  <FormControl className={classes.formControl} fullWidth>
    <Card className={classes.card}>
      <CardContent>
        {selectedText ? (
          <div>
            <Typography variant="subtitle1" className={classes.songInfo}>
              {selectedText.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedText.description}
            </Typography>
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No text selected
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setModalOpen(true)}
          className={classes.selectButton}
        >
          {selectedText ? 'Change Text' : 'Select Text'}
        </Button>
      </CardActions>
    </Card>

    <SelectTextModal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      onSelect={(text: TextAttrs) => {
        setEventConfig({ ...eventConfig, parameters: text });
        setModalOpen(false)
      }}
    />

</FormControl>
</>)
}

export type SpotAttrs = {
  space: SpaceAttributes
  signpost: GlitchSignpost
}

const SelectSpot = ({classes, eventConfig, setEventConfig}:{classes:any,setEventConfig:any, eventConfig: GlitchEventAction}) => {

  const {game} = useSelector((state:any) => state.editor)

  const [spots, setSpots] = useState<SpotAttrs[]>([]);

  useEffect(() => {
    Games.list_spots(game.uuid).then((spots) => {
      setSpots(spots.data);
    });
  }, []);

  return (<>
  <Typography variant="h6"><T k="actions_config.select_spot.title">Select Spot</T></Typography>
  <FormControl className={classes.formControl} fullWidth>
    <InputLabel id="spot-select-label">Spot</InputLabel>
    <Select labelId="spot-select-label" value={eventConfig.parameters?.signpost?.id || ''} onChange={(event) => setEventConfig({ ...eventConfig, parameters: spots.find(({signpost:{id}})=>id==event.target.value) })}>
      {spots.map((spot) => (
        <MenuItem key={spot.signpost.id} value={spot.signpost.id}>
          {spot.space.name} / {spot.signpost.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</>)
}

const EventActionConfig = ({classes, eventConfig, setEventConfig, onRemove, events, actions}:any) => {

  const handleEventChange = (event) => {
    setEventConfig({ ...eventConfig, event: event.target.value });
  };

  const handleActionChange = (event) => {
    setEventConfig({ ...eventConfig, action: event.target.value });
  };

  events = events || [ CLICK_EVENT, HOVER_EVENT, COLLISION_START_EVENT, COLLISION_END_EVENT];

  actions = actions || [
    PLAY_SOUND_ACTION,
    APPLY_EFFECT,
    PLAY_VIDEO_ACTION,
    SHOW_WEB_RESOURCE_ACTION,
    SHOW_TEXT_ACTION,
    TRAVEL_ACTION,
  ]

  return (<>
  <Card elevation={6} variant='outlined'>

    <CardHeader title={<T k="actions_config.panel.title">Configure Object Event</T>} titleTypographyProps={{variant:'h6'}}>
    </CardHeader>

    <CardContent>

    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="event-select-label"><T k="actions_config.event.label">Event</T></InputLabel>
      <Select labelId="event-select-label" value={eventConfig.event || ''} onChange={handleEventChange} >
        {events.map((event) => (
          <MenuItem key={event} value={event}><T k={`actions_config.buttons.events.${event}`}>{event}</T></MenuItem>
        ))}
      </Select>
    </FormControl>

    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="action-select-label"><T k="actions_config.action.label">Action</T></InputLabel>
      <Select
        labelId="action-select-label"
        value={eventConfig.action || ''}
        onChange={handleActionChange}
      >
        {actions.map((action) => (
          <MenuItem key={action} value={action}><T k={`actions_config.buttons.actions.${action}`}>{action}</T></MenuItem>
        ))}

      </Select>
    </FormControl>

    {eventConfig.action === PLAY_SOUND_ACTION && 
      <SelectSound classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === APPLY_EFFECT && 
      <ConfigureEffect classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === PLAY_VIDEO_ACTION &&
      <SelectVideo classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === SHOW_WEB_RESOURCE_ACTION &&
      <SelectWebResource classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === SHOW_TEXT_ACTION &&
      <SelectText classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig}/>
    }

    {eventConfig.action === TRAVEL_ACTION &&
      <SelectSpot classes={classes} eventConfig={eventConfig} setEventConfig={setEventConfig} />
      }
    </CardContent>
    <CardActions>
      <Button onClick={onRemove} color="secondary"><T k="actions_config.buttons.remove">Remove</T></Button>
    </CardActions>
    </Card>
  </>)

}

const ActionsConfig = ({event_actions, handleSave, events, actions}:any) => {

  const [eventActions, setEventActions] = useState(event_actions||[]);
  const classes=useStyles();

  const handleClear = () => {
    setEventActions([]);
  };

  const addEventAction = () => {
    setEventActions([...eventActions, {
      id: v4(),
      event: COLLISION_START_EVENT,
      action: PLAY_SOUND_ACTION,
      parameters: {}
    }]);
  }

  return (<>

  {eventActions.map((eventConfig, index) => (
    <EventActionConfig
      key={eventConfig.id}
      eventConfig={eventConfig}
      classes={classes}
      events={events}
      actions={actions}
      setEventConfig={(newConfig) => {
      setEventActions((oldActions) => [...oldActions.slice(0, index), newConfig, ...oldActions.slice(index + 1)]);
    }} onRemove={() => {
      setEventActions((oldActions) => [...oldActions.slice(0, index), ...oldActions.slice(index + 1)]);
    }} />
  ))}

  <div className={classes.buttonGroup}>
    <Button variant="contained" color="primary" onClick={addEventAction}>
      <T k="actions_config.buttons.add">Add</T>
    </Button>
    <Button variant="contained" color="primary" onClick={(e)=>handleSave(eventActions)}>
      <T k="actions_config.buttons.save_configuration">Save Configuration</T>
    </Button>
    <Button variant="outlined" color="secondary" onClick={handleClear} style={{ marginLeft: 8 }} >
      <T k="actions_config.buttons.clear_configuration">Clear Configuration</T>
    </Button>
  </div>

</>);
}

export default ActionsConfig;
