import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import {T} from 'components/utils/t';

function TransparentPaperComponent(props: PaperProps) {
  return (
    <Paper {...props} style={{background: 'transparent', boxShadow: 'none'}}/>
  );
}

export default function Intro({logo_url, handleNext, idx}) {

  return <>
    <Helmet>
      <title>{`Lets create a game`}</title>
    </Helmet>

  <Dialog
    BackdropProps={{ style: { display: 'none' } }}
    disableEscapeKeyDown
    className="creatix intro"
    open={true}
    onClose={()=>void 0}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="md"
    fullWidth={true}
    PaperComponent={TransparentPaperComponent}
  >
  <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
  </DialogTitle>
  <DialogContent>
      <div className="row">
        <div className="col-6 offset-3 col-md-3 offset-md-1">
          <img className="img-fluid" src={logo_url}></img>
        </div>
        <div className="col-12 col-md-6 offset-md-1">
          <h1><T k='creatix.title.h1'>Vamos a crear un juego!</T></h1>
          <p><T k='creatix.title.subtitle'>Piensa en alguna escena adecuada para un videojuego de aventuras</T></p>
          <div className="btn btn-white" onClick={()=>handleNext(idx+1)}>
            <T k='creatix.title.button'>Vamos!</T>
          </div>
        </div>
      </div>
  </DialogContent>

</Dialog>
</>
}

