import CanvasController from './CanvasController';
import BaseHandler from './BaseHandler';
import {FabricEvent, FabricObject} from '../utils';
import { v4 } from 'uuid';
import {linesStyles, loadFabricImage2 } from 'components/glitch/utils';
import {GlitchAsset} from 'models/glitch/asset';
import {setGameData, setOpenDoors, setOpenProperties} from 'redux/reducers/editor';
import {store} from 'redux/store';
import {SpotAttrs} from 'components/actions_config';
import {GlitchSignpost} from 'components/glitch/space';

/**
 * Game Handler Class
 *
 * @class GameHandler
 */
class GameHandler extends BaseHandler {

  public handleSelectDoor(spot:SpotAttrs, name:string, config:{password?:string, passwordQuestion?:string, isEndOfGame?:boolean}) {
    const {password, passwordQuestion, isEndOfGame}=config;

    const obj:any = this.controller.canvas.getActiveObject();

    if(obj.glitch_type != 'signpost')return;

    const signpost = obj.item as GlitchSignpost;
    const connects = [];

    if(spot){
      connects.push({
        space_id: spot.space.uuid,
        signpost_id: spot.signpost.id,
        label: spot.signpost.name,
        x: spot.signpost.x,
        y: spot.signpost.y,
      })
    }

    obj.item = {
      ...signpost,
      is_end_of_game: isEndOfGame,
      name: name || signpost.name,
      password,
      passwordQuestion,
      connects
    }
    store.dispatch(setOpenDoors(false));
  }

  public moveItemToLayer(layerId: string) {

    const activeObject = this.controller.canvas.getActiveObject() as FabricObject;
    if (!activeObject)
      return;

    const layer = this.controller.gameData.dynamic.layers[layerId];

    const moveItem = (deco) => {
      if(deco.layer_id == layerId)
        return;

      deco.layer_id = layerId;

      deco.z_idx = layer.z*1000+deco.item.z;
    };

    // If the activeObject is a selection, move all selected objects
    if (activeObject.type === 'activeSelection') {
      const objects = activeObject.getObjects();
      objects.forEach(obj => moveItem(obj));
    } else {
      moveItem(activeObject);
    }

    this.controller.restackItems();
    this.controller.canvas.renderAll();
  }

  private getMandanga(event):any{
    const { absolutePointer, pointer } = event;
    const pos = absolutePointer;
    const id = v4();

    return {
      id,
      item: {
        id, 
        x: pos.x,
        y: pos.y,
        w: 50,
        h: 50,
      },
      left: pos.x,
      top: pos.y,
    }
  }

  public addWall(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);

    const opts = {
      ...popts,
      glitch_type: 'wall',
      width : 100,
      height : 100,
      item: {
        ...popts.item,
        type: 'wall'
      },
      ...linesStyles.walls
    }

    this.controller.add(opts, false, false, null, (createdObject) => {
      this.controller.interactionHandler.selection();
      this.controller.canvas.setActiveObject(createdObject);
    })

  }

  public addLadder(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);
    const opts = {
      ...popts,
      glitch_type: 'ladder',
      width : 100,
      height : 100,
      item: {
        ...popts.item,
        type: 'ladder'
      },
      ...linesStyles.ladders
    }

    this.controller.add(opts, false, false, null, (createdObject) => {
      this.controller.interactionHandler.selection();
      this.controller.canvas.setActiveObject(createdObject);
    })

  }

  public convertToSignpost(deco:FabricObject) {
    deco.glitch_type = 'signpost'
    deco.layer_id = 'middleground'
    deco.item = {
      ...deco.item,
      type: 'signpost'
    }
  }

  public async addSignpost(event: FabricEvent<MouseEvent>) {
    const popts = this.getMandanga(event);
    const {absolutePointer, pointer} = event;

    const {selectedSprite}:{selectedSprite:GlitchAsset} = this.store.getState().editor;
    const img = await loadFabricImage2(selectedSprite.url);

    const decorable = {
      glitch_type: 'signpost',
      layer_id: 'middleground',
      element: img.getElement(),
      url: selectedSprite.url,
      item: {
        ...selectedSprite,
        ...popts.item,
        type: 'signpost',
      },
      left: absolutePointer.x,
      top: absolutePointer.y,
      originX: 'center',
      originY: 'bottom',
    }

    const createdObject = await this.controller.add(decorable, false, true, 256)

    this.controller.interactionHandler.selection();
    this.controller.canvas.setActiveObject(createdObject);
    store.dispatch(setOpenDoors(true));

    // TODO some day, recover the fact that the spot is a deco
    return;

    //const opts = {
    //  ...popts,
    //  type: 'signpost',
    //  glitch_type: 'signpost',
    //  width : 50,
    //  height : 50,
    //  item: {
    //    ...popts.item,
    //    name: name,
    //    type: 'signpost',
    //  },
    //  ...linesStyles.signpost,
    //};

    //this.controller.add(opts, false, false, null, (createdObject) => {
    //  this.controller.interactionHandler.selection();
    //  this.controller.canvas.setActiveObject(createdObject);
    //})

  }

  public setOpenProperties = (openProperties) => {
    store.dispatch(setOpenProperties(openProperties));
  }

  constructor(controller: CanvasController) {
    super(controller);
  }

  removeObjectById(id) {
    const newGameData = {
      ...this.controller.gameData,
      dynamic: {
        ...this.controller.gameData.dynamic,
        layers: Object.entries(this.controller.gameData.dynamic.layers).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value,
            decos: value.decos.filter((obj) => obj.id !== id),
            ladders: value.ladders.filter((i) => !i.imported_from_space_id),
            platformLines: value.platformLines.filter((i) => !i.imported_from_space_id),
            walls: value.walls.filter((i) => !i.imported_from_space_id),
          }
          return acc
        }, {})
      }
    };
    this.store.dispatch(setGameData(newGameData));
  }

  setSignPointOnDeco(selectedObject, uuid) {
    //selectedObject.item = selectedObject.item || {};
    selectedObject.item.signpoint = {
      tsid: uuid,
      space_id: uuid,
    }
  }

}

export default GameHandler;
