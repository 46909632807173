import {GlitchGame, GlitchLayer} from 'components/glitch/space'
import sortBy from 'lodash/sortBy'
import Deco from './deco'
import Ladder from './ladder';
import PlatformLine from './platform_line';
import SignPost from './sign_post';
import Wall from './wall';

export default class Layer extends Phaser.GameObjects.Group {

  public layer:GlitchLayer;
  public signposts:SignPost[] = []
  public decos:Deco[] = []
  public ladders:Ladder[] = []
  public walls: Wall[] = []
  public platform_lines: PlatformLine[] = []

  constructor(scene: Phaser.Scene, layer:GlitchLayer, game:GlitchGame) {
    super(scene)
    this.layer=layer;

    const stageHeight = game.dynamic.b - game.dynamic.t;
    const heightDiff = stageHeight - layer.h;

    const [offset_x,offset_y] = [
      (layer.name == 'middleground' ? layer.w/2 : 0),
      (layer.name == 'middleground' ? layer.h : 0) + heightDiff
    ]

    const myIndex = sortBy(Object.values(game.dynamic.layers), l=>l.z).indexOf(layer);

    sortBy(layer.decos,(x)=>x.z).forEach(deco => {
      if(!(scene.textures.exists(deco.filename) || scene.textures.exists(`${deco.filename}-0`))){
        console.error(`Texture ${deco.filename} does not exist`)
        return
      }

      const d=new Deco(scene, deco, offset_x, offset_y)
      d.setDepth(myIndex*1000+deco.z)
      this.decos.push(d)
      this.add(d)
    })

    layer.signposts.forEach(signpost => {
      const s = new SignPost(scene, signpost, offset_x, offset_y)
      this.signposts.push(s)
      this.add(s)
    })

    layer.walls.forEach(wall => {
      const w =new Wall(scene, wall, offset_x, offset_y)
      this.walls.push(w)
      this.add(w)
    })

    layer.ladders.forEach(ladder => {
      const l = new Ladder(scene, ladder, offset_x, offset_y)
      this.ladders.push(l)
      this.add(l)
    })

    layer.platformLines.forEach(line => {
      const pl = new PlatformLine(scene, line, offset_x, offset_y)
      this.platform_lines.push(pl)
      this.add(pl)
    })
  }

  parallaxByOffset(offsetX, offsetY) {
    this.children.iterate((child:any) => {
      if(child.deco) child.doPosition(offsetX, offsetY)
      return true;
    });
  }

}
