import {GlitchWall} from 'components/glitch/space'
import {CATEGORY_WALL} from 'game/scenes/mainScene'
import Phaser from 'phaser'

export class Wall extends Phaser.GameObjects.GameObject {

  wall: GlitchWall

  constructor(scene: Phaser.Scene, wall: GlitchWall, offset_x: number, offset_y: number) {

    super(scene, 'wall')

    scene.add.existing(this)

    this.wall = wall;

    this.body = this.scene.matter.add.rectangle(wall.x + offset_x, wall.y + offset_y-(wall.h/2), wall.w, wall.h, {
      isStatic: true,
      friction: 0, // No friction against other bodies
      frictionStatic: 0, // No static friction
      frictionAir: 0, // No air friction
      collisionFilter: {
        category: CATEGORY_WALL,
      }
    });

  }

}

export default Wall
