import {GlitchGame, GlitchSignPoint, GlitchSignpost} from 'components/glitch/space'
import MainScene from 'game/scenes/mainScene';
import {SpaceAttributes} from 'models/space';
import {getGameData, LoadingCallbacks, ViwocScene} from 'packs/game';
import Decorable from './decorable';

// this is in glitch coordinates, not pixel coordinates
export type Spot = {
  x:number,
  y:number,
  signpost_id?:string
};

export interface SignPostable {
  _loadNextLevel: boolean
  nextGame: GlitchGame
  nextSpace: SpaceAttributes
  nextSpot: GlitchSignPoint
}

export const nextLevel = (scene: ViwocScene, item:SignPostable) => {

  scene.sound.stopAll()
  scene.cameras.main.fadeOut();

  const {loadingCallbacks} = scene;

  scene.scene.start('PreloadScene', {
    loadingCallbacks,
    gameData:item.nextGame,
    space: item.nextSpace,
    path_template: (scene as any).path_template,
    spot: item.nextSpot
  })
}

export default class SignPost extends Decorable implements SignPostable {

  public _loadNextLevel: boolean = false
  public nextGame:GlitchGame=null;
  public nextSpace: SpaceAttributes
  public nextSpot: GlitchSignPoint = null;
  public loadingCallbacks: LoadingCallbacks;

  public signpost: GlitchSignpost;

  constructor(scene: Phaser.Scene, signpost: GlitchSignpost, offset_x: number, offset_y: number) {
    super(scene, signpost, offset_x, offset_y)

    this.signpost=signpost
    this.setInteractive()
    this.loadingCallbacks = (scene as MainScene).loadingCallbacks

    scene.add.existing(this)

    if(!this.signpost.connects?.length){
      this.setAlpha(0.5)
      return
    }
    const {space_id, x, y, tsid, signpost_id} = this.signpost.connects[0];

    getGameData(space_id || tsid.replace(/^L/, 'G')).then(([location_json, space]) => {
      this.nextGame = location_json
      this.nextSpace = space
      this.nextSpot = this.signpost.connects[0];
    }).catch(error => {
      console.error('Error:', error)
    })
  }

}
