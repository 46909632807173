import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {useDispatch, useSelector} from 'react-redux';
import {WebResources} from 'api/agent';
import {setWebResources} from 'redux/reducers/editor';
import WebResourceNew from './new';
import {WebResourceAttributes} from 'models/web_resource';

interface SelectWebResourceModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (web_resource: WebResourceAttributes) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    web_resourceItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    web_resourceHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    audioControls: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    audioPlayer: {
      display: 'none',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

const SelectWebResourceModal: React.FC<SelectWebResourceModalProps> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const web_resources:Array<WebResourceAttributes> = useSelector((state:any) => state.editor?.web_resources);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeWR, setActiveWR] = useState<WebResourceAttributes | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchWebResources();
    }
    // Cleanup on close
    return () => {
      setActiveWR(null);
    };
  }, [open, searchQuery]);

  const refetchWebResources = () => {
    WebResources.list({ query: searchQuery }).then((web_resources) => {
      dispatch(setWebResources(web_resources));
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (web_resource: WebResourceAttributes) => {
    onSelect(web_resource);
  };

  const handlePlay = (web_resource: WebResourceAttributes) => {
    if (activeWR?.uuid === web_resource.uuid) {
      setActiveWR(null);
    } else {
      setActiveWR(web_resource);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if(!web_resources) return null;

  // Group web_resources by category
  const groupedWebResources = web_resources.reduce<Record<string, WebResourceAttributes[]>>((groups, web_resource) => {
    const category = web_resource.category || 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(web_resource);
    return groups;
  }, {});

  const OneWebResource = ({ web_resource:snd, hit }: { web_resource?: WebResourceAttributes, hit?:any}) => {

    let web_resource;
    if(snd) {
      web_resource = snd;
    } else {
      const {uuid, name, duration, description, url} = hit;
      web_resource = {uuid, name, duration, description, url};
    }

    return <ListItem button key={web_resource.uuid} onClick={() => handleSelect(web_resource)}>
      <div className={classes.web_resourceItem}>
        <div className={classes.web_resourceHeader}>
          <ListItemText primary={web_resource.name} />
          <IconButton
            edge="end"
            aria-label={activeWR?.uuid === web_resource.uuid ? 'Stop preview' : 'Play preview'}
            onClick={(e) => {
              e.stopPropagation();
              handlePlay(web_resource);
            }}
          >
            {activeWR?.uuid === web_resource.uuid ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton>
        </div>
        <Typography variant="body2" className={classes.description}>
          ({web_resource.duration} sec.) {web_resource.description}
        </Typography>
      </div>
    </ListItem>
  };

  return (<>
  {activeWR && <Dialog open={!!activeWR} onClose={()=>setActiveWR(null)} fullWidth maxWidth="sm">
    <DialogTitle>{activeWR?.name}</DialogTitle>
    <DialogContent>
      <iframe src={activeWR?.url} width="100%" height="100%" />
    </DialogContent>
  </Dialog>}

    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select a WebResource</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Your web resources" />
          <Tab label="Create web resource" />
        </Tabs>

        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <>
              <TextField
                label="Search WebResources"
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.list}>
                  {Object.entries(groupedWebResources)
                    .sort(([a, web_resourcesA], [b, web_resourcesB]) => web_resourcesB.length - web_resourcesA.length)
                    .map(([category, web_resources]) => (
                      <div key={category}>
                        <ListSubheader>{category}</ListSubheader>
                        {web_resources.map((web_resource) => (
                          <OneWebResource key={web_resource.uuid} web_resource={web_resource} />
                        ))}
                      </div>
                    ))}
                  {web_resources.length === 0 && !loading && (
                    <ListItem>
                      <ListItemText primary="No web_resources found." />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {activeTab === 1 && (
            <WebResourceNew onCreated={(web_resource)=>{handleSelect(web_resource)}} />
          )}

        </div>
      </DialogContent>
    </Dialog>
  </>);
};

export default SelectWebResourceModal;

