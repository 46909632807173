// SelectPictureModal.tsx
import React, { useState, useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import {PictureAttrs} from 'models/picture';
import {useDispatch, useSelector} from 'react-redux';
import {Pictures} from 'api/agent';
import {setPictures} from 'redux/reducers/editor';
import PictureUploadForm from './new';

interface SelectPictureModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (picture: PictureAttrs) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    pictureItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    pictureHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    previewControls: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    previewImage: {
      maxWidth: '100%',
      maxHeight: '200px',
      display: 'none',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
  })
);

const ListPictures: React.FC<any> = ({ onSelect }) => {
  const classes = useStyles();
  const pictures:Array<PictureAttrs> = useSelector((state:any) => state.editor?.pictures);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchPictures();
    }
    return () => { };
  }, [open, searchQuery]);

  const refetchPictures = () => {
    Pictures.list({ query: searchQuery }).then((pictures) => {
      dispatch(setPictures(pictures));
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  if(!pictures) return null;

  return <>
    <TextField
      label="Search Pictures"
      variant="outlined"
      fullWidth
      className={classes.searchField}
      value={searchQuery}
      onChange={handleSearchChange}
    />
    {loading ? (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    ) : (
      <Grid container spacing={3}>
        {pictures.length ? pictures.map((picture) => (
          <Grid item xs={12} sm={6} md={4} key={picture.uuid}>
            <Card className={classes.card} onClick={() => onSelect(picture)}>
              <CardMedia
                className={classes.cardMedia}
                image={picture.image_url}
                title={picture.image_file_name}
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="subtitle1" component="h2">
                  {picture.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )) : null}
      </Grid>
    )}
    {pictures.length === 0 && !loading && (
      <Typography variant="body1">No pictures found.</Typography>
    )}
  </>
}

const SelectPictureModal: React.FC<SelectPictureModalProps> = ({ open, onClose, onSelect }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select a Picture</DialogTitle>
      <DialogContent>

        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Your pictures" />
          <Tab label="Create picture" />
        </Tabs>

        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <ListPictures onSelect={onSelect} />
          )}

          {activeTab === 1 && (
            <PictureUploadForm onCreated={(picture)=>{onSelect(picture)}} />
          )}

        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPictureModal;
export {ListPictures}
