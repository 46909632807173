import { fabric } from 'fabric';

import CanvasController from './CanvasController';
import { FabricObject, InteractionMode, InteractionModes } from '../utils';
import BaseHandler from './BaseHandler';
import {setInteractionMode} from 'redux/reducers/editor';

export type IReturnType = { selectable?: boolean; evented?: boolean } | boolean;

class InteractionHandler extends BaseHandler {

  public interactionMode: InteractionMode = InteractionModes.SELECTION;
  private cursorSprite: fabric.Image | null = null;

  constructor(handler: CanvasController) {
    super(handler);

    if (this.controller.editable) {
      this.selection();
    }
  }

  public setInteractionMode(newMode: InteractionMode){
    if(newMode == this.interactionMode)
      return;
    this.interactionMode = newMode;

    if(this.interactionMode != InteractionModes.SPOT && this.cursorSprite){
      this.controller.canvas.remove(this.cursorSprite);
      this.controller.canvas.off('mouse:move', this.mouseMove);
      this.cursorSprite = null;
    }

    switch(this.interactionMode){
      case InteractionModes.LINE:
        this.line();
        return;
      case InteractionModes.SPOT:
        this.spot();
        return;
      case InteractionModes.LADDER:
        this.ladder();
        return;
      case InteractionModes.WALL:
        this.wall();
        return;
      case InteractionModes.SELECTION:
        this.selection();
        break;
      case InteractionModes.GRAB:
        this.grab();
        break;
      default:
        //this.finishDrawing();
        break;
    }
  }

  public handleStateChange(newState) {
    if(newState.editor.interactionMode == this.interactionMode){
      return;
    }

    this.setInteractionMode(newState.editor.interactionMode);
  }

  public line = () => {
    if (this.interactionMode !== InteractionModes.LINE) {
      this.store.dispatch(setInteractionMode(InteractionModes.LINE));
      return;
    }
    this.disableSelection()
  }

  public spot = () => {
    if (this.interactionMode !== InteractionModes.SPOT) {
      this.store.dispatch(setInteractionMode(InteractionModes.SPOT));
      return;
    }
    const asset = this.store.getState().editor.selectedSprite;
    this.controller.canvas.selection = false;
    
    // Remove existing cursor sprite if any
    if (this.cursorSprite) {
      this.controller.canvas.remove(this.cursorSprite);
    }

    // Create new cursor sprite
    fabric.Image.fromURL(asset.url, (img) => {
      this.cursorSprite = img;
      this.cursorSprite.set({
        left: 0,
        top: 0,
        originX: 'center',
        originY: 'center',
        selectable: false,
        evented: false,
      });
      this.controller.canvas.add(this.cursorSprite);
      this.controller.canvas.on('mouse:move', this.mouseMove);
    });

    this.controller.canvas.defaultCursor = 'none';
    this.controller.workarea.hoverCursor = 'none';
    this.controller.canvas.renderAll();
  }

  public mouseMove = (options) => {
    if (this.interactionMode === InteractionModes.SPOT && this.cursorSprite) {
      const pointer = this.controller.canvas.getPointer(options.e);
      this.cursorSprite.set({
        left: pointer.x,
        top: pointer.y
      });
      this.controller.canvas.renderAll();
    }
  }

  public ladder = () => {
    if (this.interactionMode !== InteractionModes.LADDER) {
      this.store.dispatch(setInteractionMode(InteractionModes.LADDER));
      return;
    }
    this.disableSelection();
    this.controller.canvas.defaultCursor = 'default';
    this.controller.workarea.hoverCursor = 'default';
    this.controller.canvas.renderAll();
  }

  public wall = () => {
    if (this.interactionMode !== InteractionModes.WALL) {
      this.store.dispatch(setInteractionMode(InteractionModes.WALL));
      return;
    }
    this.disableSelection()
    this.controller.canvas.defaultCursor = 'default';
    this.controller.workarea.hoverCursor = 'default';
    this.controller.canvas.renderAll();
  }

  public add_sprite = () => {
    if (this.interactionMode !== InteractionModes.ADD_SPRITE) {
      this.store.dispatch(setInteractionMode(InteractionModes.ADD_SPRITE));
      return;
    }
    this.disableSelection();
    this.controller.canvas.defaultCursor = 'default';
    this.controller.workarea.hoverCursor = 'default';
    this.controller.canvas.renderAll();
  }

  /**
   * Change selection mode
   * @param {(obj: FabricObject) => IReturnType} [callback]
   */
  public selection = (callback?: (obj: FabricObject) => IReturnType) => {
    if (this.interactionMode !== InteractionModes.SELECTION) {
      this.store.dispatch(setInteractionMode(InteractionModes.SELECTION));
      return;
    }

    if (typeof this.controller.canvasOption.selection === 'undefined') {
      this.controller.canvas.selection = true;
    } else {
      this.controller.canvas.selection = this.controller.canvasOption.selection;
    }
    this.controller.canvas.defaultCursor = 'default';
    this.controller.workarea.hoverCursor = 'default';
    this.controller.getObjects().forEach(obj => {
      if (callback) {
        this.interactionCallback(obj, callback);
        return;
      } 
      // When typeof selection is ActiveSelection, ignoring selectable, because link position left: 0, top: 0
      if (obj.superType === 'link' || obj.superType === 'port') {
        obj.selectable = false;
        obj.evented = true;
        obj.hoverCursor = 'pointer';
        return;
      }
      obj.hoverCursor = 'move';
      obj.selectable = true;
      obj.evented = true;
    });
    this.controller.canvas.renderAll();
  };

  /**
   * Change grab mode
   * @param {(obj: FabricObject) => IReturnType} [callback]
   */
  public grab = (callback?: (obj: FabricObject) => IReturnType) => {
    if (this.interactionMode !== InteractionModes.GRAB) {
      this.store.dispatch(setInteractionMode(InteractionModes.GRAB));
      return;
    }
    this.disableSelection(callback);
    this.controller.canvas.defaultCursor = 'grab';
    this.controller.workarea.hoverCursor = 'grab';

    this.controller.canvas.renderAll();
  };

  /**
   * Change drawing mode
   * @param {InteractionMode} [type]
   * @param {(obj: FabricObject) => IReturnType} [callback]
   */
  public drawing = (type?: InteractionMode, callback?: (obj: FabricObject) => IReturnType) => {
    if (this.isDrawingMode()) {
      return;
    }
    this.store.dispatch(setInteractionMode(type));
    this.disableSelection(callback);
    this.controller.canvas.defaultCursor = 'pointer';
    this.controller.workarea.hoverCursor = 'pointer';
    this.controller.canvas.renderAll();
  };

  public linking = (callback?: (obj: FabricObject) => IReturnType) => {
    if (this.isDrawingMode()) {
      return;
    }
    this.store.dispatch(setInteractionMode(InteractionModes.LINK));
    this.controller.canvas.selection = false;
    this.controller.canvas.defaultCursor = 'default';
    this.controller.workarea.hoverCursor = 'default';
    this.controller.getObjects().forEach(obj => {
      if (callback) {
        this.interactionCallback(obj, callback);
      } else {
        if (obj.superType === 'node' || obj.superType === 'port') {
          obj.hoverCursor = 'pointer';
          obj.selectable = false;
          obj.evented = true;
          return;
        }
        obj.selectable = false;
        obj.evented = this.controller.editable ? false : true;
      }
    });
    this.controller.canvas.renderAll();
  };

  /**
   * Moving objects in grap mode
   * @param {MouseEvent} e
   */
  public moving = (e: MouseEvent) => {
    if (this.isDrawingMode()) {
      return;
    }
    const delta = new fabric.Point(e.movementX, e.movementY);
    this.controller.canvas.relativePan(delta);
    this.controller.canvas.requestRenderAll();
    this.controller.objects.forEach(obj => {
      if (obj.superType === 'element') {
        const { id } = obj;
        const el = this.controller.elementHandler.findById(id);
        // update the element
        this.controller.elementHandler.setPosition(el, obj);
      }
    });
  };

}

export default InteractionHandler;
