import {sortBy} from 'lodash';
import { parseGradients, parseFilters, drawItem, loadImage, loadLayer } from './utils';
import {dataURItoBlob} from 'shared/util/video-parse'
import {SpriteAttributes} from 'models/sprite';
import {SuggestPromptsResponse} from 'components/panels/generative_ai_panel';

export type GlitchObjectRef = {
  id: string,
  x: number
  y: number,
  w: number,
  h: number,
}
export type GlitchWall = {
  imported_from_space_id?: string,
  id: string,
  y: number,
  w: number,
  h: number,
  x: number,
}
export type GlitchLadder = {
  imported_from_space_id?: string,
  id: string,
  y: number,
  w: number,
  h: number,
  x: number
}
export type GlitchEndpoint = {
  name:string,
  x:number,
  y:number
}
export type GlitchPlatformLine = {
  imported_from_space_id?: string,
  id: string,
  platform_pc_perm: number,
  platform_item_perm: number,
  endpoints: GlitchEndpoint[]
  event_actions: GlitchEventAction[]
}

export type GlitchSignPoint = {
  y: number,
  x: number,
  hub_id?: string,
  mote_id?: string,
  tsid?: string,
  label?: string,
  space_id?: string,
  signpost_id?: string,
}

export type GlitchSignpost = Partial<GlitchDecorable> & {
  id?:string,
  is_end_of_game?:boolean,
  passwordQuestion?:string,
  password?:string,
  url?:string,
  name: string,
  y: number,
  x: number,
  h: number,
  w: number,
  connects: GlitchSignPoint[]
}

export type PromptGenerator = {
  prompt: string,
  viwoc_gid?: string,
  keyword: string,
  components: {
    [key:string]: string[]
  }
  props?: SuggestPromptsResponse
}

export type GeneratedAsset = {
  url: string,
  uuid: string,
  prompt: string,
  settings: any
}

export type GlitchGradient = {
  top?: string
  bottom?: string
};

export type GlitchEventAction = {
  event: string,
  action: string,
  parameters: any
}

export type GlitchGame = {
  tsid: string,
  label: string
  hub_id: string,
  mote_id: string,
  event_actions?: Array<GlitchEventAction>,
  loading_image: {
    url: string,
    w: number,
    h: number,
  },
  background_music?: any & {
    url: string,
  },
  background_image?: {
    url: string,
    w: number,
    h: number,
    generator?: PromptGenerator,
  },
  main_image: {
    url: string,
    w: number,
    h: number,
  },
  gradient: GlitchGradient,
  dynamic: {
    l: number,
    r: number,
    t: number,
    b: number,
    rookable_type: number,
    ground_y: number, // this one is never used
    layers: { [layerName:string]: GlitchLayer }
  }, 
  objrefs: GlitchObjectRef[]
}

export type GlitchDecorable = {
  filename?: string, 
  x: number,
  y: number,

  w: number,
  h: number,

  z?: number,
  r?: number,

  sprites?: SpriteAttributes[],
  h_flip?: boolean,
  v_flip?: boolean,
  event_actions?: Array<GlitchEventAction>
}

export type GlitchDeco = GlitchDecorable & {
  id?: string,
  imported_from_space_id?: string,
  url?: string,

  animated?: boolean,
  standalone?: boolean,
  signpoint?: GlitchSignPoint,
}

// for example: {"contrast"=>0, "tintColor"=>0, "brightness"=>9, "saturation"=>18}
export type GlitchFilters = {
  blur?: number,
  brightness?: number,
  contrast?: number,
  saturation?: number,
  tintColor?: number,
  tintAmount?: number,
  [key:string]:number
}

export type GlitchLayer = {
  id?:string,
  name:string,
  w:number,
  h:number
  z:number,
  filters: GlitchFilters,
  decos: GlitchDeco[],
  signposts: GlitchSignpost[],
  platformLines: GlitchPlatformLine[],
  ladders: GlitchLadder[],
  walls: GlitchWall[],

  // invented ?
  items: any[],
  offset?: number,
}

const STAGE = '.stage_holder'

export class Space {

  loadedLayers= 0;
  failedImageLoad:{[key:string]:number}= {};

  layerIds:Array<string>= [];

  gameObject:GlitchGame;

  stageWidth= null;
  stageHeight= null;
  stageHolderWidth= null;
  stageHolderHeight= null;

  loadingRoom= true;

  loadtime= {
    start: null,
    end: null
  };

  init(spaceId):Promise<any> {
    this.stageHolderWidth = $(STAGE).width();
    this.stageHolderHeight = $(STAGE).height();

    return this.loadSpace(spaceId);
  }

  async loadSpace(spaceId) {
    this.loadtime.start = new Date();

    this.loadingRoom = true;

    const dataJSON = await $.get('/locations/' + spaceId + '.json')

    this.gameObject = dataJSON;
    return this.prepStage()
  }

  async prepStage() {

    $('.location').text(this.gameObject.label);

    $('.stage').stop(true, false);
    $('.stage').empty();
    window.scrollTo(0,0);
    $('.stage').fadeTo(666, 1);
    $('.loading_holder').fadeIn(666);

    $('.stage').css({
      position: 'absolute',
      overflow: 'hidden',
      width: this.gameObject.dynamic.r - this.gameObject.dynamic.l,
      height: this.gameObject.dynamic.b - this.gameObject.dynamic.t,
      'z-index': -1000
    });
    this.stageWidth = this.gameObject.dynamic.r  - this.gameObject.dynamic.l;
    this.stageHeight = this.gameObject.dynamic.b - this.gameObject.dynamic.t;

    const [topGradient, bottomGradient] = parseGradients(this.gameObject.gradient)

    $('.stage').css('background-image', '-webkit-gradient(linear, left top, left bottom, color-stop(0, ' + topGradient + '), color-stop(1, ' + bottomGradient + '))');
    $('.stage').css('background-image', '-o-linear-gradient(bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)');
    $('.stage').css('background-image', '-moz-linear-gradient(bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)');
    $('.stage').css('background-image', '-webkit-linear-gradient(bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)');
    $('.stage').css('background-image', '-ms-linear-gradient(bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)');
    $('.stage').css('background-image', 'linear-gradient(to bottom, ' + topGradient + ' 0%, ' + bottomGradient + ' 100%)');

    this.stageWidth = $('.stage').width();

    this.loadingRoom = false;
    return await this.prepLayers();

  }

  createLayer(layer:GlitchLayer, id):[HTMLDivElement,HTMLCanvasElement] {

    const newLayer = ($('<div>') as JQuery<HTMLDivElement>)
      .attr('id', id).css({
      'position': 'absolute',
      'width': layer.w,
      'height': layer.h,
      'left': 0,
      'top': parseInt($('.stage').css('height')) - layer.h,
      'z-index': layer.z
    });

    const newCanvas = ($('<canvas>') as JQuery<HTMLCanvasElement>)
    .attr({id: id + '_canvas', width: layer.w, height: layer.h})
    .css({width: layer.w, height: layer.h})
    .css({position: 'absolute', top: 0, left: 0});

    const filters = parseFilters(layer.filters)

    newCanvas.css('filter', filters.join(' '));

    newLayer.append(newCanvas);

    return [newLayer[0], newCanvas[0]]
  }

  createPlayer(newLayer:HTMLDivElement){
    const playerSprite = $('<div class="player_holder">').append(
      $('<div class="player_frame">').append(
        $('<div class="player">').css('background-image', 'url(img/glitchen/root_base.png)')
      )
    );
    playerSprite.append($('<div class="nameLabel">').text('Hello'));

    $(newLayer).append(playerSprite);
  }

  get middleground():GlitchLayer {
    return this.gameObject.dynamic.layers.middleground
  }

  createSpace() {
    const tile_w=800,tile_h=800;
    const wtiles = Math.ceil(this.middleground.w/tile_w);
    const htiles = Math.ceil(this.middleground.h/tile_h);
    const middleCanvas = $('#middleground canvas')[0] as HTMLCanvasElement;
    const bg = dataURItoBlob(middleCanvas.toDataURL())

    const fd = new FormData();
    fd.append('space[bg]', bg, 'background.png');
    fd.append('space[width]',String(wtiles) );
    fd.append('space[height]',String(htiles) );
    fd.append('space[tile_height]', String(tile_h));
    fd.append('space[tile_width]', String(tile_w));

    return $.ajax({
      url: '/spaces',
      type: 'POST',
      data: fd,
      processData: false,
      contentType: false
    }).done((result) => {
      window.alert('Yeah');
    }, (err)=>{
      window.alert('Shit');
    })

  }

  async prepLayers() {

    this.layerIds = sortBy(Object.keys(this.gameObject.dynamic.layers), (id)=> this.gameObject.dynamic.layers[id].z)

    const exitList = $('<ul>');

    $('.exits').append($('<h2>').text('Exits'));
    $('.exits').append(exitList);

    this.loadedLayers = this.layerIds.length;

    this.failedImageLoad = {};

    await this.layerIds.reduce((p,layerId) => p.then(()=> {
      const layer = this.gameObject.dynamic.layers[layerId];
      const [newLayer, newCanvas] = this.createLayer(layer, layerId);

      if (layerId == 'middleground') {
        this.createPlayer(newLayer);
      }

      $('.stage').append(newLayer);

      layer.signposts.forEach((signpost)=>{

        Object.values(signpost.connects).forEach((connection) => {
          let exitLink = $('<li>').append($('<a>').attr({
            id: connection.tsid.replace('L','G'),
            href: '#/glitch/' + connection.tsid.replace('L','G')
          }).text(connection.label));

          exitList.append(exitLink);
        });

      })

      const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];
      const fail_cb = (failed)=>{
        this.failedImageLoad[failed] = (this.failedImageLoad[failed] || 0) + 1
      }

      return loadLayer(layer,newCanvas.getContext('2d'),fail_cb, offset_x, offset_y).finally(()=>{
        this.loadedLayers--;
        const percent = 100 - (this.loadedLayers / this.layerIds.length * 100);
        $('.percent').css('width', percent + '%');
        $('.offscreenBuffer').empty();
      });
    }), Promise.resolve());

    let newHtml = Object.keys(this.failedImageLoad).map((image)=>image + '<br />').join('')

    if (newHtml !== '') {
      $('.debug').html('<strong>Missing images</strong><br />' + newHtml);
    } else {
      $('.debug').empty();
    }

    $('.loading_holder').fadeOut(600);

    this.loadtime.end = new Date();
    console.log('Load time: ' + (this.loadtime.end - this.loadtime.start) + 'ms');
  }

  drawFrame(player) {

    const middleGroundOffset = Math.max(0, Math.min(this.stageWidth - this.stageHolderWidth, player.position.x - (this.stageHolderWidth / 2)))

    const currentPercent = middleGroundOffset / (this.stageWidth - this.stageHolderWidth);

    this.layerIds.forEach((layerId) => {
      try {
        this.gameObject.dynamic.layers[layerId].offset = ((this.gameObject.dynamic.layers[layerId].w - this.stageHolderWidth) * -currentPercent);
        $('#' + layerId).css('transform', 'translateX(' + this.gameObject.dynamic.layers[layerId].offset + 'px)' );
      } catch(er) {
      }
    })

    //  Work out the position of the player
    var newY = Math.min(0, Math.max(this.stageHolderHeight - this.stageHeight, -(this.stageHeight - player.position.y) + (this.stageHolderHeight / 2)));

    $('.stage').css('transform', 'translateY(' + newY + 'px)' );

    //  Place the player
    $('.player_holder').css({
      'transform': 'translateX(' + (player.position.x - (player.size.width/2)) + 'px) translateY(' + (this.stageHeight - player.position.y - player.size.height) + 'px)'
    });
  }

}

