import { play_game_url } from 'utils/urls';
import { loadGame } from 'packs/game';
import { Helmet } from 'react-helmet';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  loadingProgressStyle: {},
  loading: {
    width: '100%',
    height: 10,
    marginTop: theme.spacing(5),
  },
}));

const LoadingScreen = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [gameTitle, setGameTitle] = useState('Loading game');
  const [progress, setProgress] = useState(0);
  const phaserGameRef = useRef(null);
  const gameRef = useRef(null);
  
  const { uuid, avatar_id = 'f6a92135-9e34-406c-820e-66b6b3939496' } = props.match?.params || {};

  const gameConfig = useMemo(() => ({
    id: uuid,
    path_template: `/#${play_game_url('${id}')}`,
    avatar_id,
    loadingCallbacks: {
      setLoading: (isLoading) => {
        setLoading(isLoading);
      },
      setProgress: (progress) => {
        setProgress(progress);
      },
    },
  }), [uuid, avatar_id]);

  useEffect(() => {
    let mounted = true;

    const initGame = async () => {
      if (!phaserGameRef.current || gameRef.current) return;

      try {
        const game = await loadGame(phaserGameRef.current, gameConfig);
        if (!mounted) return;
        
        setGameTitle(game.config.gameTitle);
        gameRef.current = game;
        console.log('game:', game);
      } catch (error) {
        console.error('Failed to initialize game:', error);
      }
    };

    initGame();

    return () => {
      mounted = false;
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, [gameConfig]);

  return (
    <>
      <Helmet>
        <title>{gameTitle}</title>
      </Helmet>

      <Container className={classes.container} style={{ display: loading ? 'block' : 'none' }}>
        <Typography variant="h4">Loading ...</Typography>
        <LinearProgress className={classes.loading} value={progress * 100} variant="determinate" />
      </Container>

      <div style={{ display: loading ? 'none' : 'block' }}>
        <div ref={phaserGameRef} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  );
};

export default LoadingScreen;
