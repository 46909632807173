import React, { useEffect, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import {
  TextField,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import {Npcs} from 'api/agent';
import {addItem} from 'components/canvas/DropHandler';
import {useDesignContext} from 'contexts/design_context';
import {useDispatch, useSelector} from 'react-redux';
import {setLoadingProgress} from 'redux/reducers/editor';

// Initialize GiphyFetch with your API key from environment variables
const gf = new GiphyFetch(process.env.GIPHY_API_KEY);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  searchBar: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    flexGrow: 1,
  },
  gridContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  card: {
    height: '100%',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 1:1 aspect ratio
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

/**
 * StickerPickerPanel Component
 *
 * Props:
 * - onSelect: Function to handle the selected sticker.
 */
const StickerPickerPanel = ({ onSelect, onHitClick:_onHitClick }) => {
  const classes = useStyles();
  const [stickers, setStickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const dispatch = useDispatch();

  const {designController} = useDesignContext();
  const {generativePrompt} = useSelector((state:any) => state.editor);
  const [query, setQuery] = useState(generativePrompt?.keyword);

  // Function to handle search submissions
  const handleSearch = async (e?) => {
    e?.preventDefault();
    if (!query.trim()) return; // Prevent empty searches

    setLoading(true);
    setError(null);
    setStickers([]);
    setSelectedSticker(null);

    try {
      const response = await gf.search(query, { type: 'stickers', limit: 35 });
      setStickers(response.data);
    } catch (err) {
      setError('Failed to fetch stickers. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (generativePrompt?.keyword) {
      handleSearch()
    }
  }, [generativePrompt?.keyword]);

  // Function to handle sticker selection
  const handleSelectSticker = async (sticker) => {
    setSelectedSticker(sticker.id);
    console.log('Selected Sticker:', sticker);
    const { images } = sticker;
    const { fixed_width } = images;

    dispatch(setLoadingProgress(50));

    try{
      const created = await Npcs.create2({name: sticker.title, from_url: fixed_width.webp});
      const item = {
        ...created,
        type: 'deco',
        option: { }
      };
      addItem(item, true, designController);

      if (onSelect) {
        onSelect(sticker);
      }
    }catch(e){
      console.error(e);
    } finally {
      dispatch(setLoadingProgress(100));
    }
  };

  return (
    <div className={classes.container}>
      {/* Search Bar */}
      <form onSubmit={handleSearch} className={classes.searchBar}>
        <TextField
          className={classes.searchInput}
          label="Search Stickers"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </form>

      {/* Loading Indicator */}
      {loading && (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}

      {/* Error Message */}
      {error && (
        <Alert severity="error" style={{ marginBottom: '16px' }}>
          {error}
        </Alert>
      )}

      {/* No Results Message */}
      {!loading && !error && stickers.length === 0 && (
        <Typography variant="body1">No stickers found. Try searching!</Typography>
      )}

      {/* Stickers Grid */}
      <Grid container spacing={2} className={classes.gridContainer}>
        {stickers.map((sticker) => (
          <Grid item xs={4} sm={4} md={4} key={sticker.id}>
            <Card className={`${classes.card} ${ selectedSticker === sticker.id ? classes.selected : '' }`}
              onClick={() => handleSelectSticker(sticker)} >
              <CardActionArea>
                <CardMedia className={classes.media} image={sticker.images.fixed_width.webp} title={sticker.title} />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StickerPickerPanel;

