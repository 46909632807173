import {GlitchDeco, GlitchGame, GlitchSignPoint} from 'components/glitch/space'
import {effects} from 'game/effects'
import {APPLY_EFFECT, PLAY_SOUND_ACTION, SHOW_TEXT_ACTION, SHOW_WEB_RESOURCE_ACTION, PLAY_VIDEO_ACTION, TRAVEL_ACTION, CLICK_EVENT, HOVER_EVENT, COLLISION_START_EVENT, COLLISION_END_EVENT} from 'game/game_constants'
import MainScene, {CATEGORY_PLATFORM} from 'game/scenes/mainScene'
import {SpaceAttributes} from 'models/space'
import {getGameData} from 'packs/game'
import Phaser from 'phaser'
import {text_url} from 'utils/urls'
import Player2 from '../player/player2'
import Decorable from './decorable'
import PlatformLine from './platform_line'
import {nextLevel, SignPostable, Spot} from './sign_post'


export default class Deco extends Decorable implements SignPostable{

  public signpoint: GlitchSignPoint
  public nextGame: GlitchGame
  public nextSpace: SpaceAttributes
  public nextSpot: Spot

  _loadNextLevel: boolean

  public get deco(): GlitchDeco{
    return this.decorable as GlitchDeco
  }

  constructor(scene: Phaser.Scene, deco: GlitchDeco, offset_x: number, offset_y: number) {

    super(scene, deco, offset_x, offset_y);

    if(window.location.toString().match(/dev.viwoc.com/)){
      this.setInteractive()

      this.on('pointerdown', () => {
        console.log('pointerdown', this)
      })
    }

    this.doPosition();

    if(deco.signpoint) {
      this.signpoint=deco.signpoint;
      this.setInteractive()

      const {tsid:_tsid, space_id, x,y} = this.signpoint;
      const tsid = _tsid.length < 36 ? _tsid.replace(/^L/, 'G') : _tsid

      getGameData(space_id || tsid).then(([location_json, space]) => {
        this.nextGame = location_json
        this.nextSpace = space
        this.nextSpot = {x:x+offset_x, y:y+offset_y}
      }).catch(error => {
        console.error('Error:', error)
      })
    }

    scene.add.existing(this)
  }

}

export const performAction = (deco:Deco|PlatformLine|Player2, action: string, parameters: any, offsets:[number,number]) => {

  const scene = deco.scene as MainScene

  if(action == PLAY_SOUND_ACTION){
    const sound = scene.sound.add(parameters.id)
    sound.play()
    return;
  }

  if(action == APPLY_EFFECT){
    effects[parameters.effect](scene, deco)
    return;
  }

  if(action == PLAY_VIDEO_ACTION){
    if(deco.isPlaying)
      return;

    deco.isPlaying = true;
    console.log(action,parameters)

    if(parameters.embed_url){
      const htmlContent = `
      <div style="width: 800px; height: 450px; background: black; position: relative;">
        <div class="close-button" style="position: absolute; top: 0; right: 0; color: white; background: black; padding: 5px; cursor: pointer;">
        X </div>
        <iframe
          src="${parameters.embed_url}"
          width="800"
          height="450"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      `
      // Create a DOM element
      const htmlElement = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY)).createFromHTML(htmlContent);

      // Add interactivity (optional)
      const okButton = htmlElement.node.querySelectorAll('.close-button')[0];
      okButton.addEventListener('click', () => {
        deco.isPlaying=false;
        console.log('Button clicked!');
        htmlElement.destroy();
      });

      htmlElement.setVisible(true);
      htmlElement.setDepth(1000000);
      htmlElement.setOrigin(0.5, 0.5);
    }else{
      const video = document.createElement('video');

      video.playsInline = true;
      video.src = parameters.url;
      video.width = 400;
      video.height = 300;
      video.autoplay = true;

      const element = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY), video);
      element.setVisible(true);
      element.setDepth(1000000);
      element.setOrigin(0.5, 0.5);

      video.addEventListener('ended', (event) => {
        element.setVisible(false);
        deco.isPlaying=false;
        element.destroy()
      });
    }
    return;
  }

  if(action == SHOW_WEB_RESOURCE_ACTION){
    if(deco.isPlaying)
      return;
    deco.isPlaying = true;
    console.log(action,parameters)
    const iframe = document.createElement('iframe');

    iframe.src = parameters.url;
    iframe.width = "800";
    iframe.height = "450";

    const element = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY), iframe);
    element.setVisible(true);
    element.setDepth(1000000);
    element.setOrigin(0.5, 0.5);

    return;
  }

  if(action == SHOW_TEXT_ACTION){
    if(deco.isPlaying)
      return;

    console.log(action,parameters)
    deco.isPlaying = true;

    const url = text_url(parameters.uuid)

    fetch(url).then((resp) => resp.json()).then((r) => {

      const htmlContent = `
            <div style="width: 400px; padding: 10px; background: rgba(0, 0, 0, 0.8); color: white; border-radius: 10px;">
                ${r.content}
                <button id="okButton">OK</button>
            </div>
        `;

      // Create a DOM element
      const htmlElement = scene.add.dom(deco.x, deco.y-(deco.height * deco.scaleY)).createFromHTML(htmlContent);

      // Add interactivity (optional)
      const okButton = htmlElement.getChildByID('okButton');
      okButton.addEventListener('click', () => {
        deco.isPlaying=false;
        console.log('Button clicked!');
        htmlElement.destroy();
      });
    });


    return;
  }

  if(action == TRAVEL_ACTION){
    if(!parameters?.space?.uuid) return

    if(scene.startTime && (scene.time.now - scene.startTime) < 5000) return;

    scene.player.halt();

    getGameData(parameters.space.uuid).then(([location_json, space]) => {
      nextLevel(scene, {
        nextGame: location_json,
        nextSpace: space,
        nextSpot: {x:parameters.signpost.x+offsets[0], y:parameters.signpost.y+offsets[1]},
        _loadNextLevel: true
      })
    }).catch(error => {
      console.error('Error:', error)
    })
    return;
  }
}

