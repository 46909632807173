import React, { useState } from 'react';
import { Texts } from 'api/agent';
import ListErrors from 'components/shared/ListErrors';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import { TextAttrs } from 'models/text';
import { TextField, Button, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
  })
);

type TextNewProps = {
  onCreated?: (text:TextAttrs)=>void
};

const TextNew: React.FC<TextNewProps> = ({onCreated}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState(null);

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const text = {
      name,
      content,
    };

    Texts.create(text).then((response) => {
      console.log('response:',response);
      onCreated(response.body);
    }).catch((e) => {
      console.log('error:',e);
      setErrors(e.response.body)
    });
  };

  return (<>
    {errors ? <ListErrors errors={errors} /> : null }

    <form className={classes.form} onSubmit={submitForm}>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div>
        <ReactTrixRTEToolbar toolbarId="react-trix-rte-editor" />
        <ReactTrixRTEInput
          toolbarId="react-trix-rte-editor"
          defaultValue="React Trix Rich Text Editor"
          onChange={(_e, newValue) => setContent(newValue)}
        />
      </div>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submitButton}
      >
        Save
      </Button>
    </form>
  </>);
};

export default TextNew;
