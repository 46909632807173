import {Spaces} from "api/agent";
import {GlitchGame} from "components/glitch/space";
import {mergeGameIntoGame, removeBackgroundLayers, removeImportedItems, removeOffscreenItems, serializeItems} from "components/glitch/utils";
import {useDesignContext} from "contexts/design_context";
import React, {useEffect} from "react";
import { InstantSearch, SearchBox, Hits, RefinementList, InfiniteHits, Configure } from "react-instantsearch";
import Client from "@searchkit/instantsearch-client";
import Searchkit from "searchkit";
import {loadSearchCss} from "./elements_panel";
import {useDispatch, useSelector} from "react-redux";
import {setGameData} from "redux/reducers/editor";
import {LocationHit, result_attributes} from "utils/searchkit_client";

const TemplatesPanel = (props) => {
  const dispatch = useDispatch();

  const {fabricCanvas } = useDesignContext();
  const {gameData} = useSelector((state:any) => state.editor);

  const href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"
  useEffect(loadSearchCss(href), []);

  const HitView = ({ hit }) => {

    const svgStyle:React.CSSProperties = {
      width: '100%',
      height: '100px',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '10px',
      border: '1px solid black',
      borderRadius: '5px',
    };

    const obj = result_attributes<LocationHit>(hit);

    const url =obj.preview_url;

    const onSelectLayer = async ({space_uuid}: any)=> {
      //designController.clear();

      let newGameData:GlitchGame;

      const space_json = await Spaces.show(space_uuid)

      if(props.onSelectSpace){
        props.onSelectSpace(space_json);
      }

      if(space_json.location){
        newGameData = space_json.location.metadata
      }

      const currentGameData = removeImportedItems(serializeItems(gameData, fabricCanvas));
      let newerGameData = mergeGameIntoGame(currentGameData, newGameData, {imported_from_space_id: space_uuid});
      newerGameData = removeOffscreenItems(newerGameData);
      newerGameData = removeBackgroundLayers(newerGameData, 0);

      dispatch(setGameData(newerGameData));
    }

    return <div>
      <img data-space-uuid={hit.space_uuid} src={url} style={svgStyle} alt={hit.label} onClick={e => onSelectLayer(obj)} ></img>
      <p>{hit.hub_name} / {hit.label}</p>
    </div>
    };

  const index = `glitch_locations_${(window as any).rails_env}`;
  //const our_ratio = gameData.dynamic.layers.middleground.w / gameData.dynamic.layers.middleground.h;
  // filter by not hub_name is null
  let filter_attributes:any[] = [
    {field: 'hub_name', attribute: 'hub_name', type: 'string'},
    {field: 'aspect_ratio', attribute: 'aspect_ratio', type: 'numeric'}
  ];

  let filter = "hub_name:*";
  //if(our_ratio < 0.8){
  //  filter += " AND aspect_ratio:[0 TO 1]";
  //} else if(our_ratio >= 0.8 && our_ratio <= 2){
  //  filter += " AND aspect_ratio:[0.5 TO 2]";
  //} else if(our_ratio >= 2){
  //  filter += " AND aspect_ratio:[1.8 TO *]";
  //}

  //const searchLocations = 
    //(window as any).locations_index;
  //
  const search_locations = new Searchkit({
    connection: {
      host: '/search/index',
    },
    search_settings: {
      filter_attributes,
      search_attributes: [{field: 'label.analyzed', weight: 4}, {field: 'hub_name.analyzed', weight: 1}, {field: 'category.analyzed', weight: 1}],
      result_attributes: ['label', 'hub_name', 'preview_url', 'space_uuid', 'category', 'viwoc_gid', 'uuid'],
      snippet_attributes: ['label'],
      highlight_attributes: ['label'],
      facet_attributes: [
        {
          attribute: 'category',
          field: 'category',
          type: 'string'
        },
      ]
    },
  })

  return <InstantSearch indexName={index} searchClient={Client(search_locations)} future={{preserveSharedStateOnUnmount: true}}>
    <Configure filters={filter} />
    <SearchBox />
    <RefinementList attribute="category" limit={15} showMore={true} classNames={{item: 'd-inline-block border border-gray m-1 p-1 rounded-2', checkbox: 'd-none', selectedItem: 'bg-primary text-white'}}/>
    <InfiniteHits hitComponent={HitView} id="hits-templates" classNames={{list: 'row', item: 'col-12 col-sm-6 col-md-3'}}/>
  </InstantSearch>
};

 
export default TemplatesPanel;
