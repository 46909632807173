import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import { REGISTER, } from 'constants/actionTypes';

import { Auth } from 'api/agent';
import AccountLayout from './Layout';
import { Button, TextField, Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {RouterTabs} from './Login';
import {T} from 'components/utils/t';

const prefix='pages.register';

const Register = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { from, sign_in_reason_key } = location.state || { from: { pathname: '/' }, sign_in_reason_key: 'login'};

  const {
    errors,
    inProgress,
    initialData,
  } = useSelector((state:any) => state.auth);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [authData, setAuthData] = useState(initialData || {
    name: '',
    surname: '',
    nickname: '',
    email: '',
    password: '',
    username: '',
    acceptedTerms: false
  });

  const {
    name,
    surname,
    nickname,
    email,
    password,
    username,
  } = authData;

  const onChangeSurname = value => setAuthData({ ...authData, surname: value });
  const onChangeName = value => setAuthData({ ...authData, name: value });
  const onChangeEmail = value => setAuthData({ ...authData, email: value });
  const onChangePassword = value => setAuthData({ ...authData, password: value });
  const onChangeNickname = value => setAuthData({ ...authData, nickname: value });

  const onSubmit = (name, surname, nickname, email, password) => {
    Auth.register({name, surname, nickname, email, password, termsAccepted: acceptedTerms}).then((result) => {
      dispatch({ type: REGISTER, payload:result, authData});
      history.push(from);
    }).catch((error) => {
      dispatch({ type: REGISTER, error: true, payload:error.response.body, authData});
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (acceptedTerms) {
      onSubmit(name, surname, nickname, email, password);
    } else {
      console.error("Terms and conditions must be accepted");
    }
  };

  const getFieldError = (fieldName) => {
    return errors && errors[fieldName] ? errors[fieldName][0] : '';
  };

  const getGeneralErrors = () => {
    if (!errors) return null;
    const generalErrors = Object.entries(errors).filter(([key]) => 
      !['name', 'surname', 'email', 'password', 'nickname'].includes(key)
    );
    return generalErrors.length > 0 ? (
      <Box mt={2} mb={2}>
        {generalErrors.map(([key, value]) => (
          <Alert severity="error" key={key}>{`${key}: ${value}`}</Alert>
        ))}
      </Box>
    ) : null;
  };

  const nice_content = <><div className="container">
              <div className="row">
                <div className="col-12 col-md-6 offset-md-3 heading" style={{marginTop: '10rem'}}>
                  <Typography component="h2" variant="h2" align="center">
                    <T k={`${prefix}.illustration.header`}>Register</T>
                  </Typography>
                  <Typography component="h3" variant="h3" align="center">
                    <T k={sign_in_reason_key}> </T>
                  </Typography>
                </div>
              </div>
            </div></>;


  return (<>
    <Helmet>
      <title>Sign up for Viwoc</title>
    </Helmet>

    <AccountLayout nice_content={nice_content}>

      <RouterTabs />

      {getGeneralErrors()}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          value={name}
          onChange={(e) => onChangeName(e.target.value)}
          error={!!getFieldError('name')}
          helperText={getFieldError('name')}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Surname"
          value={surname}
          onChange={(e) => onChangeSurname(e.target.value)}
          error={!!getFieldError('surname')}
          helperText={getFieldError('surname')}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => onChangeEmail(e.target.value)}
          error={!!getFieldError('email')}
          helperText={getFieldError('email')}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => onChangePassword(e.target.value)}
          error={!!getFieldError('password')}
          helperText={getFieldError('password')}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Nickname (visible name for your avatar)"
          value={nickname}
          onChange={(e) => onChangeNickname(e.target.value)}
          error={!!getFieldError('nickname')}
          helperText={getFieldError('nickname')}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              color="primary"
            />
          }
          label={
            <span>
              I accept the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a>
            </span>
          }
        />

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={inProgress || !acceptedTerms}
            type="submit"
          >
            Sign up
          </Button>
        </Box>
      </form>
    </AccountLayout>
  </>);
};

export default Register;
