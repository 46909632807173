// SelectSoundModal.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { InfiniteHits, InstantSearch, RefinementList, SearchBox } from 'react-instantsearch';
import { result_attributes, searchSounds, SoundHit } from 'utils/searchkit_client';
import {SoundAttrs} from 'models/sound';
import {useDispatch, useSelector} from 'react-redux';
import {Sounds} from 'api/agent';
import {setSounds} from 'redux/reducers/editor';
import AudioUploadForm from './new';
import {T} from 'components/utils/t';

interface SelectSoundModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (sound: SoundAttrs) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: 400,
      overflow: 'auto',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    soundItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    soundHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    audioControls: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    audioPlayer: {
      display: 'none',
    },
    description: {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
  })
);

const SelectSoundModal: React.FC<SelectSoundModalProps> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const sounds:Array<SoundAttrs> = useSelector((state:any) => state.editor?.sounds);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeAudio, setActiveAudio] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      refetchSounds();
    }
    // Cleanup on close
    return () => {
      setActiveAudio(null);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [open, searchQuery]);

  const refetchSounds = () => {
    Sounds.list({ query: searchQuery }).then((sounds) => {
      dispatch(setSounds(sounds));
    });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (sound: SoundAttrs) => {
    onSelect(sound);
  };

  const handlePlay = (sound: SoundAttrs) => {
    if (activeAudio === sound.uuid) {
      // Stop if already playing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      setActiveAudio(null);
    } else {
      // Play new audio
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current = new Audio(sound.url);
      audioRef.current.play();
      audioRef.current.onended = () => setActiveAudio(null);
      setActiveAudio(sound.uuid);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if(!sounds) return null;

  // Group sounds by category
  const groupedSounds = sounds.reduce<Record<string, SoundAttrs[]>>((groups, sound) => {
    const category = sound.category || 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(sound);
    return groups;
  }, {});

  const OneSound = ({ sound:snd, hit }: { sound?: SoundAttrs, hit?:any}) => {

    let sound;
    if(snd) {
      sound = snd;
    } else {
      const {id,viwoc_gid,uuid, name, duration, description, url} = result_attributes<SoundHit>(hit);
      sound = {id, uuid, name, duration, description, url, viwoc_gid};
    }

    return <ListItem button key={sound.uuid} onClick={() => handleSelect(sound)}>
      <div className={classes.soundItem}>
        <div className={classes.soundHeader}>
          <ListItemText primary={sound.name} />
          <IconButton
            edge="end"
            aria-label={activeAudio === sound.uuid ? 'Stop preview' : 'Play preview'}
            onClick={(e) => {
              e.stopPropagation();
              handlePlay(sound);
            }}
          >
            {activeAudio === sound.uuid ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton>
        </div>
        <Typography variant="body2" className={classes.description}>
          ({sound.duration} sec.) {sound.description}
        </Typography>
      </div>
    </ListItem>
  };

  const index = `sounds_${(window as any).rails_env}`;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle><T k="sounds.select_sound_modal.title">Select a Sound</T></DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label={<T k="sounds.select_sound_modal.your_sounds">Your sounds</T>} />
          <Tab label={<T k="sounds.select_sound_modal.create_sound">Create sound</T>} />
          <Tab label={<T k="sounds.select_sound_modal.search_music">Search music</T>} />
        </Tabs>
        <div className={classes.tabContent}>
          {activeTab === 0 && (
            <>
              <TextField
                label={<T k="sounds.slect_sound_modal.search.label">Search Sounds</T>}
                variant="outlined"
                fullWidth
                className={classes.searchField}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.list}>
                  {Object.entries(groupedSounds)
                    .sort(([a, soundsA], [b, soundsB]) => soundsB.length - soundsA.length)
                    .map(([category, sounds]) => (
                      <div key={category}>
                        <ListSubheader>{category}</ListSubheader>
                        {sounds.map((sound) => (
                          <OneSound key={sound.uuid} sound={sound} />
                        ))}
                      </div>
                    ))}
                  {sounds.length === 0 && !loading && (
                    <ListItem>
                      <ListItemText primary={<T k="sounds.select_sound_modal.no_sounds_found">No sounds found.</T>} />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {activeTab === 1 && (
            <AudioUploadForm onCreated={(sound)=>{handleSelect(sound)}} />
          )}

          {activeTab === 2 && (
            <div>
              <Typography variant="h6"><T k="sounds.select_sound_modal.search_sounds_title">Search Sounds</T></Typography>
              <InstantSearch indexName={index} searchClient={searchSounds}>
                <SearchBox />
                <RefinementList attribute="category" />
                <InfiniteHits hitComponent={OneSound} />
              </InstantSearch>
            </div>
          ) }
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectSoundModal;

