import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

// Restrict `tag` to specific HTML elements
interface ConfirmableButtonProps<T extends keyof JSX.IntrinsicElements = "a"> {
  onConfirm: () => void; // Action to perform on confirmation
  confirmationMessage?: string; // Custom confirmation message
  tag?: T; // HTML tag for the button
  children?: React.ReactNode; // Button content
}

// Extend `ConfirmableButtonProps` to accept props from the HTML element represented by `tag`
const ConfirmableButton = <T extends keyof JSX.IntrinsicElements = "a">({
  onConfirm,
  confirmationMessage = "Are you sure?",
  tag,
  children,
  ...props // Spread remaining props
}: ConfirmableButtonProps<T> & JSX.IntrinsicElements[T]) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Use the tag or default to "a"
  const Tag = (tag || "a") as keyof JSX.IntrinsicElements;

  const handleConfirm = () => {
    onConfirm(); // Execute the confirmed action
    setIsDialogOpen(false); // Close the dialog
  };

  const handleCancel = () => {
    setIsDialogOpen(false); // Close the dialog
  };

  return (
    <>
      <Tag {...props} onClick={() => setIsDialogOpen(true)}>
        {children}
      </Tag>

      <Dialog open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirmation Required</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="default">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmableButton;

