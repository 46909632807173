import {GameAttributes} from "models/game";

type UrlGenerator<T> = (T) => string;

export const dataURItoBlob = (dataURI: string): Blob => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}

export const game_url:UrlGenerator<GameAttributes> = (game) => `/games/${game.uuid}`;

export const space_ai_generations_url = (opts:any={}) => {
  return `/generative_ai.json?${new URLSearchParams(opts).toString()}`
}

export const transcribe_url = (opts:any={}) => {
  return `/pruebas/transcribe.json?${new URLSearchParams(opts).toString()}`;
}
export type SdxlOptions = {
  prompt: string,
  negative_prompt?: string,
  width?: number, // multiple of 8
  height?: number, // multiple of 8
  image_url?: string, // for img2img
  mask_url?: string, // for inpaint
  num_outputs?: number, // default is 1
  scheduler?: string, // DDIM, K_EULER_ANCESTRAL, K_EULER, KarrasDPM, PNDM, HeunDiscrete, DPMSolverMultistep
  num_inference_steps?: number, // [1,500]@1 default is 25
  guidance_scale?: number, // [1,50]@0.01 default is 7.5
  prompt_strength?: number, // [0,1]@0.01 default is 0.8
  seed?: number, // default is random
  refine?: string, // [no_refiner, expert_ensemble_refiner, base_image_refiner], default is expert_ensemble_refiner
  hight_noise_frac?: number, // [0,1]@0.01 default is 0.8
  refine_steps?: number, // @1
  apply_watermark?: boolean, // default is false
  lora_scale?: number, // [0,1]@0.01 default is 0.5
  disable_safety_checker?: boolean, // default is false
}
export type RemoveBgOptions = {
  image: string,
}

export const remove_bg_url = (opts:any={}) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/remove_bg?${queryParams.toString()}`;
}

export const google_oauth2_url = () => '/users/auth/google_oauth2'

export const text_url = (uuid:string) => {
  return `/texts/${uuid}.json`;
}
export const games_url = (opts:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/games?${queryParams.toString()}`;
}

export const generations_url = (opts:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/generations?${queryParams.toString()}`;
}

export const sprite_url = (opts:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/sprite?${queryParams.toString()}`;
}
export const space_url = (gameId:string, spaceId:string, opts:any=null) => {
  const queryParams = new URLSearchParams(opts);
  return `/games/${gameId}/spaces/${spaceId}.json?${queryParams.toString()}`;
}

export const new_creatix_game_url = () => {
  return `/creatix/1`
}
export const new_creatix_space_url = (game_id:string, step?:number) => {
  if(step)
    return `/creatix/space/${game_id}/${step}`
  else
    return `/creatix/space/${game_id}`
}
export const edit_space_url = (space_id:string) => {
  return `/glitch/edit/${space_id}`
}
export const game_welcome_url = (game_id:string) => {
  return `/game_welcome/${game_id}`
}
export const avatar_url= (avatar_id:string) => {
  return `/avatar/${avatar_id}`
}

export const play_game_url = (space_id:string) => {
  return `/spaces/play/${space_id}`
}
export const play_game_avatar_url = (space_id:string, avatar_id:string) => {
  return `/spaces/play/${space_id}/${avatar_id}`
}

export const login_path = () => {
  return `/login`
}

export const register_path = () => {
  return `/register`
}

export const spriterr_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/spriterr?${queryParams.toString()}`;
}

export const suggest_prompts_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/suggest_prompts?${queryParams.toString()}`;
}
export const activate_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/activate?${queryParams.toString()}`;
}
export const preboot_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/preboot?${queryParams.toString()}`;
}

export const improve_prompt_url = (opts?:any) => {
  return `/prompts/improve/invoke`
}
export const depthmap_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/depthmap?${queryParams.toString()}`;
}
export const blip_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/blip?${queryParams.toString()}`;
}
export const sdxl_url = (opts?:any) => {
  const queryParams = new URLSearchParams(opts);
  return `/generative_ai/sdxl?${queryParams.toString()}`;
}
