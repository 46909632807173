import {Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Switch, FormControlLabel} from '@material-ui/core';
import {Games} from 'api/agent';
import { Link } from "react-router-dom";
import {SpotAttrs} from 'components/actions_config';
import {GlitchSignpost} from 'components/glitch/space';
import {T} from 'components/utils/t';
import {useDesignContext} from 'contexts/design_context';
import {groupBy} from 'lodash';
import React, {useEffect, useState, useMemo} from 'react';
import {useSelector } from 'react-redux';
import {new_creatix_space_url} from 'utils/urls';
import SpacePreview from './preview';
import {saveGame} from 'components/canvas/Header';
import SpaceConnections from 'components/games/space_viz';

// A Dialog to select a space.
type DoorModalProps = {
  open: boolean;
  onClose: () => void;
  signpost?: GlitchSignpost,
  selectedObject: any
}

const DoorModal: React.FC<DoorModalProps> = ({ open, onClose, selectedObject}) => {

  const {game} = useSelector((state: any) => state.editor);
  const [allSpots, setSpots] = useState<SpotAttrs[]>([]);
  const {designController, fabricCanvas} = useDesignContext();

  // Derive state from selectedObject to ensure it updates when selectedObject changes
  const [connected, setConnected] = useState<SpotAttrs|null>(null);
  const [doorName, setDoorName] = useState('');
  const [hasPassword, setHasPassword] = useState(false);
  const [passwordQuestion, setPasswordQuestion] = useState('');
  const [password, setPassword] = useState('');
  const [isEndOfGame, setIsEndOfGame] = useState(false);

  // Update state when selectedObject changes
  useEffect(() => {
    setDoorName(selectedObject?.item?.name || '');
    const pw = selectedObject?.item?.password;
    switch(typeof(pw)){
      case 'undefined':
        setHasPassword(false);
        return;
      case 'string':
        setHasPassword(true);
        setPassword(pw);
        setPasswordQuestion(selectedObject.item.passwordQuestion||'')
        return;
    }

    setIsEndOfGame(selectedObject?.item?.is_end_of_game || false);
  }, [selectedObject]);

  const signpost = useMemo(() => 
    selectedObject?.glitch_type === 'signpost' ? selectedObject.item : null,
  [selectedObject]);

  const validateSaveAndClose = () => {
    // validate that the door name is present
    if(!doorName) return false;

    let cfg:any = {isEndOfGame};

    if(hasPassword){
      cfg = {...cfg, password, passwordQuestion}
    }

    designController.gameHandler.handleSelectDoor(connected, doorName, cfg);
    saveGame(fabricCanvas);
    onClose();
  }

  // get the spaces of the current game
  useEffect(() => {
    Games.list_spots(game.uuid).then((spots) => {
      setSpots(spots.data)
    })
  }, [open]);

  useEffect(()=>{
    if(!signpost || !allSpots)return;
    const connectedSpot = allSpots.find((spot) => 
      signpost.connects?.[0]?.signpost_id === spot.signpost.id
    );
    if (connectedSpot) {
      setConnected(connectedSpot);
    }
  },[signpost,allSpots]);

  if(!selectedObject || selectedObject.glitch_type !== 'signpost')
    return null;

  const spots = allSpots.filter(({space,signpost}) => signpost.id !== selectedObject.item.id)

  const create_space_url = new_creatix_space_url(game.uuid);

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle><T k="spaces.select_space_modal.title">Select a Space</T></DialogTitle>
      <DialogContent>
        <TextField
          label="Door Name"
          value={doorName}
          onChange={(e) => setDoorName(e.target.value)}
          required={true}
          error={!doorName}
          helperText={doorName ? '' : "This field is required"}
          fullWidth
          margin="normal"
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPassword}
              onChange={(e) => setHasPassword(e.target.checked)}
              color="primary"
            />
          }
          label="Has Password"
        />

        {hasPassword && (
          <TextField
            label="Question"
            value={passwordQuestion}
            onChange={(e) => setPasswordQuestion(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}

        {hasPassword && (
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={isEndOfGame}
              onChange={(e) => setIsEndOfGame(e.target.checked)}
              color="primary"
            />
          }
          label="Is End of Game"
        />

        {connected && (
          <div>
            <h4>Current Target Door:</h4>
            <p>
              <b>{connected.signpost.name}</b> in <i>{connected.space.name}</i>
            </p>
          </div>
      )}
      <div className="row">
        {isEndOfGame && <div className="col-12">
          <p>This will end the game when the player reaches this door.</p>
      </div>}

  {!isEndOfGame && Object.entries(groupBy(spots, ({space})=>space.uuid)).map(([uuid,spots]) => {
    return <div className="col-12 col-md-4" key={`container-${uuid}`} >
      <SpacePreview
        game={game}
        space={spots[0].space}
        onClick={() => {}}
        truncNames={20}
        hideActions={true}
        className={connected?.space?.uuid == uuid ? 'border border-primary border-2' : ''}>

        {spots.map(({space,signpost})=> <CardActions>
          <Button key={signpost.id}
            onClick={() => setConnected({space, signpost})}
            variant={signpost.id == connected?.signpost?.id ? "contained" : "outlined"}
            color="primary">
            {signpost.name || space.name.slice(0,10)}
          </Button>
        </CardActions>
    )}
  </SpacePreview>
</div>
})
}
{!isEndOfGame && <div className="col-12 col-md-3">
  <Card>
    <CardContent>
      {!spots.length && <p><T k="spaces.select_space_modal.no_spaces_found">No spaces found</T></p>}
    </CardContent>
    <CardActions>
      <Link to={create_space_url} target="_blank"  className="btn btn-primary"><T k="spaces.select_space_modal.create_space">Create a Space</T></Link>
    </CardActions>
  </Card>
    </div> }
      <div>
        <SpaceConnections apiData={spots}></SpaceConnections>
      </div>

  </div>

</DialogContent>

<DialogActions>
  <Button onClick={onClose} variant="outlined" color="primary"><T k="actions.cancel">Cancel</T></Button>
  <Button onClick={validateSaveAndClose} variant="outlined" color="primary"><T k="actions.save">Save</T></Button>
</DialogActions>
  </Dialog>
}

export default DoorModal;
