import { createSlice } from '@reduxjs/toolkit';
import {InteractionModes} from 'components/canvas';

export type PromptSuggestion = {
  title: string,
  prompt: string,
  description?: string,
}
const initialState = {
  loadingProgress: 100,
  openProperties: false,
  openDoors: false,
  selectedPanel: null,
  selectedSprite: null,
  pinPanel: false,
  spaceId: null,
  game: null,
  layerId: 'middleground',
  gameData: null,
  synced: false,
  sounds: null,
  texts: null,
  web_resources: null,
  videos: null,
  pictures: null,
  background_music: null,
  ai_generations: [],
  prompt_suggestions: [],
  interactionMode: InteractionModes.SELECTION,
  generativePrompt: null,
};

// Create slice
const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setGenerativePrompt: (state, {type, payload}) => {
      state.generativePrompt = payload;
    },
    setLoadingProgress: (state, {type, payload}) => {
      state.loadingProgress = payload;
    },

    setPinPanel: (state, {type, payload}) => {
      state.pinPanel = payload;
    },

    setSelectedPanel: (state, {type, payload}) => {
      state.selectedPanel = payload;
    },

    setSelectedSprite: (state, {type, payload}) => {
      state.selectedSprite = payload;
    },

    setLayerId: (state, {type, payload}) => {
      state.layerId = payload;
    },

    setGameData: (state, {type, payload}) => {
      state.gameData = payload;
    },

    setGame: (state, {type, payload}) => {
      state.game= payload;
    },

    setSpaceId: (state, {type, payload}) => {
      state.spaceId = payload;
    },
    setOpenProperties: (state, {type, payload}) => {
      state.openProperties = payload;
    },
    setOpenDoors: (state, {type, payload}) => {
      state.openDoors = payload;
    },

    setInteractionMode: (state, {type, payload}) => {
      state.interactionMode = payload;
    },

    setBackgroundMusic: (state, {type, payload}) => {
      state.background_music = payload;
    },
    setWebResources: (state, {type, payload}) => {
      state.web_resources = payload;
    },
    setSounds: (state, {type, payload}) => {
      state.sounds = payload;
    },

    setTexts: (state, {type, payload}) => {
      state.texts = payload;
    },

    setVideos: (state, {type, payload}) => {
      state.videos = payload;
    },
    setPictures: (state, {type, payload}) => {
      state.pictures = payload;
    },

    setSynced: (state, {type, payload}) => {
      state.synced = payload;
    },
    setPromptSuggestions: (state, {type, payload:suggestions}:any & {payload: PromptSuggestion[]}) => {
      state.prompt_suggestions = suggestions;
    },
    addPromptSuggestions: (state, {type, payload:suggestions}:any & {payload: PromptSuggestion[]}) => {
      const newS= [...suggestions, ...state.prompt_suggestions];
      state.prompt_suggestions = newS;
    },
    setAiGenerations: (state, {type, payload:ai_generations}) => {
      state.ai_generations = ai_generations;
    },
    addAiGenerations: (state, {type, payload:ai_generations}) => {
      // concat state.ai_generations with ai_generations, but if there's an item with the same id, keep the one that has the url attribute:
      state.ai_generations = [...ai_generations, ...state.ai_generations].reduce((acc, generation) => {
        const found = acc.find(({id}) => id == generation.id)
        if(!found) return [...acc, generation];
        if(found.url) return acc;
        return [...acc.filter(({id}) => id != generation.id), generation];
      }, [])
    },
  },
});

// Export actions
export const {
  setSynced,
  setSounds,
  setTexts,
  setWebResources,
  setVideos,
  setPictures,
  addPromptSuggestions,
  setPromptSuggestions,
  addAiGenerations,
  setAiGenerations,
  setBackgroundMusic,
  setInteractionMode,
  setOpenProperties,
  setOpenDoors,
  setSpaceId,
  setLayerId,
  setSelectedPanel,
  setSelectedSprite,
  setPinPanel,
  setGenerativePrompt,
  setGameData,
  setGame,
  setLoadingProgress,
} = editorSlice.actions;

export default editorSlice.reducer;

