import React, {useState} from 'react';

import {ButtonGroup, makeStyles, Switch} from '@material-ui/core';
import CommonButton from 'components/common/Button';
import {isMobile} from 'react-device-detect';
import {useDesignContext} from 'contexts/design_context';
import {InteractionModes} from './utils';
import {useDispatch, useSelector} from 'react-redux';
import SplitButton from 'components/common/split_button';
import SelectSpriteModal from 'components/sprites/select_sprite_modal';
import {setSelectedSprite} from 'redux/reducers/editor';
import SelectTemplate from 'components/dialogs/select_template';

export const i18n = {
  t: (key:string) => key
}

type IProps = {
  fullscreen:boolean,
  onChangeFullscreen:(e:any, checked:boolean)=>void,
  zoomRatio:number
}

const useStyles = makeStyles({
  buttonGroup: {
    '& .MuiButton-root': {
      height: 64, // Ensure buttons have the same minimum width
      width: 64, // Ensure buttons have the same minimum width
    },
    '& i.fas': {
      fontSize: '1.5rem',
    }
  },
});

const Footer = ({fullscreen, zoomRatio, onChangeFullscreen}:IProps) => {

  const {designController} = useDesignContext();
  const interactionMode = useSelector((state: any) => state.editor.interactionMode);
  const [selected, setSelected] = useState<string>(null);
  const [templatesOpen, setTemplatesOpen] = useState<boolean>(false);
  const dispatch= useDispatch();

  const classes = useStyles();

  const add_sprite= () => {
    designController.interactionHandler.add_sprite();
  };

  const selection= () => {
    if (designController.interactionHandler.isDrawingMode()) {
      if (interactionMode === InteractionModes.POLYGON) {
        designController.drawingHandler.polygon.finish();
      }
    }
    designController.interactionHandler.selection();
  };

  const grab= () => {
    if (designController.interactionHandler.isDrawingMode()){
      if (interactionMode === InteractionModes.POLYGON) {
        designController.drawingHandler.polygon.finish();
      }
    }
    designController.interactionHandler.grab();
  }

  const lines = (glitch_type,style) => {
    if (designController.interactionHandler.isDrawingMode()){
      if (interactionMode === InteractionModes.POLYGON) {
        designController.drawingHandler.polygon.finish();
      }
    }
    // designController.drawingHandler.polygon.init({glitch_type, ...style})
    designController.drawingHandler.line.init({glitch_type, ...style})
  }

  const spot = () => {
    setSelected('spot');
  }

  const zoomValue = parseInt((zoomRatio * 100).toFixed(2), 10);

  return <>
  {selected == 'spot' ? <>
  <SelectSpriteModal open={selected=='spot'} onClose={()=>setSelected(null)} onSelect={(sprite)=>{
    dispatch(setSelectedSprite(sprite));
    designController.interactionHandler.spot();
    setSelected(null)
  }} />
  </> : null}

      <div className="rde-editor-footer-toolbar-interaction">
        <ButtonGroup size="medium" variant="contained" className={classes.buttonGroup}>
            
          <CommonButton
            color={'default'}
            onClick={() => setTemplatesOpen(true)}
            icon="mountain-sun"
            tooltipTitle={i18n.t('action.add_sprite')}
          />


          <CommonButton
            color={interactionMode === InteractionModes.ADD_SPRITE ? 'primary' : 'default'}
            onClick={() => interactionMode === InteractionModes.ADD_SPRITE ?  selection() : add_sprite()}
            icon="smile-plus"
            tooltipTitle={i18n.t('action.add_sprite')}
          />

          <CommonButton
            color={interactionMode === InteractionModes.SELECTION ? 'primary' : 'default'}
            onClick={() => selection()}
            icon="mouse-pointer"
            tooltipTitle={i18n.t('action.selection')}
          />

        <SplitButton options={[InteractionModes.LINE, InteractionModes.LADDER, InteractionModes.WALL]} />

          <CommonButton
            color={interactionMode === InteractionModes.SPOT ? 'primary' : 'default'}
            onClick={spot}
            icon="person-to-door"
            tooltipTitle={i18n.t('action.anchor')}
          />

          <CommonButton
            color={interactionMode === InteractionModes.GRAB ? 'primary' : 'default'}
            onClick={() => grab()}
            tooltipTitle={i18n.t('action.grab')}
            icon="hand-rock"
          />
        </ButtonGroup>
      </div>

      <div className="rde-editor-footer-toolbar-zoom">
        <ButtonGroup size="medium" variant="contained" className={classes.buttonGroup}>
          <CommonButton onClick={() => designController.zoomHandler.zoomOut()} icon="search-minus" tooltipTitle={i18n.t('action.zoom-out')} />

          <CommonButton onClick={() => designController.zoomHandler.zoomOneToOne()} tooltipTitle={i18n.t('action.one-to-one')} >
            {`${zoomValue}%`}
          </CommonButton>

          <CommonButton  onClick={() => designController.zoomHandler.zoomToFitScrolled()} tooltipTitle={i18n.t('action.fit')} icon="expand" />
          <CommonButton 
            onClick={() => designController.zoomHandler.zoomIn()}
            icon="search-plus"
            tooltipTitle={i18n.t('action.zoom-in')}
          />
        </ButtonGroup>
    </div>

    <SelectTemplate open={templatesOpen} onClose={()=>setTemplatesOpen(false)}>
    </SelectTemplate>

    {!isMobile &&
    <div className="rde-editor-footer-toolbar-preview">
      <Switch checked={fullscreen} onChange={onChangeFullscreen} />
    </div>}

  </>
}

export default Footer;
