import SpacePreview from '../spaces/preview';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Spaces, Games } from 'api/agent';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {new_creatix_space_url, play_game_url} from 'utils/urls';
import {T} from 'components/utils/t';
import {IconButton, Tooltip} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {GameAttributes} from 'models/game';
import ConfirmableButton from 'components/common/confirmable_button';
import SpaceConnections from './space_viz';
import {SpotAttrs} from 'components/actions_config';

const GameShow: React.FC<any> = ({ spaces, history, match }) => {

  const currentUser = useSelector((state:any) => state.common.currentUser);
  const [game, setGame] = useState<GameAttributes>(null);
  const [spacesState, setSpaces] = useState(null);
  const [destroyed, setDestroyed] = useState(false);
  const [cloned, setCloned] = useState(null);
  const [allSpots, setSpots] = useState<SpotAttrs[]>([]);

  useEffect(() => {
    Spaces.list(match.params.uuid).then((res) => {
      setSpaces(res);
    });

    Games.list_spots(match.params.uuid).then((spots) => {
      setSpots(spots.data)
    })

    Games.show(match.params.uuid).then((res) => {
      setGame(res);
    });
  }, [match.params.uuid]);

  const destroyGame = async () => {
    await Games.destroy(game.uuid);
    setDestroyed(true);
  };

  const cloneGame = async () => {
    if(!window.confirm('Are you sure you want to clone this game?'))
      return;
    const clonedGame = await Games.clone(game.uuid);
    setCloned(clonedGame);
  };

  const togglePublish = async () => {
    const newPublishedState = game.status != 'public';
    const game2 = await Games.set_published(game.uuid, newPublishedState);
    setGame(game2)
  };

  if (destroyed) {
    return <Redirect to="/games" />;
  }
  if (cloned) {
    return <Redirect to={`/games/${cloned.uuid}`} />;
  }
  if (!spacesState) {
    return <div className="article-preview"><T k="games.show.loading">Loading...</T></div>;
  }

  return (<>
  <Helmet>
    <title>{game?.name}</title>
  </Helmet>

    <div>
      {game && (
        <div className="row mt-4">
          <div className="col-12">
            <Link className="btn btn-link" to="/games"><i className="fa fa-arrow-left"/>Back</Link>
          </div>
          <div className="col-12">
            <h1>{game.name}</h1>
          </div>
          <div className="col-12">
            {game.canEdit && <Link className='btn btn-sm btn-outline-warning' to={`/games/${game.uuid}/edit`}><T k="games.show.edit_btn">Edit</T></Link>}
        {game.canEdit && <ConfirmableButton className='ml-2 btn btn-sm btn-outline-danger'
          onConfirm={destroyGame}
          confirmationMessage='Are you sure you want to destroy this game and all of its spaces?'
        ><T k="games.show.destroy_btn">Destroy</T></ConfirmableButton>}
    
            {currentUser &&  <a className='ml-2 btn btn-sm btn-outline-primary' onClick={cloneGame}><T k="games.show.clone_btn">Clone</T></a>}
            {game.canEdit && 
              <span>
                <button className='ml-2 btn btn-sm btn-success' onClick={togglePublish}>
                  <T k={game.status == 'public' ? "games.show.unpublish_btn" : "games.show.publish_btn"}>
                    {game.status == 'public' ? "Unpublish" : "Publish"}
                  </T>
                </button>
                <Tooltip title="When published, everybody can see this game. When unpublished, it's considered work in progress and only you can see it.">
                  <IconButton size="small" className="ml-2">
                    <HelpOutlineIcon fontSize="small"/>
                  </IconButton>
                </Tooltip>
              </span>
            }
            {false && game.canEdit && <Link className='ml-2 btn btn-sm btn-outline-info' to={`/games/${game.uuid}/scripts`}><T k="games.show.scripts_btn">Scripts</T></Link>}
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-12 mt-4 mb-4">
          <h3><T k="games.show.spaces_title">Espacios que pertenecen a este juego</T></h3>
        </div>
        {
          spacesState.map((space) => {
            const url = play_game_url(space.uuid)
            return (
              <div className="col-12 col-md-3" key={`container-${space.uuid}`} >
                <SpacePreview space={space} game={game} onClick={() => window.open(`/#${url}`,'_blank')} />
              </div>
            );
          })
        }
        {game?.canEdit &&
          <div className="col-12 col-md-3">
            <div className="btn btn-primary btn-xl" onClick={() => history.push(new_creatix_space_url(game.uuid))}><T k="games.show.add_space_btn">Add Space</T></div>
          </div>
        }
      </div>

      <div className="row mt-4">
        <SpaceConnections apiData={allSpots}></SpaceConnections>
      </div>

    </div>
  </>
  );
};

export default withRouter(GameShow);
