import {GeneratedAsset, GlitchGame, PromptGenerator} from "components/glitch/space";
import {GameAttributes} from "models/game";
import {SpaceAttributes} from "models/space";
import React, { createContext, FC, useState, useContext } from "react";
import { FabricObject, CanvasController, FabricCanvas } from "../components/canvas";

export enum PanelKinds {

  STICKERS='stickers',
  GENERATIVE_AI='generative_ai',
  AI_LAYERS='ai_layers',
  TEMPLATES='templates',
  FILTERS='filters',
  DESIGNS='designs',
  COLOR='color',
  TYPOGRAPHY='typography',
  ICONS='icons',
  LAYERS='layers',
  LINES='lines',
  ANCHORS='anchors',
  ELEMENTS=  'elements',
  NPCS=  'npcs',
  UPLOADS=   'uploads',
  TEXT=      'text',
  SHADOW=    'shadow',
  STYLES=    'styles',
  BACKGROUND='background',
  OTHER= 'other',
};

export const PanelTitles = {
  [PanelKinds.AI_LAYERS]: 'AI Layers',
  [PanelKinds.NPCS]: 'NPCS',
  [PanelKinds.STICKERS]: 'Stickers',
  [PanelKinds.GENERATIVE_AI]: 'Generative AI',
  [PanelKinds.FILTERS]: 'Filters', 
  [PanelKinds.TEMPLATES]: 'Templates', 
  [PanelKinds.DESIGNS]: 'Designs',
  [PanelKinds.COLOR]: 'Color',
  [PanelKinds.TYPOGRAPHY]: 'Typography',
  [PanelKinds.ICONS]: 'Icons',
  [PanelKinds.LAYERS]: 'Layers',
  [PanelKinds.LINES]: 'Lines',
  [PanelKinds.ANCHORS]: 'Spots',
  [PanelKinds.ELEMENTS]: 'Elements',
  [PanelKinds.UPLOADS]: 'Images',
  [PanelKinds.TEXT]: 'Text',
  [PanelKinds.SHADOW]: 'Shadow',
  [PanelKinds.STYLES]: 'Styles',
  [PanelKinds.BACKGROUND]: 'Background',
  [PanelKinds.OTHER]: 'Other',
}


export interface IDesignContext {
  fabricCanvas:FabricCanvas|null
  setFabricCanvas: React.Dispatch<React.SetStateAction<FabricCanvas>>

  designController:CanvasController|null
  setDesignController: React.Dispatch<React.SetStateAction<CanvasController>>

  generatedAssets:GeneratedAsset[]
  setGeneratedAssets: React.Dispatch<React.SetStateAction<GeneratedAsset[]>>

  layerId:string|null
  setLayerId: React.Dispatch<React.SetStateAction<string>>

  layerState: {[key:string]:any}
  setLayerState: React.Dispatch<React.SetStateAction<{[key:string]:any}>>

  space:SpaceAttributes|null
  setSpace: React.Dispatch<React.SetStateAction<SpaceAttributes>>

  selectedObject:FabricObject|null, // TODO any extends fabric.Object
  setSelectedObject: React.Dispatch<React.SetStateAction<any>>
}

export const DesignContext = createContext<IDesignContext>({
  fabricCanvas:null,
  setFabricCanvas: (x:FabricCanvas) => {},

  designController:null,
  setDesignController: (x:CanvasController) => {},

  layerId:'middleground',
  setLayerId: (x:string) => {},

  layerState:{},
  setLayerState: (x:{[key:string]:any}) => {},

  space: null,
  setSpace: (x:SpaceAttributes) => {},

  selectedObject:null,
  setSelectedObject: (x:any|null) => {},

})

export const DesignProvider:FC<any> = ({children}) => {

  const [generatedAssets, setGeneratedAssets] = useState<GeneratedAsset[]>([])
  const [layerId, setLayerId] = useState<string|null>('middleground')
  const [layerState, setLayerState] = useState<{[key:string]:any}>({})
  const [space, setSpace] = useState<SpaceAttributes|null>(null)
  const [designController, setDesignController] = useState<CanvasController|null>(null)
  const [selectedObject, setSelectedObject] = useState<any|null>(null)
  const [fabricCanvas, setFabricCanvas] = useState<FabricCanvas>(null)

  const context = {
    fabricCanvas,
    setFabricCanvas,

    designController,
    setDesignController,

    generatedAssets,
    setGeneratedAssets,

    layerState,
    setLayerState,

    layerId,
    setLayerId,

    space,
    setSpace,

    selectedObject,
    setSelectedObject,

  };
  return <DesignContext.Provider value={context}>{children}</DesignContext.Provider>
}

export function useDesignContext() {
  return useContext(DesignContext)
}
