import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, DialogActions, Dialog, DialogContent, DialogTitle, makeStyles, ButtonBase } from '@material-ui/core';
import { SIZES } from 'utils/prompt_modifiers';
import { DndProvider, useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import {Pictures} from 'api/agent';
import {blip_url} from 'utils/urls';
import {useSelector, useDispatch} from 'react-redux';
import {setBackground, setLoading, setPlace, setSize} from 'redux/reducers/creatix';
import {T} from 'components/utils/t';

const useStyles = makeStyles((theme) => ({
  selected: {
    color: 'white',
    backgroundColor: `${theme.palette.primary.main}`,
  },
  button:{
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    width: 120,
    height: 120,
    margin: '5px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  notSelected: {
    color: 'black',
    backgroundColor: 'white',
  },
  sizeButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 120,
    margin: 10,
    padding: 10,
    border: '2px solid #ccc',
  }}))

const ImageDropZone = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (monitor) {
        const files = (monitor.getItem() as any).files;
        const file = files[0];
        if (file && file.type.startsWith('image/')) {
          onDrop(file);
        } else {
          alert('Please upload a valid image file.');
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        padding: 20,
        textAlign: 'center',
        backgroundColor: isOver ? '#e0e0e0' : 'white',
      }}
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-file-upload"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file && file.type.startsWith('image/')) {
            onDrop(file);
          } else {
            alert('Please upload a valid image file.');
          }
        }}
      />
      <label htmlFor="image-file-upload">
        <Button variant="contained" component="span">
          Upload Image to Set Size
        </Button>
      </label>
      <p>Drag and drop an image file here, or click to select one</p>
    </div>
  );
};

const SizeButton = ({ size, label, isSelected, onClick }) => {
  const [width, height] = size;
  const aspectRatio = width / height;
  const buttonSize = 120;
  const rectWidth = aspectRatio > 1 ? buttonSize - 20 : (buttonSize - 20) * aspectRatio;
  const rectHeight = aspectRatio > 1 ? (buttonSize - 20) / aspectRatio : buttonSize - 20;
  const classes = useStyles();

  const svgContent = `
    <svg width="${rectWidth}" height="${rectHeight}" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="100%" height="100%" fill="black" stroke="black" stroke-width="0"/>
    </svg>
  `;
  const svgDataUriBase64 = `data:image/svg+xml;base64,${btoa(svgContent)}`;

  return (
    <ButtonBase
      onClick={onClick}
      color="primary"
      className={[isSelected ? classes.selected : classes.notSelected, classes.button].join(' ')}
    >
      <div style={{backgroundImage: `url(${svgDataUriBase64})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '70%', height: '70%'}} />
      <div>{label}</div>
    </ButtonBase>
  );
};

export default function Size({ handleNext, idx }) {
  const [imageFile, setImageFile] = useState(null);
  const [showDropzone, setShowDropzone] = useState(false);

  const dispatch=useDispatch();

  const size = useSelector((state:any) => state.creatix.size);
  const title = useSelector((state:any) => state.creatix.title);
  const loading = useSelector((state:any) => state.creatix.loading);

  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  useEffect(() => {
    if (imageFile) {
      console.log('imageFile', imageFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log('e.target.result', e.target.result);
        const img = new Image();
        img.onload = () => {
          // round width to multiples of 64
          const width = Math.ceil(img.width / 64) * 64;
          const height = Math.ceil(img.height / 64) * 64;
          if(width < 5000 && height < 5000 && width*height < 15000000) {
            dispatch(setSize([width, height]));
          } else {
            alert('Image size should be less than 5000x5000 and <15Mpixel');
          }
        };
        img.onerror = (err) => {
          console.error(err);
        };
        img.src = e.target.result as string;
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  const handleFileUpload = async (file) => {
    setImageFile(file);
  };

  const saveAndContinue = async () => {

    if(!imageFile){
      handleNext(idx + 1);
      return;
    }

    dispatch(setLoading(true));
    try {
      const created = await Pictures.create(imageFile.name, imageFile);
      dispatch(setBackground({...created, url: created.image_url}));

      console.log(created);
      const url = blip_url();
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
        body: JSON.stringify({
          url: created.image_url,
        }),
      });
      const data = await resp.json();
      console.log(data);
      dispatch(setPlace(data.output.replace(/^Caption: /, '')));

      handleNext(idx + 1);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  }

  return (<>

  <Helmet>
    <title>{`Pick a size for your game.`}</title>
  </Helmet>

    <Dialog
      BackdropProps={{ style: { display: 'none' } }}
      disableEscapeKeyDown
      className="game_welcome step3"
      open={true}
      onClose={()=>void 0}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={false}
      style={{ height: '80%' }}
    >
      <DialogTitle id="alert-dialog-title-2" disableTypography={true}>
        <T k="creatix.size.title" title={title}>
          Set the size of the stage for game "{title}"
        </T>
    </DialogTitle>

      <DialogContent>
        <DndProvider backend={HTML5Backend}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Predefined size buttons with proportional rectangles */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {Object.entries(SIZES).map(([k, v]) => (
                <SizeButton
                  key={k}
                  size={v}
                  label={k}
                  isSelected={JSON.stringify(size) === JSON.stringify(v)}
                  onClick={() => dispatch(setSize(v))}
                />
              ))}
            </Box>

            <Box sx={{ marginTop: 2 }}>
              {Object.entries(SIZES).map(([k, v]) => {
                const [width, height] = v;
                if (JSON.stringify(size) === JSON.stringify(v)) {
                  return (
                    <Box key={k} sx={{ textAlign: 'center', marginTop: 1 }}>
                      <div><small>{width}x{height}</small></div>
                      <div className="d-none">{k}</div>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>

            <Box sx={{ marginTop: 2 }}>
            {showDropzone ?
              <ImageDropZone onDrop={handleFileUpload} />
              :
              <Button variant="text" onClick={() => setShowDropzone(true)}><T k="creatix.size.upload.btn">Upload an image instead</T></Button>
            }
          </Box>

            {imageFile && (
              <Box>
                <p><T k="creatix.size.selected_image.label">{`Selected Image: ${imageFile.name}`}</T></p>
              </Box>
            )}
            {size && (
              <Box className="d-none">
                <p><T k="creatix.size.selected_size.label">{`Selected Size: ${size[0]} x ${size[1]}`}</T></p>
              </Box>
            )}
          </Box>
        </DndProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleNext(idx - 1)} color="primary" disabled={!!loading}>
          <T k="createix.size.previous">Previous</T>
        </Button>
        <Button onClick={() => saveAndContinue()} color="primary" disabled={!size || !!loading}>
          <T k="createix.size.next">Next
        </T>        </Button>
      </DialogActions>
    </Dialog>
  </>);
}
