import { fabric } from 'fabric';
import warning from 'warning';
import { v4 } from 'uuid';
import { cloneDeep, pick, sortBy, union } from 'lodash';
import {store} from 'redux/store';

const DEFAULT_IMAGE = './images/sample/transparentBg.png';

const copyable_properties = {
  'textbox': ['fill','opacity','stroke','strokeWidth','textAlign','fontWeight','fontFamily','fontSize','underline','shadow','angle','charSpacing','lineHeight','fitBox'],

  'image': ['opacity','stroke','strokeWidth','shadow','angle'],

   '*': ['opacity','stroke','strokeWidth','shadow','angle','fill','opacity','stroke','strokeWidth','textAlign','fontWeight','fontFamily','fontSize','underline','shadow','angle','charSpacing','lineHeight'].concat(CUSTOM_PROPERTIES),
}

//const non_copyable_properties = ['id'];


import {
  ElementHandler,
  ImageHandler,
  /*ChartHandler,*/
  CropHandler,
  ContextmenuHandler,
  TooltipHandler,
  ZoomHandler,
  WorkareaHandler,
  TransactionHandler,
  AlignmentHandler,
  GuidelineHandler,
  GridHandler,
  EventHandler,
  DrawingHandler,
  InteractionHandler,
  FrameHandler,
  ShortcutHandler,
} from '.';

import {
  FabricObject,
  FabricImage,
  WorkareaObject,
  WorkareaOption,
  InteractionMode,
  CanvasOption,
  GridOption,
  GuidelineOption,
  KeyEvent,
  FabricObjectOption,
  FabricElement,
  FabricCanvas,
  FabricGroup,
  FabricObjects,
  InteractionModes,
} from '../utils';

import CanvasObject from '../CanvasObject';
import { TransactionEvent } from './TransactionHandler';
import { defaults } from '../constants';
import ControlsCustomizationHandler from './customization';
import TemplateHandler from './TemplateHandler';
import {CUSTOM_PROPERTIES, Z_IDX} from 'components/glitch/utils';
import GameHandler from './GameHandler';
import {GlitchGame} from 'components/glitch/space';
import LoadManager from 'utils/load_manager';
import {setInteractionMode} from 'redux/reducers/editor';

export interface HandlerCallback {
  /**
   * When has been added object in Canvas, Called function
   *
   */
  onAdd?: (object: FabricObject) => void;
  /**
   * Return contextmenu element
   *
   */
  onContext?: (e: React.MouseEvent, target?: FabricObject, controller?:CanvasController) => Promise<any> | any;
  /**
   * Return tooltip element
   *
   */
  onTooltip?: (el: HTMLDivElement, target?: FabricObject) => Promise<any> | any;
  /**
   * When zoom, Called function
   */
  onZoom?: (zoomRatio: number) => void;
  /**
   * When clicked object, Called function
   *
   */
  onClick?: (canvas: FabricCanvas, target: FabricObject) => void;
  /**
   * When double clicked object, Called function
   *
   */
  onDblClick?: (canvas: FabricCanvas, target: FabricObject) => void;
  /**
   * When modified object, Called function
   */
  onModified?: (target: FabricObject) => void;
  /**
   * When select object, Called function
   *
   */
  onSelect?: (target: FabricObject) => void;
  /**
   * When has been removed object in Canvas, Called function
   *
   */
  onRemove?: (target: FabricObject) => void;
  /**
   * When has been undo or redo, Called function
   *
   */
  onTransaction?: (transaction: TransactionEvent) => void;
  /**
   * When has been changed interaction mode, Called function
   *
   */
  onInteraction?: (interactionMode: InteractionMode) => void;
  /**
   * When canvas has been loaded
   *
   */
  onLoad?: (handler: CanvasController, canvas?: fabric.Canvas) => void;
}

export interface HandlerOption {
  /**
   * Canvas id
   * @type {string}
   */
  id?: string;
  /**
   * Canvas object
   * @type {FabricCanvas}
   */
  canvas?: FabricCanvas;
  /**
   * Canvas parent element
   * @type {HTMLDivElement}
   */
  container?: HTMLDivElement;
  frame?: HTMLDivElement;
  /**
   * Canvas editable
   * @type {boolean}
   */
  editable?: boolean;
  /**
   * Canvas interaction mode
   * @type {InteractionMode}
   */
  interactionMode?: InteractionMode;
  /**
   * Persist properties for object
   * @type {string[]}
   */
  propertiesToInclude?: string[];
  /**
   * Minimum zoom ratio
   * @type {number}
   */
  minZoom?: number;
  /**
   * Maximum zoom ratio
   * @type {number}
   */
  maxZoom?: number;
  /**
   * Workarea option
   * @type {WorkareaOption}
   */
  workareaOption?: WorkareaOption;
  /**
   * Canvas option
   * @type {CanvasOption}
   */
  canvasOption?: CanvasOption;
  /**
   * Grid option
   * @type {GridOption}
   */
  gridOption?: GridOption;
  /**
   * Default option for Fabric Object
   * @type {FabricObjectOption}
   */
  objectOption?: FabricObjectOption;
  /**
   * Guideline option
   * @type {GuidelineOption}
   */
  guidelineOption?: GuidelineOption;
  /**
   * Whether to use zoom
   * @type {boolean}
   */
  zoomEnabled?: boolean;
  /**
   * ActiveSelection option
   * @type {Partial<FabricObjectOption<fabric.ActiveSelection>>}
   */
  activeSelectionOption?: Partial<FabricObjectOption<fabric.ActiveSelection>>;
  /**
   * Canvas width
   * @type {number}
   */
  width?: number;
  /**
   * Canvas height
   * @type {number}
   */
  height?: number;
  /**
   * Keyboard event in Canvas
   * @type {KeyEvent}
   */
  keyEvent?: KeyEvent;
  /**
   * Append custom objects
   * @type {{ [key: string]: any }}
   */
  fabricObjects?: FabricObjects;
  [key: string]: any;
}

export type HandlerOptions = HandlerOption & HandlerCallback;

/**
 * Main handler for Canvas
 * @class Handler
 * @implements {HandlerOptions}
 */
class CanvasController implements HandlerOptions {
  public id: string;
  public canvas: FabricCanvas;
  public workarea: WorkareaObject;
  public container: HTMLDivElement;
  public editable: boolean;

  public minZoom: number;
  public maxZoom: number;
  public propertiesToInclude?: string[] = defaults.propertiesToInclude;
  public workareaOption?: WorkareaOption = defaults.workareaOption;
  public canvasOption?: CanvasOption = defaults.canvasOption;
  public gridOption?: GridOption = defaults.gridOption;
  public objectOption?: FabricObjectOption = defaults.objectOption;
  public guidelineOption?: GuidelineOption = defaults.guidelineOption;
  public keyEvent?: KeyEvent = defaults.keyEvent;
  public activeSelectionOption?: Partial<FabricObjectOption<fabric.ActiveSelection>> = defaults.activeSelectionOption;
  public fabricObjects?: FabricObjects = CanvasObject;
  public zoomEnabled?: boolean;

  private _width?: number;

  private _gameData:GlitchGame;

  public autosave: boolean=true;

  public save:()=>void=null;

  public get gameData(): GlitchGame{
    return this._gameData;
  }

  public set gameData(gameData: GlitchGame){
    this._gameData = gameData;
  }

  public setInteractionMode:(InteractionMode)=>void = (mode:InteractionMode)=>{
    store.dispatch(setInteractionMode(mode));
  };

  public get width():number{
    return this._width;
  }
  public set width(value:number){
    this._width = value;
  }

  private _height?: number;
  public get height():number{
    return this._height;
  }
  public set height(value:number){
    this._height = value;
  }

  public onAdd?: (object: FabricObject) => void;
  public onContext?: (e: React.MouseEvent, target?: FabricObject, controller?:CanvasController) => Promise<any>;
  public onTooltip?: (el: HTMLDivElement, target?: FabricObject) => Promise<any>;
  public onZoom?: (zoomRatio: number) => void;
  public onClick?: (canvas: FabricCanvas, target: FabricObject) => void;
  public onDblClick?: (canvas: FabricCanvas, target: FabricObject) => void;
  public onModified?: (target: FabricObject) => void;
  public onSelect?: (target: FabricObject) => void;
  public onRemove?: (target: FabricObject) => void;
  public onTransaction?: (transaction: TransactionEvent) => void;
  public onInteraction?: (interactionMode: InteractionMode) => void;
  public onLoad?: (handler: CanvasController, canvas?: fabric.Canvas) => void;

  public templateHandler: TemplateHandler;
  public imageHandler: ImageHandler;
  /*public chartHandler: ChartHandler;*/
  public elementHandler: ElementHandler;
  public cropHandler: CropHandler;
  public animationHandler: any;
  public contextmenuHandler: ContextmenuHandler;
  public tooltipHandler: TooltipHandler;
  public zoomHandler: ZoomHandler;
  public workareaHandler: WorkareaHandler;
  public interactionHandler: InteractionHandler;
  public frameHandler: FrameHandler;
  public transactionHandler: TransactionHandler;
  public gridHandler: GridHandler;
  public alignmentHandler: AlignmentHandler;
  public guidelineHandler: GuidelineHandler;
  public eventHandler: EventHandler;
  public drawingHandler: DrawingHandler;
  public shortcutHandler: ShortcutHandler;
  public gameHandler: GameHandler; 

  /* Circuit/No-Code/graph composition */
  //public portHandler: PortHandler;
  //public linkHandler: LinkHandler;
  //public nodeHandler: NodeHandler;

  public objectMap: Record<string, FabricObject> = {};
  public objects: FabricObject[];
  public activeLine?: any;
  public activeShape?: any;
  public zoom = 1;
  public prevTarget?: FabricObject;
  public target?: FabricObject;
  public pointArray?: any[];
  public lineArray?: any[];
  public isCut = false;

  private isRequsetAnimFrame = false;
  private requestFrame: any;
  private clipboard: any;

  copied_actions: any[];

  copied_style: {
    type: string;
    props: Pick<any, any>;
  };

  customizationHandler: ControlsCustomizationHandler;
  frame: HTMLDivElement;

  constructor(options: HandlerOptions) {
    if(process.env.NODE_ENV == 'development'){
      (window as any).controller = this;
    }

    this.initialize(options);
  }

  public setDimensions({width,height}){
    this.workarea.set({width,height});
    this.canvas.requestRenderAll();
    this.frameHandler?.recalcPosition();
  }

  /**
   * Initialize handler
   *
   * @author salgum1114
   * @param {HandlerOptions} options
   */
  public initialize(options: HandlerOptions) {
    this.initOption(options);
    this.initCallback(options);
    this.initHandler();
    // re-init workarea options
    this.setWorkareaOption(options.workareaOption);
    if(options.workareaOption?.src)
      this.workareaHandler.setImage(options.workareaOption.src);

  }

  /**
   * Init class fields
   * @param {HandlerOptions} options
   */
  public initOption = (options: HandlerOptions) => {
    this.id = options.id;
    this.canvas = options.canvas;
    this.container = options.container;
    this.frame = options.frame;
    this.editable = options.editable;
    this.minZoom = options.minZoom;
    this.maxZoom = options.maxZoom;
    this.zoomEnabled = options.zoomEnabled;
    this.width = options.width;
    this.height = options.height;
    this.objects = [];
    this.setPropertiesToInclude(options.propertiesToInclude);
    this.setWorkareaOption(options.workareaOption);
    this.setCanvasOption(options.canvasOption);
    this.setGridOption(options.gridOption);
    this.setObjectOption(options.objectOption);
    this.setFabricObjects(options.fabricObjects);
    this.setGuidelineOption(options.guidelineOption);
    this.setActiveSelectionOption(options.activeSelectionOption);
    this.setKeyEvent(options.keyEvent);
  };

  /**
   * Initialize callback
   * @param {HandlerOptions} options
   */
  public initCallback = (options: HandlerOptions) => {
    this.onAdd = options.onAdd;
    this.onTooltip = options.onTooltip;
    this.onZoom = options.onZoom;
    this.onContext = options.onContext;
    this.onClick = options.onClick;
    this.onModified = options.onModified;
    this.onDblClick = options.onDblClick;
    this.onSelect = options.onSelect;
    this.onRemove = options.onRemove;
    this.onTransaction = options.onTransaction;
    this.onInteraction = options.onInteraction;
    this.onLoad = options.onLoad;
  };

  /**
   * Initialize handlers
   *
   */
  public initHandler = () => {
    this.templateHandler = new TemplateHandler(this);
    this.workareaHandler = new WorkareaHandler(this);
    this.imageHandler = new ImageHandler(this);
    /*this.chartHandler = new ChartHandler(this);*/
    this.elementHandler = new ElementHandler(this);
    this.cropHandler = new CropHandler(this);
    this.animationHandler = null;//new AnimationHandler(this);
    this.contextmenuHandler = new ContextmenuHandler(this);
    this.tooltipHandler = new TooltipHandler(this);
    this.zoomHandler = new ZoomHandler(this);
    this.interactionHandler = new InteractionHandler(this);
    if(this.frame)
      this.frameHandler = new FrameHandler(this);
    this.transactionHandler = new TransactionHandler(this);
    this.gridHandler = new GridHandler(this);
    //this.portHandler = new PortHandler(this);
    //this.linkHandler = new LinkHandler(this);
    //this.nodeHandler = new NodeHandler(this);
    this.alignmentHandler = new AlignmentHandler(this);
    this.guidelineHandler = new GuidelineHandler(this);
    this.eventHandler = new EventHandler(this);
    this.drawingHandler = new DrawingHandler(this);
    this.gameHandler = new GameHandler(this);

    this.customizationHandler = new ControlsCustomizationHandler(this);

    if(this.editable){
      this.shortcutHandler = new ShortcutHandler(this);
    }
  };

  public async applyVariables(vars):Promise<any> {
    //await applyVariablesToObjects(this.getObjects(), vars);
    this.canvas.requestRenderAll();
  }
  /**
   * Get primary object
   * @returns {FabricObject[]}
   */
  public getObjects = (): FabricObject[] => {
    const objects = this.canvas.getObjects().filter((obj: FabricObject) => obj?.id && !['workarea','grid','port'].includes(obj.id)) as FabricObject[];

    if (objects.length) {
      objects.forEach(obj => this.objectMap[obj.id] = obj);
    } else {
      this.objectMap = {};
    }
    return objects;
  };

  /**
   * Set key pair
   * @param {keyof FabricObject} key
   * @param {*} value
   * @returns
   */
  public set = (key: keyof FabricObject, value: any) => {
    const activeObject = this.canvas.getActiveObject() as any;
    if (!activeObject) {
      return;
    }
    activeObject.set(key, value);
    activeObject.setCoords();
    this.canvas.requestRenderAll();
    const { id, superType, type, player, width, height } = activeObject as any;

    if (superType === 'element') {
      if (key === 'visible') {
        if (value) {
          activeObject.element.style.display = 'block';
        } else {
          activeObject.element.style.display = 'none';
        }
      }
      const el = this.elementHandler.findById(id);
      // update the element
      this.elementHandler.setScaleOrAngle(el, activeObject);
      this.elementHandler.setSize(el, activeObject);
      this.elementHandler.setPosition(el, activeObject);
      if (type === 'video' && player) {
        player.setPlayerSize(width, height);
      }
    }

    this.onModified?.(activeObject);
  };

  /**
   * Set option
   * @param {Partial<FabricObject>} option
   * @returns
   */
  public setObject = (option: Partial<FabricObject>) => {
    const activeObject = this.canvas.getActiveObject() as any;
    if (!activeObject) {
      return;
    }
    Object.keys(option).forEach(key => {
      if (option[key] !== activeObject[key]) {
        activeObject.set(key, option[key]);
        activeObject.setCoords();
      }
    });
    this.canvas.requestRenderAll();
    const { id, superType, type, player, width, height } = activeObject;
    if (superType === 'element') {
      if ('visible' in option) {
        if (option.visible) {
          activeObject.element.style.display = 'block';
        } else {
          activeObject.element.style.display = 'none';
        }
      }
      const el = this.elementHandler.findById(id);
      // update the element
      this.elementHandler.setScaleOrAngle(el, activeObject);
      this.elementHandler.setSize(el, activeObject);
      this.elementHandler.setPosition(el, activeObject);
      if (type === 'video' && player) {
        player.setPlayerSize(width, height);
      }
    }

    this.onModified?.(activeObject);
  };

  /**
   * Set key pair by object
   * @param {FabricObject} obj
   * @param {string} key
   * @param {*} value
   * @returns
   */
  public setByObject = (obj: any, key: string, value: any) => {
    if (!obj) {
      return;
    }
    obj.set(key, value);
    obj.setCoords();
    this.canvas.renderAll();
    const { id, superType, type, player, width, height } = obj as any;

    if (superType === 'element') {
      if (key === 'visible') {
        if (value) {
          obj.element.style.display = 'block';
        } else {
          obj.element.style.display = 'none';
        }
      }
      const el = this.elementHandler.findById(id);
      // update the element
      this.elementHandler.setScaleOrAngle(el, obj);
      this.elementHandler.setSize(el, obj);
      this.elementHandler.setPosition(el, obj);
      if (type === 'video' && player) {
        player.setPlayerSize(width, height);
      }
    }

    this.onModified?.(obj);
  };

  /**
   * Set key pair by id
   * @param {string} id
   * @param {string} key
   * @param {*} value
   */
  public setById = (id: string, key: string, value: any) => {
    const findObject = this.findById(id);
    this.setByObject(findObject, key, value);
  };

  /**
   * Set partial by object
   * @param {FabricObject} obj
   * @param {FabricObjectOption} option
   * @returns
   */
  public setByPartial = (obj: FabricObject, option: FabricObjectOption) => {
    if (!obj) {
      return;
    }
    obj.set(option);
    obj.setCoords();
    this.canvas.renderAll();
    const { id, superType, type, player, width, height } = obj as any;
    if (superType === 'element') {
      if ('visible' in option) {
        if (option.visible) {
          obj.element.style.display = 'block';
        } else {
          obj.element.style.display = 'none';
        }
      }
      const el = this.elementHandler.findById(id);
      // update the element
      this.elementHandler.setScaleOrAngle(el, obj);
      this.elementHandler.setSize(el, obj);
      this.elementHandler.setPosition(el, obj);
      if (type === 'video' && player) {
        player.setPlayerSize(width, height);
      }
    }
  };

  /**
   * Set shadow
   * @param {fabric.Shadow} option
   * @returns
   */
  public setShadow = (option: fabric.IShadowOptions) => {
    const activeObject = this.canvas.getActiveObject() as FabricObject;
    if (!activeObject) {
      return;
    }
    activeObject.set('shadow', new fabric.Shadow(option) as fabric.Shadow);
    this.canvas.requestRenderAll();

    this.onModified?.(activeObject);
  };

  /**
   * Set the image
   * @param {FabricImage} obj
   * @param {(File | string)} [source]
   * @returns
   */
  public setImage(obj: FabricImage, source?: File | string) {
    if (!source) {
      return this.loadImage(obj, null).then((x) => {
        obj.set('file', null);
        obj.set('src', null);
      });
    }
    return new Promise((accept,reject) => {
      if (source instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          this.loadImage(obj, reader.result as string).then(accept, reject);
          obj.set('file', source);
          obj.set('src', null);
        };
        reader.readAsDataURL(source);
      } else {
        this.loadImage(obj, source).then(accept,reject);
        obj.set('file', null);
        obj.set('src', source);
      }
    })
  };

  /**
   * Set image by id
   * @param {string} id
   * @param {*} source
   */
  public setImageById(id: string, source: any) {
    const findObject = this.findById(id) as FabricImage;
    this.setImage(findObject, source);
  };

  /**
   * Set visible
   * @param {boolean} [visible]
   * @returns
   */
  public setVisible = (visible?: boolean) => {
    const activeObject = this.canvas.getActiveObject() as FabricElement;
    if (!activeObject) {
      return;
    }
    if (activeObject.superType === 'element') {
      if (visible) {
        activeObject.element.style.display = 'block';
      } else {
        activeObject.element.style.display = 'none';
      }
    }
    activeObject.set({
      visible,
    });
    this.canvas.renderAll();
  };

  /**
   * Set the position on Object
   *
   * @param {FabricObject} obj
   * @param {boolean} [centered]
   */
  public centerObject = (obj: FabricObject, centered?: boolean) => {
    if (centered) {
      this.canvas.centerObject(obj);
      obj.setCoords();
    } else {
      this.setByPartial(obj, {
        left:
        obj.left / this.canvas.getZoom() -
        obj.width / 2 -
        this.canvas.viewportTransform[4] / this.canvas.getZoom(),
        top:
        obj.top / this.canvas.getZoom() -
        obj.height / 2 -
        this.canvas.viewportTransform[5] / this.canvas.getZoom(),
      });
    }
  };

  /**
   * Add object
   * @param {FabricObjectOption} obj
   * @param {boolean} [centered=true]
   * @param {boolean} [loaded=false]
   * @returns
   */
  public async add(obj: FabricObjectOption, centered = true, loaded = false, maxWidth=0, onLoad:Function=null) {
    console.log('add', obj);
    const { editable, onAdd, gridOption, objectOption } = this;
    const option: any = {
      hasControls: editable,
      hasBorders: editable,
      selectable: editable,
      lockMovementX: !editable,
      lockMovementY: !editable,
      hoverCursor: !editable ? 'pointer' : 'move',
    };

    if (obj.type === 'i-text') {
      option.editable = false;
    } else {
      option.editable = editable;
    }

    if (editable && this.workarea.layout === 'fullscreen') {
      option.scaleX = this.workarea.scaleX;
      option.scaleY = this.workarea.scaleY;
    }

    const newOption = Object.assign(
      {},
      objectOption,
      obj,
      {
        container: this.container.id,
        editable,
      },
      option,
    );

    if (obj.clipPath)
      fabric.util.enlivenObjects([obj.clipPath], ([newClipPath]) => newOption.clipPath = newClipPath, null);

    // Create canvas object
    let createdObj;

    // TODO instead of glitch_type, we should propertly create fabric.Deco, fabric.Signpost, etc and use the type property
    if(obj.glitch_type){
      createdObj = this.fabricObjects[obj.glitch_type].create(newOption);

      if(maxWidth)
        createdObj.scaleToWidth(maxWidth);
    } else {

      if (obj.type === 'image') {
        createdObj = this.addImage(newOption, (x)=>{
          if(maxWidth && x.getOriginalSize().width > maxWidth)
            x.scaleToWidth(maxWidth);

          if(obj._scaleToWidth)
            x.set('scaleX', obj._scaleToWidth/x.getOriginalSize().width);

          if(obj._scaleToHeight)
            x.set('scaleY', obj._scaleToHeight/x.getOriginalSize().height);

          //if(onLoad)
          //  onLoad(createdObj)

          this.canvas.requestRenderAll()
        });
      } else if (obj.type === 'group') {
        // TODO...
        // Group add function needs to be fixed
        const objects = this.addGroup(newOption, centered, loaded);
        const groupOption = Object.assign({}, newOption, { objects, name: 'New Group' });
        createdObj = this.fabricObjects[obj.type].create(groupOption);
        //if(onLoad)
        //  onLoad(createdObj)
        //} else if (obj.type ==='polygon' && obj.glitch_type == 'platform_line'){
        //  obj.points.forEach((point: any, i) => {
        //    if(i==obj.points.length-1) return;
        //    const points = [point.x, point.y, obj.points[i+1].x, obj.points[i+1].y];
        //    const o = this.fabricObjects[obj.type].create({...newOption, points});
        //    if(onLoad)
        //      onLoad(o)
        //    this.canvas.add(o);
        //  })
      } 
    } 


    console.log('createdObj', createdObj);

    this.canvas.add(createdObj);

    if(['svg'].includes(obj.type)){
      // TODO get the width of the canvas, and apply say a 20%
      if(maxWidth)
        createdObj.scaleToWidth(maxWidth)
    }

    this.objects = this.getObjects();
    if (!editable && !(obj.superType === 'element')) {
      createdObj.on('mousedown', this.eventHandler.object.mousedown);
    }

    if (createdObj.dblclick) {
      createdObj.on('mousedblclick', this.eventHandler.object.mousedblclick);
    }

    if (this.objects.some(object => object.type === 'gif')) {
      this.startRequestAnimFrame();
    } else {
      this.stopRequestAnimFrame();
    }

    if (centered && obj.glitch_type != 'deco' && obj.superType !== 'drawing' && obj.superType !== 'link' && editable && !loaded) {
      this.centerObject(createdObj, centered);
    }
    //if (createdObj.superType === 'node') {
    //  this.portHandler.create(createdObj as NodeObject);
    //  if (createdObj.iconButton) {
    //    this.canvas.add(createdObj.iconButton);
    //  }
    //}
    if (!editable && createdObj.animation && createdObj.animation.autoplay) {
      this.animationHandler.play(createdObj.id);
    }
    if (gridOption.enabled) {
      this.gridHandler.setCoords(createdObj);
    }
    if (this.transactionHandler.active && !loaded) {
      this.transactionHandler.save('add');
    }

    if(onLoad)
      onLoad(createdObj)

    if (onAdd && editable && !loaded) {
      console.log('onAdd', createdObj);
      onAdd(createdObj);
    }

    return createdObj;
  };

  /**
   * Add group object
   *
   * @param {FabricGroup} obj
   * @param {boolean} [centered=true]
   * @param {boolean} [loaded=false]
   * @returns
   */
  public addGroup = (obj: FabricGroup, centered = true, loaded = false) => {
    return obj.objects.map(child => {
      return this.add(child, centered, loaded);
    });
  };

  /**
   * Add iamge object
   * @param {FabricImage} obj
   * @returns
   */
  public addImage(obj: FabricImage, onLoad?:Function) {
    const { filters = [], ...otherOption } = obj;
    const image = new Image();
    const createdObj = new fabric.Image(image, {
      ...this.objectOption,
      ...otherOption,
    }) as FabricImage;

    if (obj.src) {
      image.src = obj.src;
      image.crossOrigin="anonymous"
    }

    createdObj.set({ filters: this.imageHandler.createFilters(filters), });

    this.setImage(createdObj, obj.src || obj.file).then(()=>{
      if(onLoad)onLoad(createdObj);
    });
    return createdObj;
  };

  public copy_style = (target: FabricObject) => {
    if (!target)return;

    const style = pick(target.toJSON(), copyable_properties[target.type] ?? copyable_properties['*']);

    this.copied_style = {
      type: target.type,
      props: style
    }
    this.setInteractionMode(InteractionModes.PASTE_STYLE)

    this.workareaHandler.setCursor(copyStyleCursor)
    this.canvas.hoverCursor = copyStyleCursor
    this.canvas.defaultCursor = copyStyleCursor
  }

  public copy_actions = (target: FabricObject) => {
    if (!target)return;

    const {event_actions} = target.item

    if(!event_actions)return;

    this.copied_actions = [ ...event_actions ];
    this.setInteractionMode(InteractionModes.PASTE_ACTIONS)

    this.workareaHandler.setCursor(copyStyleCursor)
    this.canvas.hoverCursor = copyStyleCursor
    this.canvas.defaultCursor = copyStyleCursor
  }

  public paste_actions = (target?: FabricObject) => {
    if (!target || !this.copied_actions) return

    if(target.type == 'activeSelection'){
      const activeSelection = target as fabric.ActiveSelection;
      activeSelection.forEachObject((obj: any) => {
        obj.item = {
          ...obj.item,
          event_actions: [...this.copied_actions]
        }
      });
    } else {
      target.item = {
        ...target.item,
        event_actions: [...this.copied_actions]
      };
    };
    this.copied_actions = null;
    this.setInteractionMode(InteractionModes.SELECTION);
  }

  public paste_style = (target?: FabricObject) => {
    if (target && this.copied_style) {
      //if (target.type === this.copied_style.type) {
        const { fill, ...basicProps } = this.copied_style.props
        target.set(basicProps)

        if (fill) {
          if (fill.type) {
            target.set({ fill: new fabric.Gradient(fill) })
          } else {
            target.set({ fill })
          }
        }
      //}
    }
    this.copied_style = null
    this.workareaHandler.setCursor('default')
    this.canvas.hoverCursor = 'move'
    this.canvas.defaultCursor = 'default'
  }

  /**
   * Remove object
   * @param {FabricObject} target
   * @returns {any}
   */
  public remove = (target?: FabricObject) => {
    const activeObject = target || (this.canvas.getActiveObject() as any);
    //if (this.prevTarget && this.prevTarget.superType === 'link') {
    //  this.linkHandler.remove(this.prevTarget as LinkObject);
    //  if (this.transactionHandler.active) {
    //    this.transactionHandler.save('remove');
    //  }
    //  return;
    //}
    if (!activeObject) {
      return;
    }
    if (typeof activeObject.deletable !== 'undefined' && !activeObject.deletable) {
      return;
    }
    if (activeObject.type !== 'activeSelection') {
      this.canvas.discardActiveObject();
      if (activeObject.superType === 'element') {
        this.elementHandler.removeById(activeObject.id);
      }
      //if (activeObject.superType === 'link') {
      //  this.linkHandler.remove(activeObject);
      //} else if (activeObject.superType === 'node') {
      //  if (activeObject.toPort) {
      //    if (activeObject.toPort.links.length) {
      //      activeObject.toPort.links.forEach((link: any) => {
      //        this.linkHandler.remove(link, 'from');
      //      });
      //    }
      //    this.canvas.remove(activeObject.toPort);
      //  }
      //  if (activeObject.fromPort && activeObject.fromPort.length) {
      //    activeObject.fromPort.forEach((port: any) => {
      //      if (port.links.length) {
      //        port.links.forEach((link: any) => {
      //          this.linkHandler.remove(link, 'to');
      //        });
      //      }
      //      this.canvas.remove(port);
      //    });
      //  }
      //}
      this.canvas.remove(activeObject);
    } else {
      // its an ActiveSelection
      const { _objects: activeObjects } = activeObject;
      const existDeleted = activeObjects.some((obj: any) => typeof obj.deletable !== 'undefined' && !obj.deletable,);
      if (existDeleted) {
        return;
      }
      this.canvas.discardActiveObject();

      activeObjects.forEach((obj: any) => {
        if (obj.superType === 'element') {
          this.elementHandler.removeById(obj.id);
        } else if (obj.glitch_type == 'deco') {
          this.gameHandler.removeObjectById(obj.id);
        } else if (obj.glitch_type == 'wall') {
          this.gameHandler.removeObjectById(obj.id);
        } else if (obj.glitch_type == 'platform_line') {
          this.gameHandler.removeObjectById(obj.id);
        } else if (obj.glitch_type == 'ladder') {
          this.gameHandler.removeObjectById(obj.id);
        } /*else if (obj.superType === 'node') {
          if (obj.toPort) {
            if (obj.toPort.links.length) {
              obj.toPort.links.forEach((link: any) => {
                this.linkHandler.remove(link, 'from');
              });
            }
            this.canvas.remove(obj.toPort);
          }
          if (obj.fromPort && obj.fromPort.length) {
            obj.fromPort.forEach((port: any) => {
              if (port.links.length) {
                port.links.forEach((link: any) => {
                  this.linkHandler.remove(link, 'to');
                });
              }
              this.canvas.remove(port);
            });
          }
        }*/
        this.canvas.remove(obj);
      });
    }
    if (this.transactionHandler.active) {
      this.transactionHandler.save('remove');
    }
    this.objects = this.getObjects();
    const { onRemove } = this;
    if (onRemove) {
      onRemove(activeObject);
    }
  };

  /**
   * Remove object by id
   * @param {string} id
   */
  public removeById = (id: string) => {
    const findObject = this.findById(id);
    if (findObject) {
      this.remove(findObject);
    }
  };

  /**
   * Delete at origin object list
   * @param {string} id
   */
  public removeOriginById = (id: string) => {
    const object = this.findOriginByIdWithIndex(id);
    if (object.index > 0) {
      this.objects.splice(object.index, 1);
    }
  };

  /**
   * Duplicate object
   * @returns
   */
  public duplicate() {
    const { onAdd, propertiesToInclude, gridOption: { grid = 10 }, } = this;
    const activeObject = this.canvas.getActiveObject() as FabricObject;

    if (!activeObject)
      return;

    if (typeof activeObject.cloneable !== 'undefined' && !activeObject.cloneable)
      return;

    activeObject.clone((clonedObj: FabricObject) => {
      this.canvas.discardActiveObject();

      clonedObj.set({
        left: clonedObj.left + grid,
        top: clonedObj.top + grid,
        evented: true,
      });

      if (clonedObj.type === 'activeSelection') {
        const activeSelection = clonedObj as fabric.ActiveSelection;
        activeSelection.canvas = this.canvas;
        activeSelection.forEachObject((obj: any) => {
          obj.set('id', v4());
          this.canvas.add(obj);
          this.objects = this.getObjects();
          if (obj.dblclick) {
            obj.on('mousedblclick', this.eventHandler.object.mousedblclick);
          }
        });
        if (onAdd) {
          onAdd(activeSelection);
        }
        activeSelection.setCoords();
      } else {
        if (activeObject.id === clonedObj.id) {
          clonedObj.set('id', v4());
        }
        this.canvas.add(clonedObj);
        this.objects = this.getObjects();
        if (clonedObj.dblclick) {
          clonedObj.on('mouse:dblclick', this.eventHandler.object.mousedblclick as any);
        }
        if (onAdd) {
          onAdd(clonedObj);
        }
      }

      this.canvas.setActiveObject(clonedObj);
      //this.portHandler.create(clonedObj as NodeObject);
      this.canvas.requestRenderAll();
    }, propertiesToInclude);
  };

  /**
   * Duplicate object by id
   * @param {string} id
   * @returns
   */
  public duplicateById(id: string) {
    const { onAdd, propertiesToInclude, gridOption: { grid = 10 }, } = this;

    const findObject = this.findById(id);

    if (findObject) {
      if (typeof findObject.cloneable !== 'undefined' && !findObject.cloneable) {
        return false;
      }
      findObject.clone((cloned: FabricObject) => {
        cloned.set({
          left: cloned.left + grid,
          top: cloned.top + grid,
          id: v4(),
          evented: true,
        });
        this.canvas.add(cloned);
        this.objects = this.getObjects();
        if (onAdd) {
          onAdd(cloned);
        }
        if (cloned.dblclick) {
          cloned.on('mousedblclick', this.eventHandler.object.mousedblclick as any);
        }
        this.canvas.setActiveObject(cloned);
        //this.portHandler.create(cloned as NodeObject);
        this.canvas.requestRenderAll();
      }, propertiesToInclude);
    }
    return true;
  };

  /**
   * Cut object
   *
   */
  public cut() {
    this.copy();
    this.remove();
    this.isCut = true;
  };

  /**
   * Copy to clipboard
   *
   * @param {*} value
   */
  public copyToClipboard(value: any) {
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = value;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.canvas.wrapperEl.focus();
  };

  /**
   * Copy object
   *
   * @returns
   */
  public copy() {
    const { propertiesToInclude } = this;
    const activeObject = this.canvas.getActiveObject() as FabricObject;
    if (activeObject && activeObject.superType === 'link') {
      return false;
    }
    if (!activeObject)
      return false

    if (typeof activeObject.cloneable !== 'undefined' && !activeObject.cloneable) {
      return false;
    }

    activeObject.clone((cloned: FabricObject) => {
      if (this.keyEvent.clipboard) {
        this.copyToClipboard(JSON.stringify(cloned.toObject(propertiesToInclude), null, '\t'));
      } else {
        this.clipboard = cloned;
      }
    }, propertiesToInclude);

    return true;
  };

  /**
   * Paste object
   *
   * @returns
   */
  public paste() {
    const {
      onAdd,
      propertiesToInclude,
      gridOption: { grid = 10 },
      clipboard,
      isCut,
    } = this;
    const padding = isCut ? 0 : grid;
    if (!clipboard) {
      return false;
    }
    if (typeof clipboard.cloneable !== 'undefined' && !clipboard.cloneable) {
      return false;
    }
    this.isCut = false;

    clipboard.clone((clonedObj: any) => {
      this.canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + padding,
        top: clonedObj.top + padding,
        id: isCut ? clipboard.id : v4(),
        evented: true,
      });

      if (clonedObj.type === 'activeSelection') {
        clonedObj.canvas = this.canvas;
        clonedObj.forEachObject((obj: any) => {
          obj.set('id', isCut ? obj.id : v4());
          this.canvas.add(obj);
          if (obj.dblclick) {
            obj.on('mousedblclick', this.eventHandler.object.mousedblclick);
          }
        });
      } else {

        this.canvas.add(clonedObj);

        if (clonedObj.dblclick) {
          clonedObj.on('mousedblclick', this.eventHandler.object.mousedblclick);
        }
      }

      const newClipboard = clipboard.set({
        top: clonedObj.top,
        left: clonedObj.left,
      });

      if (isCut) {
        this.clipboard = null;
      } else {
        this.clipboard = newClipboard;
      }

      if (this.transactionHandler.active) {
        this.transactionHandler.save('paste');
      }
      // TODO...
      // After toGroup svg elements not rendered.
      this.objects = this.getObjects();
      if (onAdd) {
        onAdd(clonedObj);
      }
      clonedObj.setCoords();
      this.canvas.setActiveObject(clonedObj);
      this.canvas.requestRenderAll();
    }, propertiesToInclude);
    return true;
  };

  /**
   * Load the image
   * @param {FabricImage} obj
   * @param {string} src
   */
  public loadImage(obj: FabricImage, src: string) {
    return new Promise((accept,reject) => {
      let url = src || DEFAULT_IMAGE;
      fabric.util.loadImage(url, source => {
        if (obj.type !== 'image') {
          obj.setPatternFill({ source, repeat: 'repeat', }, null);
        }else{
          obj.setElement(source);
        }
        obj.setCoords();
        accept(obj)
      },this, 'anonymous');
    });
  };

  /**
   * Find object by object
   * @param {FabricObject} obj
   */
  public find(obj: FabricObject){
    return this.findById(obj.id)
  }


  /**
   * Find object by id
   * @param {string} id
   * @returns {(FabricObject | null)}
   */
  public findById(id: string): FabricObject | null {
    const found = this.objects.find(obj => obj.id === id);
    if (!found) {
      warning(true, 'Not found object by id.');
      return null;
    }
    return found;
  };

  /**
   * Find object in origin list
   * @param {string} id
   * @returns
   */
  public findOriginById = (id: string) => {
    const found = this.objects.find(obj => obj.id === id);

    if (!found) {
      console.warn('Not found object by id.');
      return null;
    }
    return found;
  };

  /**
   * Return origin object list
   * @param {string} id
   * @returns
   */
  public findOriginByIdWithIndex = (id: string) => {
    let findObject;
    let index = -1;
    const exist = this.objects.some((obj, i) => {
      if (obj.id === id) {
        findObject = obj;
        index = i;
        return true;
      }
      return false;
    });
    if (!exist) {
      console.warn('Not found object by id.');
      return {};
    }
    return {
      object: findObject,
      index,
    };
  };

  /**
   * Select object
   * @param {FabricObject} obj
   * @param {boolean} [find]
   */
  public select = (obj: FabricObject, find?: boolean) => {
    let findObject = obj;
    if (find) {
      findObject = this.find(obj);
    }
    if (findObject) {
      this.canvas.discardActiveObject();
      this.canvas.setActiveObject(findObject);
      this.canvas.requestRenderAll();
    }
  };

  /**
   * Select by id
   * @param {string} id
   */
  public selectById = (id: string) => {
    const findObject = this.findById(id);
    if (findObject) {
      this.canvas.discardActiveObject();
      this.canvas.setActiveObject(findObject);
      this.canvas.requestRenderAll();
    }
  };

  /**
   * Select all
   * @returns
   */
  public selectAll = () => {
    this.canvas.discardActiveObject();
    const filteredObjects = this.canvas.getObjects().filter((obj: any) => {
      if (obj.id === 'workarea') {
        return false;
      } else if (!obj.evented) {
        return false;
      } else if (obj.superType === 'link') {
        return false;
      } else if (obj.superType === 'port') {
        return false;
      } else if (obj.superType === 'element') {
        return false;
      } else if (obj.locked) {
        return false;
      }
      return true;
    });
    if (!filteredObjects.length) {
      return;
    }
    if (filteredObjects.length === 1) {
      this.canvas.setActiveObject(filteredObjects[0]);
      this.canvas.renderAll();
      return;
    }
    const activeSelection = new fabric.ActiveSelection(filteredObjects, {
      canvas: this.canvas,
      ...this.activeSelectionOption,
    });
    this.canvas.setActiveObject(activeSelection);
    this.canvas.renderAll();
  };

  /**
   * Save origin width, height
   * @param {FabricObject} obj
   * @param {number} width
   * @param {number} height
   */
  public originScaleToResize = (obj: FabricObject, width: number, height: number) => {
    if (obj.id === 'workarea') {
      this.setByPartial(obj, {
        workareaWidth: obj.width,
        workareaHeight: obj.height,
      });
    }else{
      this.setByPartial(obj, {
        scaleX: width / obj.width,
        scaleY: height / obj.height,
      });
    }
  };

  /**
   * When set the width, height, Adjust the size
   * @param {number} width
   * @param {number} height
   */
  public scaleToResize = (width: number, height: number) => {
    const activeObject = this.canvas.getActiveObject() as FabricObject;
    const { id } = activeObject;
    const obj = {
      id,
      scaleX: width / activeObject.width,
      scaleY: height / activeObject.height,
    };
    this.setObject(obj);
    activeObject.setCoords();
    this.canvas.requestRenderAll();
  };

  /**
   * Import json
   * @param {*} json
   */
  public async importJSON(json: any) {
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
    this.canvas.setBackgroundColor(this.canvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
    const newWorkarea = json.find((obj: FabricObjectOption) => obj.id === 'workarea');

    if (!this.workarea) {
      this.workareaHandler.initialize();
    }

    let { left:prevLeft, top:prevTop} = newWorkarea || this.workarea;

    if (!newWorkarea) {
      this.canvas.centerObject(this.workarea);
    } else {
      this.workarea.set(newWorkarea);
      await this.workareaHandler.setImage(newWorkarea.src, true);
    }
    this.workarea.setCoords();

    const added=[];

    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    for(let i=0;i<json.length;i++){
      let obj:FabricObjectOption = cloneDeep(json[i]);
      if (obj.id === 'workarea')
        continue;

      if (this.workarea.layout === 'fullscreen') {
        const leftRatio = canvasWidth / (this.workarea.width * this.workarea.scaleX);
        const topRatio = canvasHeight / (this.workarea.height * this.workarea.scaleY);
        obj.left *= leftRatio;
        obj.top *= topRatio;
        obj.scaleX *= leftRatio;
        obj.scaleY *= topRatio;
      } else {
        const diffLeft = this.workarea.left - prevLeft;
        const diffTop = this.workarea.top - prevTop;
        obj.left += diffLeft;
        obj.top += diffTop;
      }
      if (obj.superType === 'element') {
        obj.id = v4();
      }
      added.push(await this.add(obj, false, true, 0));
    }
    this.frameHandler?.recalcPosition();
    this.canvas.renderAll();
    return added;
  };

  /**
   * Export json
   */
  public exportJSON = () => this.canvas.toObject(this.propertiesToInclude).objects as FabricObject[];

  /**
   * Active selection to group
   * @returns
   */
  public toGroup = (target?: FabricObject) => {
    const activeObject = target || (this.canvas.getActiveObject() as fabric.ActiveSelection);
    if (!activeObject) {
      return null;
    }
    if (activeObject.type !== 'activeSelection') {
      return null;
    }
    const group = activeObject.toGroup() as FabricObject<fabric.Group>;
    group.set({
      id: v4(),
      name: 'New group',
      type: 'group',
      ...this.objectOption,
    });
    this.objects = this.getObjects();
    if (this.transactionHandler.active) {
      this.transactionHandler.save('group');
    }
    if (this.onSelect) {
      this.onSelect(group);
    }
    this.canvas.renderAll();
    return group;
  };

  /**
   * Group to active selection
   * @returns
   */
  public toActiveSelection = (target?: FabricObject) => {
    const activeObject = target || (this.canvas.getActiveObject() as fabric.Group);
    if (!activeObject) {
      return;
    }
    if (activeObject.type !== 'group') {
      return;
    }
    const activeSelection = activeObject.toActiveSelection();
    this.objects = this.getObjects();
    if (this.transactionHandler.active) {
      this.transactionHandler.save('ungroup');
    }
    if (this.onSelect) {
      this.onSelect(activeSelection);
    }
    this.canvas.renderAll();
    return activeSelection;
  };

  public restackItems() {
    sortBy(this.getObjects(),(o:any) => o.hasOwnProperty(Z_IDX) ? o.z_idx : 60000).forEach((o,i) => {
      o.z_idx = Math.floor(o.z_idx / 1000)*1000 + i;
      this.canvas.moveTo(o, i);
    })
    this.workarea.moveTo(0);
  }


  /**
   * Bring forward, but always within the objects in the same layer
   */
  public bringForward = () => {

    const activeObject = this.canvas.getActiveObject() as FabricObject;

    if (!activeObject)
      return;

    activeObject.z_idx += 1;

    this.restackItems();

    if (this.transactionHandler.active) {
      this.transactionHandler.save('bringForward');
    }

    this.onModified?.(activeObject);
  };

  /**
   * Bring to front, within the current layer
   */
  public bringToFront = () => {
    const activeObject = this.canvas.getActiveObject() as FabricObject;
    if (!activeObject) 
      return;

    activeObject.z_idx = Math.floor(activeObject.z_idx/1000)*1000+999;
    this.restackItems();

    if (this.transactionHandler.active) {
      this.transactionHandler.save('bringToFront');
    }

    this.onModified?.(activeObject);
  };

  /**
   * Send backwards within the current layer
   * @returns
   */
  public sendBackwards = () => {
    const activeObject = this.canvas.getActiveObject() as FabricObject;
    if (!activeObject)return;

    activeObject.z_idx = ((activeObject.z_idx - 1 + 1000) % 1000) + Math.floor(activeObject.z_idx / 1000)*1000

    if (this.transactionHandler.active) {
      this.transactionHandler.save('sendBackwards');
    }

    this.onModified?.(activeObject);
  };

  /**
   * Send to back
   */
  public sendToBack = () => {

    const activeObject = this.canvas.getActiveObject() as FabricObject;

    if (!activeObject)return

    activeObject.z_idx = Math.floor(activeObject.z_idx / 1000)*1000;

    this.restackItems();

    if (this.transactionHandler.active)
      this.transactionHandler.save('sendToBack');

    this.onModified?.(activeObject);
  };

  /**
   * Clear canvas
   * @param {boolean} [includeWorkarea=false]
   */
  public clear = (includeWorkarea = false) => {
    const ids = this.canvas.getObjects().reduce((prev, curr: any) => {
      if (curr.superType === 'element') {
        prev.push(curr.id);
        return prev;
      }
      return prev;
    }, []);

    this.elementHandler.removeByIds(ids);

    if (includeWorkarea) {
      this.canvas.clear();
      this.workarea = null;
    } else {
      this.canvas.discardActiveObject();
      this.canvas.getObjects().forEach((obj: any) => {
        if (obj.id === 'grid' || obj.id === 'workarea') {
          return;
        }
        this.canvas.remove(obj);
      });
    }
    this.objects = this.getObjects();
    this.canvas.renderAll();
  };

  /**
   * Start request animation frame
   */
  public startRequestAnimFrame = () => {
    if (!this.isRequsetAnimFrame) {
      this.isRequsetAnimFrame = true;
      const render = () => {
        this.canvas.renderAll();
        this.requestFrame = fabric.util.requestAnimFrame(render);
      };
      fabric.util.requestAnimFrame(render);
    }
  };

  /**
   * Stop request animation frame
   */
  public stopRequestAnimFrame = () => {
    this.isRequsetAnimFrame = false;
    const cancelRequestAnimFrame = (() =>
      window.cancelAnimationFrame ||
      // || window.webkitCancelRequestAnimationFrame
      // || window.mozCancelRequestAnimationFrame
      // || window.oCancelRequestAnimationFrame
      // || window.msCancelRequestAnimationFrame
      clearTimeout)();
    cancelRequestAnimFrame(this.requestFrame);
  };

  /**
   * Save target object as image
   * @param {FabricObject} targetObject
   * @param {string} [option={ name: 'New Image', format: 'png', quality: 1 }]
   */
  public saveImage = (targetObject?:FabricObject, option = { name: 'New Image', format: 'png', quality: 1 }) => {
    let dataUrl;
    let target = targetObject;
    if (target) {
      dataUrl = target.toDataURL(option);
    } else {
      target = this.canvas.getActiveObject() as FabricObject;
      if (target) {
        dataUrl = target.toDataURL(option);
      }
    }
    if (dataUrl) {
      const anchorEl = document.createElement('a');
      anchorEl.href = dataUrl;
      anchorEl.download = `${option.name}.png`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    }
  };

  /**
   * Save canvas as image
   * @param {string} [option={ name: 'New Image', format: 'png', quality: 1 }]
   */
  public saveCanvasImage = (option = { name: 'New Image', format: 'png', quality: 1 }) => {
    const {left,top,width,height}=this.workarea;
    const dataUrl = this.canvas.toDataURL({left,top,width,height, ...option});
    if (dataUrl) {
      const anchorEl = document.createElement('a');
      anchorEl.href = dataUrl;
      anchorEl.download = `${option.name}.png`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    }
  };

  /**
   * Sets "angle" of an instance with centered rotation
   *
   * @param {number} angle
   */
  public rotate = (angle: number) => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.set('rotation', angle);
      activeObject.rotate(angle);
      this.canvas.requestRenderAll();
    }
  };

  public get allHandlers() {
    return  [this.templateHandler
      , this.imageHandler
      /*, this.chartHandler*/
      , this.elementHandler
      , this.cropHandler
      , this.animationHandler
      , this.contextmenuHandler
      , this.tooltipHandler
      , this.zoomHandler
      , this.workareaHandler
      , this.interactionHandler
      , this.frameHandler
      , this.transactionHandler
      , this.gridHandler
      , this.alignmentHandler
      , this.guidelineHandler
      , this.eventHandler
      , this.drawingHandler
      , this.shortcutHandler
      , this.gameHandler].filter(Boolean)
  }

  /**
   * Destroy canvas
   */
  public destroy = () => {
    this.allHandlers.forEach(handler => handler.destroy());
    try{
      this.clear(true);
    }catch(e){
      console.log(e);
    }
  };

  /**
   * Set canvas option
   *
   * @param {CanvasOption} canvasOption
   */
  public setCanvasOption = (canvasOption: CanvasOption) => {
    this.canvasOption = Object.assign({}, this.canvasOption, canvasOption);
    this.canvas.setBackgroundColor(canvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
    if (typeof canvasOption.width !== 'undefined' && typeof canvasOption.height !== 'undefined') {
      if (this.eventHandler) {
        this.eventHandler.resize(canvasOption.width, canvasOption.height);
      } else {
        this.canvas.setWidth(canvasOption.width).setHeight(canvasOption.height);
      }
    }
    if (typeof canvasOption.selection !== 'undefined') {
      this.canvas.selection = canvasOption.selection;
    }
    if (typeof canvasOption.hoverCursor !== 'undefined') {
      this.canvas.hoverCursor = canvasOption.hoverCursor;
    }
    if (typeof canvasOption.defaultCursor !== 'undefined') {
      this.canvas.defaultCursor = canvasOption.defaultCursor;
    }
    if (typeof canvasOption.preserveObjectStacking !== 'undefined') {
      this.canvas.preserveObjectStacking = canvasOption.preserveObjectStacking;
    }
  };

  /**
   * Set keyboard event
   *
   * @param {KeyEvent} keyEvent
   */
  public setKeyEvent = (keyEvent: KeyEvent) => {
    this.keyEvent = Object.assign({}, this.keyEvent, keyEvent);
  };

  /**
   * Set fabric objects
   *
   * @param {FabricObjects} fabricObjects
   */
  public setFabricObjects = (fabricObjects: FabricObjects) => {
    this.fabricObjects = Object.assign({}, this.fabricObjects, fabricObjects);
  };

  /**
   * Set workarea option
   *
   * @param {WorkareaOption} workareaOption
   */
  public setWorkareaOption = async (workareaOption: WorkareaOption) => {
    this.workareaOption = Object.assign({}, this.workareaOption, workareaOption);

    if (!this.workarea)
      return;

    if(workareaOption?.src){
      const img = await LoadManager.instance.loadImage(workareaOption.src, 'anonymous');
      this.workarea.setElement(img);
      this.workarea._setWidthHeight();
      this.canvas.requestRenderAll()
    }

    this.workarea.set({ ...workareaOption, });
    this.frameHandler?.recalcPosition();
  };

  /**
   * Set guideline option
   *
   * @param {GuidelineOption} guidelineOption
   */
  public setGuidelineOption = (guidelineOption: GuidelineOption) => {
    this.guidelineOption = Object.assign({}, this.guidelineOption, guidelineOption);
    if (this.guidelineHandler) {
      this.guidelineHandler.initialize();
    }
  };

  /**
   * Set grid option
   *
   * @param {GridOption} gridOption
   */
  public setGridOption = (gridOption: GridOption) => {
    this.gridOption = Object.assign({}, this.gridOption, gridOption);
  };

  /**
   * Set object option
   *
   * @param {FabricObjectOption} objectOption
   */
  public setObjectOption = (objectOption: FabricObjectOption) => {
    this.objectOption = Object.assign({}, this.objectOption, objectOption);
  };

  /**
   * Set activeSelection option
   *
   * @param {Partial<FabricObjectOption<fabric.ActiveSelection>>} activeSelectionOption
   */
  public setActiveSelectionOption = (activeSelectionOption: Partial<FabricObjectOption<fabric.ActiveSelection>>) => {
    this.activeSelectionOption = Object.assign({}, this.activeSelectionOption, activeSelectionOption);
  };

  /**
   * Set propertiesToInclude
   *
   * @param {string[]} propertiesToInclude
   */
  public setPropertiesToInclude = (propertiesToInclude: string[]) => {
    this.propertiesToInclude = union(propertiesToInclude, this.propertiesToInclude);
  };
}

export default CanvasController;

const copyStyleVector = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 3.5H5a.5.5 0 0 0-.5.5v1.5A.5.5 0 0 0 5 6h11a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5zM5 2a2 2 0 0 0-2 2v1.5a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-.25h.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-5.75a2.25 2.25 0 0 0-2.25 2.25v1.563A2 2 0 0 0 9 15v5a2 2 0 0 0 2 2h.5a2 2 0 0 0 2-2v-5a2 2 0 0 0-1.5-1.937V11.5a.75.75 0 0 1 .75-.75h5.75a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25h-.515A2 2 0 0 0 16 2H5zm7 13a.5.5 0 0 0-.5-.5H11a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-5z" fill="currentColor"></path></svg>`;
const copyStyleCursor = `url(data:image/svg+xml;base64,${btoa(copyStyleVector)}), crosshair`;
