import {List, ListItemText, ListItem, ListItemIcon} from "@material-ui/core";
import {GlitchLayer} from "components/glitch/space";
import {fabric} from 'fabric';
import {useDesignContext} from "contexts/design_context";
import {sortBy} from "lodash";
import React, {useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import {serializeItems} from "components/glitch/utils";
import {setGameData} from "redux/reducers/editor";

const LAYER_STATES = ['visible', 'hidden'];

const LayersPanel = (_props:any) => {

  const {fabricCanvas, designController} = useDesignContext();
  const { layerId, spaceId, } = useSelector((state:any) => state.editor);
  const gameData = useSelector((state:any) => state.editor.gameData);

  const layers:GlitchLayer[] = Object.keys(gameData?.dynamic?.layers || {}).map(k=>({...gameData.dynamic.layers[k], id: k}));
  const sortedLayers:GlitchLayer[] = sortBy(layers, l=> l.z);

  const [hidden, setHidden] = React.useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const allVisible = Object.keys(gameData?.dynamic?.layers || {}).reduce((acc, k) => ({...acc, [k]: 'visible'}), {});
    setHidden(allVisible);
  }, [gameData?.dynamic?.layers]);

  const getIcon = (id:string) => {
    switch(hidden[id]){
      case 'hidden':
      return 'fa-eye-slash';
      case 'visible':
      return 'fa-eye';
      case 'only':
      return 'fa-arrows-to-eye';
    }
  }

  const updateHidden = (id:string) => {
    const nextState = LAYER_STATES[(LAYER_STATES.indexOf(hidden[id]) + 1) % LAYER_STATES.length];
      
    const allVisible = Object.keys(gameData?.dynamic?.layers || {}).reduce((acc, k) => ({...acc, [k]: 'visible'}), {});

    const hid= nextState == 'only' ? {...allVisible} : {...hidden};
    hid[id]= nextState;

    fabricCanvas.getObjects().forEach((obj:any) => {
      if(!obj.layer_id)
        return

      switch(hid[obj.layer_id]){
        case 'hidden':
        obj.set({opacity: 0, evented: false});break;
        case 'visible':
        obj.set({opacity: 1, evented: true});break;
        case 'only':
        obj.set({opacity: 1, evented: true});break;
      }
    });
    fabricCanvas.requestRenderAll();
    setHidden(hid);
  }

  const resetLayers=(): void => {
    const newData = serializeItems(gameData, fabricCanvas);
    designController.clear();

    //setContextGameData(newData);
    dispatch(setGameData(newData));
  }

  const selectAllItemsOnLayer = (id: string): void => {
    const selectedObjects = [];

    fabricCanvas.getObjects().forEach((obj:any) => {
      if(obj.layer_id == id){
        selectedObjects.push(obj);
      }
    });

    // Create an Active Selection
    const activeSelection = new fabric.ActiveSelection(selectedObjects, {
      canvas: fabricCanvas,
      //preserveObjectStacking: true
    });

    fabricCanvas.setActiveObject(activeSelection);

    fabricCanvas.requestRenderAll();
  }

  const deleteLayer = (id: string): void => {
    const newData = {...gameData};
    delete newData.dynamic.layers[id];
    setGameData(newData);
  }

  const drawLayers = (): void => {
    const middlegroundHeight = gameData.dynamic.layers.middleground.h;
    const middlegroundWidth = gameData.dynamic.layers.middleground.w;
    const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'orange', 'pink', 'brown', 'cyan', 'magenta', 'lime', 'olive', 'maroon', 'navy', 'teal'];
    sortedLayers.forEach((layer, idx) => {
      const heightDiff = layer.h - middlegroundHeight;
      const offsets:[number,number] = layer.id == 'middleground' ? [0,0] : [0,-heightDiff];
      const diff = middlegroundWidth - layer.w;
      const parallax = diff / middlegroundWidth;
      const limitBox = new fabric.Rect({
        width: layer.w,
        height: layer.h,
        left: offsets[0],
        top: offsets[1],
        fill: 'rgba(0,0,0,0)',
        stroke: colors[idx % colors.length],
        strokeWidth: 1,
      });
      limitBox.set({ parallax, offset_x: offsets[0], offset_y: offsets[1], item: {x: 0, y: 0, w: layer.w, h: layer.h}, layer_id: layer.id} as any);
      fabricCanvas.add(limitBox);
      fabricCanvas.requestRenderAll()
    })
  }

  return <List>
    {sortedLayers.map((layer:GlitchLayer) => {
      return <ListItem key={layer.id}>
        <ListItemIcon onClick={(e) => updateHidden(layer.id)}>
          <i className={`fa ${getIcon(layer.id)}`}/>
        </ListItemIcon>
        <ListItemIcon onClick={(e) => deleteLayer(layer.id)}>
          <i className={`fa fa-trash`}/>
        </ListItemIcon>

        <ListItemText onClick={(e) => selectAllItemsOnLayer(layer.id)}
          primaryTypographyProps={{style: {color: layerId === layer.id ? 'blue' : 'black', cursor: 'pointer'}}}>
          {layer.name} 
          <span style={{color: 'gray', fontSize: '0.8em'}}>{` (${layer.w}x${layer.h})`}</span>
        </ListItemText>
      </ListItem>
    })}

    <ListItemText onClick={(e) => drawLayers()}>
      Draw layers
    </ListItemText>

</List>
}

export default LayersPanel;
