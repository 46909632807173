import {addItem} from "components/canvas/DropHandler";
import {useDesignContext} from "contexts/design_context";
import React, {CSSProperties, useEffect, useState} from "react";
import {useDrag} from "react-dnd";
import { InstantSearch, SearchBox, Hits, RefinementList, InfiniteHits, Configure } from "react-instantsearch";
import _ from "underscore";
import {searchAssets} from "utils/searchkit_client";

export const loadSearchCss = (href) => () => {
  // Create link element
  const link = document.createElement('link');
  link.href = href;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.media = 'screen,print';

  // Append link element to head
  document.head.appendChild(link);

  // Remove link element on cleanup
  return () => {
    document.head.removeChild(link);
  };
}

const ElementsPanel = ({onSelectElement,...props}) => {

  const {designController} = useDesignContext();

  const href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"
  useEffect(loadSearchCss(href), []);

  const HitView = ({ hit }) => {

    const svgStyle:React.CSSProperties = {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
      display: 'inline-block',
      marginRight: '10px',
      border: '1px solid black',
      borderRadius: '5px',
    };

    const url =`/glitch/assets/${hit.name}.svg`;
    const obj = {
      ...hit,
      type: 'deco',
      option: {
        maxWidth: 256
      }
    };

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
      type: 'sprite',
      item: obj,
      end: (_item, _monitor) => {
        //const dropResult = monitor.getDropResult<{name:string}>()
        //const coords = monitor.getClientOffset();
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }))

    const style:CSSProperties = {
      opacity: isDragging ? 0.5 : 1,
      cursor: 'move',
      //position: isDragging ? 'fixed' : 'static'
    }

    return <div ref={drag} style={style} draggable={onSelectElement ? false : true}>
      <img src={url} style={svgStyle} alt={hit.name} onClick={() => {
        addItem(obj, true, designController);
        if(onSelectElement)
          onSelectElement(obj)
      }}></img>

      <p>{hit.name_tokens}</p>
    </div>
    };

  //const index = (window as any).assets_index;
  const index = `glitch_assets_${(window as any).rails_env}`;

  return <InstantSearch indexName={index} searchClient={searchAssets} future={{preserveSharedStateOnUnmount: true}}>
    <Configure hitsPerPage={28} />

    <SearchBox />

    <RefinementList attribute="group_tokens" classNames={{item: 'd-inline-block border border-gray m-1 p-1 rounded-2', checkbox: 'd-none', selectedItem: 'bg-primary text-white'}} limit={20} showMore={true} showMoreLimit={50} />

    <InfiniteHits hitComponent={HitView} id="hits-elements"  classNames={{list: 'row', item: 'col-12 col-sm-6 col-md-3'}} />
  </InstantSearch>
};

 
export default ElementsPanel;

