import { GlitchDeco, GlitchLayer, GlitchGame, GlitchGradient, GlitchFilters, GlitchPlatformLine, GlitchWall, GlitchLadder, PromptGenerator, GlitchSignpost } from "./space";
import {sortBy} from 'lodash';
import {fabric} from 'fabric';
import { LayerAttributes, SpaceAttributes } from "models/space";
import {FabricObject} from "components/canvas/utils/ObjectUtil";
import { v4 } from 'uuid';
import LoadManager from "utils/load_manager";

// TODO this will need change when using from the server`
const baseUrl = `https://${window.location.hostname}`;

export const removeImportedItems = (gameData:GlitchGame) => {
  const newGameData = {
    ...gameData,
    dynamic: {
      ...gameData.dynamic,
      layers: Object.entries(gameData.dynamic.layers).reduce((acc, [key, value]) => {
        acc[key] = {
          ...value,
          decos: value.decos.filter((i) => !i.imported_from_space_id),
          ladders: value.ladders.filter((i) => !i.imported_from_space_id),
          platformLines: value.platformLines.filter((i) => !i.imported_from_space_id),
          walls: value.walls.filter((i) => !i.imported_from_space_id),
        }
        return acc;
      }, {})
    }
  }
  return newGameData;
}

export const removeOffscreenItems = (gameData:GlitchGame):GlitchGame => {

  const middlegroundHeight = gameData.dynamic.layers.middleground.h;

  return {
    ...gameData,
    dynamic: {
      ...gameData.dynamic,
      layers: Object.entries(gameData.dynamic.layers).reduce((acc, [id,layer]) => {
        const heightDiff = layer.h - middlegroundHeight;
        const offsets:[number,number] = id == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];
        const limitBox = new fabric.Rect({
          width: layer.w,
          height: layer.h,
          left: id == 'middleground' ? 0 : 0, 
          top: id == 'middleground' ? 0 : -heightDiff,
        });

        acc[id] = {
          ...layer,
          decos: layer.decos.filter((deco) => {
            const decoBox = new fabric.Rect({
              originX: 'center',
              originY: 'bottom',
              left: deco.x + offsets[0],
              top:  deco.y + offsets[1],
              width: deco.w,
              height: deco.h,
              angle: deco.r,
            });

            return limitBox.intersectsWithObject(decoBox)
          }),
          ladders: layer.ladders.filter((ladder) => {
            const ladderBox = new fabric.Rect({
              originX: 'center',
              originY: 'bottom',
              left: ladder.x + offsets[0],
              top:  ladder.y + offsets[1],
              width: ladder.w,
              height: ladder.h,
            });
            return limitBox.intersectsWithObject(ladderBox)
          }),
          platformLines: layer.platformLines.filter((pf) => {
            const [start, end] = [...pf.endpoints].sort((a,b) => a.name == 'start' ? -1 : 1);
            const l=new fabric.Line([offsets[0]+start.x, offsets[1]+start.y, offsets[0]+end.x, offsets[1]+end.y])
            return limitBox.intersectsWithObject(l);
          }), 
          walls: layer.walls.filter((wall) => {
            const {x,y,w,h, id} = wall;
            const l= new fabric.Line([offsets[0]+x, offsets[1]+y, offsets[0]+x+w, offsets[1]+y+h])
            return limitBox.intersectsWithObject(l)
          })
        }
        return acc;
      }, {})
    }
  }
}

export const removeBackgroundLayers = (gameData:GlitchGame, zIndexThreshold):GlitchGame => {

  return {
    ...gameData,
    dynamic: {
      ...gameData.dynamic,
      layers: Object.entries(gameData.dynamic.layers).reduce((acc, [id,layer]) => {
        if(layer.z < zIndexThreshold) 
          return acc;

        return {
          ...acc,
          [id]: layer
        }
      }, {})
    }
  }
}


export const mergeGameIntoGame = (gameData:GlitchGame, newGameData:GlitchGame, extra_attrs={}):GlitchGame => {

  return mergeLayersIntoGame(gameData, Object.entries(newGameData.dynamic.layers).reduce((acc,[k,v]) => ({
    ...acc,
    [k]: {
      ...v,
      decos: v.decos.map((deco) => ({...deco, ...extra_attrs, id: deco.id || v4()})),
      ladders: v.ladders.map((ladder) => ({ ...ladder, ...extra_attrs, id: ladder.id || v4() })),
      platformLines: v.platformLines.map((platformLine) => ({ ...platformLine, ...extra_attrs, id: platformLine.id || v4() })),
      walls: v.walls.map((wall) => ({ ...wall, ...extra_attrs, id: wall.id || v4()})),
    }
  }) , {}));
}

export const mergeLayersIntoGame = (gameData:GlitchGame, layers:{[id:string]: GlitchLayer}):GlitchGame => {
  const newLayers = Object.entries(gameData.dynamic.layers).concat(Object.entries(layers)).reduce((acc, [key, value]) => {
    let layerWidth, layerHeight;
    if(gameData.dynamic.layers[key]) {
      layerWidth = gameData.dynamic.layers[key].w;
      layerHeight = gameData.dynamic.layers[key].h;
    } else {
      layerWidth = Math.ceil(gameData.dynamic.layers.middleground.w * (value.w / layers.middleground.w))
      layerHeight = Math.ceil(gameData.dynamic.layers.middleground.h * (value.h / layers.middleground.h))
    }
    acc[key] = {
      ...layers[key],
      ...gameData.dynamic.layers[key],
      w: layerWidth,
      h: layerHeight,
      decos: [...(gameData.dynamic.layers[key]?.decos || []), ...value.decos],
      ladders: [...(gameData.dynamic.layers[key]?.ladders || []), ...value.ladders],
      platformLines: [...(gameData.dynamic.layers[key]?.platformLines || []), ...value.platformLines],
      walls: [...(gameData.dynamic.layers[key]?.walls || []), ...value.walls],
    }
    return acc;
  }, {});

  const newerGameData = {
    ...gameData,
    dynamic: {
      ...gameData.dynamic,
      layers: newLayers
    }
  }
  return newerGameData
}

export const Z_IDX = 'z_idx';
export const OFFSET_X = 'offset_x';
export const OFFSET_Y = 'offset_y';
export const LAYER_ID = 'layer_id';
export const GLITCH_TYPE = 'glitch_type';

export const CUSTOM_PROPERTIES = [
  GLITCH_TYPE,
  "parallax",
  "layer_name",
  LAYER_ID,
  Z_IDX,
  OFFSET_X,
  OFFSET_Y,
  "item",
]

export type CreateGameParams = {
  label:string,
  bgUrl:string,
  width:number,
  height:number,
  generator?:PromptGenerator,
}

export const makeGlitchLayer:(opts:{id:string, name:string, w:number,h:number, z:number}) => {[id:string]:GlitchLayer} = (opts) => {
  const {id, name, w, h, z} = opts;
  const gl:GlitchLayer= {
    id,
    name,
    w,
    h,
    z,
    filters: {},
    decos: [],
    signposts: [],
    platformLines: [],
    ladders: [],
    walls: [],
    items: [],
  }

  return {[id]:gl};

}

export const createGlitchGame:(params:CreateGameParams) => GlitchGame = (params) => {

  const {width,height,bgUrl, label, generator}=params;

  const gg:GlitchGame = {
    tsid: v4(),
    label, 
    hub_id: null,
    mote_id: null,
    loading_image: null,
    main_image: null,
    background_image: {
      url: bgUrl,
      w: width,
      h: height,
      generator,
    },

    gradient: null,
    dynamic: {
      l: -params.width/2,
      r: params.width/2,
      t: -params.height,
      b: 0,
      rookable_type: 0, 
      ground_y: 0, 
      layers: {
        ...makeGlitchLayer({
          id: `bg_${v4()}`,
          name: 'background',
          w: params.width*0.9,
          h: params.height*0.98,
          z: -3, 
        }),
        ...makeGlitchLayer({
          id: 'middleground', 
          name: 'middleground',
          w: params.width, 
          h: params.height, 
          z: 0,
        }),
        ...makeGlitchLayer({
          id: `fg_${v4()}`, 
          name: 'foreground',
          w: params.width*1.1,
          h: params.height*1.02,
          z: 1, 
        })
      },
    }, 
    objrefs: []
  };
  return gg;
}


export const createSpaceFromLocation:(layers:LayerAttributes[], space:Partial<SpaceAttributes> )=>SpaceAttributes = (layers, space) => {

  const middleground = layers.find((l) => l.id == 'middleground');

  if(!middleground)
    throw "No middleground"

  space = space || {
    preview_url: null,
    start_point: {x:100,y: middleground.total_height - 100},
    uuid: 'asdf',
    name: 'Hola',
    description: 'asereje',
    metadata:{},
    canEdit: true,
    owner: 'asdf',
    npc_placements: [],
  };

  return {
    ...space,
    layers: layers,
    tiles: [{name: '',
      x: 0, 
      y: 0, 
      events: {},
      space_id: space.uuid,
      id: 'asdf',
      public_filename: middleground.image_url,
    }],
    width: 1,
    height: 1,
    total_width: middleground.total_width,
    total_height: middleground.total_height,
    tile_width: middleground.total_width,
    tile_height: middleground.total_height,
  } as SpaceAttributes
}

function getAbsoluteCoordinates(line:fabric.Line):{start:fabric.Point, end:fabric.Point} {
  const {strokeWidth} = line;
  line.strokeWidth = 0;
  const points = line.calcLinePoints();
  const matrix = line.calcTransformMatrix();
  line.set({strokeWidth});

  const start = fabric.util.transformPoint(new fabric.Point(points.x1, points.y1 ), matrix);
  const end = fabric.util.transformPoint(new fabric.Point(points.x2, points.y2 ), matrix);

  return {start,end}
}

type SerializableItem = GlitchSignpost | GlitchDeco;

const serializeImageItem = <T extends SerializableItem>(
  obj: fabric.Image & { item: T },
  [offset_x, offset_y]: [number, number],
  z: number
): T => {

  const x = {
    ...obj.item,
    filename: obj.item.filename || obj.item.id || v4(),
    z,
    r: fabric.util.radiansToDegrees(obj.angle),
  } as T;
  // set rotate to zero to capture the correct width and height
  obj.rotate(0);

  x.w = obj.getScaledWidth();
  x.h = obj.getScaledHeight();

  x.x = obj.left - offset_x;
  x.y = obj.top - offset_y;
  x.h_flip = obj.flipX;
  x.v_flip = obj.flipY;

  // set the correct rotation again
  obj.rotate(fabric.util.degreesToRadians(x.r));
  return x;
};

const serializeSignpost = (
  obj: fabric.Image & { item: GlitchSignpost },
  offsets: [number, number],
  z: number
): GlitchSignpost => serializeImageItem<GlitchSignpost>(obj, offsets, z);

const serializeDeco = (
  obj: fabric.Image & { item: GlitchDeco },
  offsets: [number, number],
  z: number
): GlitchDeco => serializeImageItem<GlitchDeco>(obj, offsets, z);


const serializePlatformLine = (obj:FabricObject<fabric.Line> & {item: GlitchPlatformLine}, [offset_x,offset_y]):GlitchPlatformLine => {
  const {start,end} = getAbsoluteCoordinates(obj);

  const line:GlitchPlatformLine = {
    ...obj.item,
    id: obj.id,
    endpoints: [
      {name: 'end', x: start.x - offset_x, y: start.y - offset_y},
      {name: 'start', x: end.x - offset_x, y: end.y - offset_y}
    ]
  };
  return line;
}

// Helper function to get dimensions and coordinates for walls and ladders
const getObjectDimensions = (obj: FabricObject<fabric.Image | fabric.Rect>) => {
  obj.strokeWidth = 0;
  obj.setCoords();
  const w = obj.aCoords.br.x - obj.aCoords.bl.x;
  const h = obj.aCoords.br.y - obj.aCoords.tr.y;
  return { w, h };
};

// Generic serializer for walls and ladders
const serializeRectangularObject = <T extends GlitchWall | GlitchLadder>(obj: FabricObject<fabric.Image | fabric.Rect> & { item: T }, [offset_x, offset_y]: [number, number], strokeWidth: number): T => {
  const { w, h } = getObjectDimensions(obj);

  const serialized = {
    ...obj.item,
    id: obj.id,
    x: obj.aCoords.br.x - w/2 - offset_x,
    y: obj.aCoords.br.y - offset_y,
    w,
    h,
  } as T;

  obj.strokeWidth = strokeWidth;
  return serialized;
};

const serializeWall = ( obj: FabricObject<fabric.Image> & { item: GlitchWall }, offsets: [number, number]): GlitchWall => {
  return serializeRectangularObject(obj, offsets, linesStyles.walls.strokeWidth);
};

const serializeLadder = ( obj: FabricObject<fabric.Rect> & { item: GlitchLadder }, offsets: [number, number]): GlitchLadder => {
  return serializeRectangularObject(obj, offsets, linesStyles.ladders.strokeWidth);
};

export const serializeItems = (gameData:GlitchGame, canvas:fabric.Canvas):GlitchGame => {

  const middlegroundHeight = gameData.dynamic.layers.middleground.h;

  const retval:GlitchGame = {
    ...gameData, 
    dynamic: {
      ...gameData.dynamic,
      layers: Object.entries(gameData.dynamic.layers).reduce((acc,[id,layer]) => {
        const heightDiff = layer.h - middlegroundHeight;
        const offsets:[number,number] = id == 'middleground' ? [layer.w/2, layer.h] : [0,-heightDiff];

        const layer_objects = canvas.getObjects().filter((o:any) => o.layer_id == id)

        acc[id] = {
          ...layer,
          decos: layer_objects.filter((o:any) => o.glitch_type == 'deco').map((y:any, i:number)=> serializeDeco(y,offsets,i)),
          walls: layer_objects.filter((o:any) => o.glitch_type == 'wall').map((y:any, i:number)=> serializeWall(y,offsets)),
          platformLines: layer_objects.filter((o:any) => o.glitch_type == 'platform_line').map((y:any, i:number)=> serializePlatformLine(y,offsets)),
          ladders: layer_objects.filter((o:any) => o.glitch_type == 'ladder').map((y:any, i:number)=> serializeLadder(y,offsets)),
          signposts: layer_objects.filter((o:any) => o.glitch_type == 'signpost').map((y:any, i:number)=> serializeSignpost(y,offsets,i)),
        };

        // layer_objects.filter((o:any) => o.type == 'polygon').forEach((g:fabric.Group) => {
        //   acc[id].decos.push(...g.getObjects().filter((o:any) => o.glitch_type == 'deco').map((y:any, i)=>serializeDeco(y,offsets, i)));
        //   acc[id].walls.push(...g.getObjects().filter((o:any) => o.glitch_type == 'wall').map((y:any)=>serializeWall(y,offsets)));
        //   acc[id].platformLines.push(...g.getObjects().filter((o:any) => o.glitch_type == 'platform_line').map((y:any)=>serializePlatformLine(y,offsets)));
        //   acc[id].ladders.push(...g.getObjects().filter((o:any) => o.glitch_type == 'ladder').map((y:any)=>serializeLadder(y,offsets)));
        // })

        return acc;
      }, {})
    }
  };


  return retval;
}


export const parseGlitchLocation:(game:GlitchGame, layer_images_map?:{[name:string]:string})=>Promise<LayerAttributes[]> = (game, layer_images_map) => {
  return Promise.all(Object.keys(game.dynamic.layers).map((id) => createCompatibleLayer(id, game.dynamic.layers[id], layer_images_map?.[id])))
}

export const parseGradient = (gradient:string) => {
  if (parseInt(gradient) >> 16 === 0 && parseInt(gradient) >> 8 === 0 &&
    (gradient.length == 6 || gradient.length == 4 || gradient.length == 1)) {

    let topGradient = gradient;
    while (topGradient.length < 6) {
      topGradient = '0' + topGradient;
    }
    topGradient = '#' + topGradient;
    return topGradient;
  } else {
    var r = (parseInt(gradient) >> 16) & 0xff;
    var g = (parseInt(gradient) >> 8) & 0xff;
    var b = parseInt(gradient) & 0xff;
    let topGradient = 'rgb(' + r + ',' + g + ',' + b + ')';
    return topGradient;
  }
}

export const parseGradients = (gradient:GlitchGradient|null) => {
  if(!gradient)
    return {}
  return Object.keys(gradient).reduce((acc:any,k:string) => {
    return {...acc, [k]: parseGradient(gradient[k])}
  }, {})
}

export const parseFilters = (filters:GlitchFilters) => {
  let tintColor = null;
  let tintAmount = null;

  return Object.keys(filters).reduce((acc:string[],k:string) => {
    let filterValue = filters[k];

    switch(k){
      case 'brightness':
        acc.push(`brightness(${1-(filterValue/-100)})`);
        break;
      case 'contrast':
        acc.push(`contrast(${1-(filterValue/-100)})`);
        break;

      case 'saturation':
        acc.push(`saturation(${1-(filterValue/-100)})`);
        break;

      case 'tintColor':
        tintColor = filterValue;
        break;
      case 'tintAmount':
        tintAmount = filterValue;
        break;
    }
    return acc
  }, [])

}

export const createLayerImage:(l:GlitchLayer,newCanvas?:fabric.StaticCanvas, useSvg?:boolean)=>Promise<fabric.StaticCanvas> = async (layer, newCanvas,useSvg=false) => {

  if(!newCanvas){
    const canvas = (typeof document != 'undefined') ? document.createElement('canvas') : null;
    newCanvas = new fabric.StaticCanvas(canvas);
  }

  newCanvas.setWidth(layer.w);
  newCanvas.setHeight(layer.h);

  // TODO declare global configuration object, and get the baseUrl from the config (so that in dev we use dev.viwoc.com)
  // let baseUrl = 'http://127.0.0.1:5050'; 
  // if(typeof document != 'undefined'){
  // }

  const [offset_x,offset_y] = layer.name == 'middleground' ? [layer.w/2, layer.h] : [0,0];

  for(let item of sortBy(layer.decos,(x)=>x.z)){

    let image:fabric.Object;

    let scaleX, scaleY;
    if(useSvg){
      const url= getDecoImageUrl(item)
      if(process.env.NODE_ENV == 'development')
        console.log("Requesting:", url);
      image = await loadFabricSvg(url);
      scaleX= item.w/(image.width || 1);
      scaleY= item.h/(image.height || 1);
    }else{
      const url = getDecoImageUrl(item)
      image = await loadFabricImage(url)
      const img:HTMLImageElement = (image as fabric.Image).getElement() as HTMLImageElement;
      scaleX= item.w/img.naturalWidth;
      scaleY= item.h/img.naturalHeight;
    }

    image.set({
      item: item,
      originX: 'center',
      originY: 'bottom',
      scaleX,
      scaleY,
      left: item.x + offset_x,
      top:  item.y + offset_y,
      angle: item.r,
      flipX: !!item.h_flip,
      flipY: !!item.v_flip
    } as any);
    newCanvas.add(image)
    newCanvas.calcOffset();
  }

  return newCanvas;
}

export const createLayerImage2:(id:string, l:GlitchLayer,x:(a:string)=>void)=>Promise<string> = (layerId, layer, err_cb) => {

  const newCanvas = document.createElement('canvas');
  newCanvas.width= layer.w;
  newCanvas.height= layer.h;

  const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, layer.h] : [0,0];
  const ctx:(CanvasRenderingContext2D | null) = newCanvas.getContext('2d');

  if(!ctx)
    throw "Error: could not get 2d cntext from canvas"

  return loadLayer(layer,ctx, err_cb,offset_x,offset_y).then(()=> {
    return newCanvas.toDataURL();
  })
}

export const createLayerImages:(game:GlitchGame)=>Promise<{[name:string]:string}> = async (game_json) => {
  const width= game_json.dynamic.r - game_json.dynamic.l;
  const height= game_json.dynamic.b - game_json.dynamic.t;

  let canvas;
  if(typeof document != 'undefined'){
    canvas = document.createElement('canvas');
  }else{
    canvas = null;
  }
  const k = new fabric.StaticCanvas(canvas);
  const backgrounds:{[name:string]:string}={};

  for(let id in game_json.dynamic.layers){
    k.clear();
    const layer = game_json.dynamic.layers[id];
    await createLayerImage(layer,k)
    backgrounds[layer.name] = k.toDataURL();
  }

  return backgrounds;

}

export const createCompatibleLayer:(id:string,l:GlitchLayer,url?:string)=>Promise<LayerAttributes> = async (layerId,layer,url) => {

  let src;
  if(url){
    src=url;
  }else{
    const canvas = document.createElement('canvas');
    const newCanvas = new fabric.StaticCanvas(canvas, {enableRetinaScaling: false});

    await createLayerImage(layer,newCanvas);

    src = newCanvas.toDataURL();
  }

  const retval = {
    id: layerId,
    z: layer.z,
    offset: layer.offset,
    filters: layer.filters,

    tile_width: layer.w,
    tile_height: layer.h,
    width: 1,
    height: 1,
    total_width: layer.w,
    total_height: layer.h,

    tiles: [{  name: layerId,
      x: 0, 
      y: 0, 
      events: {},
      id: layerId,
      space_id: 'asdf',
      public_filename: src,
    }]
  };

  return retval;

}

export const loadLayer = (layer:GlitchLayer, targetContext:CanvasRenderingContext2D, err_cb:(a:string)=>void, offset_x:number, offset_y:number) => {

  return sortBy(layer.decos,(x)=>x.z).reduce((p, item) => {

    return p.then(() => loadImage(item).then((image)=>{
      const drawnItem = drawItem(item, image)
      targetContext.drawImage(drawnItem, item.x - (drawnItem.width / 2) + offset_x, item.y - (drawnItem.height / 2) + offset_y, drawnItem.width, drawnItem.height);
    }, (error)=>{
      err_cb(item.filename)
    }));

  }, Promise.resolve())
}

export const drawItem:(deco:GlitchDeco,img:HTMLImageElement)=>HTMLCanvasElement = (item:GlitchDeco, newImg:HTMLImageElement) => {

  const sourceCanvas = $('<canvas>').attr({width: item.w, height: item.h * 2}) as JQuery<HTMLCanvasElement>;
  const sourceContext = sourceCanvas[0].getContext('2d');

  if(!sourceContext)
    throw "No 2d context could be get from canvas"

  if (item.h_flip) {
    // TODO take into account item.v_flip
    sourceContext.scale(-1, 1);
    sourceContext.drawImage(newImg, -item.w, 0, item.w, item.h);
  } else {
    sourceContext.drawImage(newImg, 0, 0, item.w, item.h);
  }

  if (!('r' in item))
    return sourceCanvas[0];

  /* it has rotation */
  const maxEdgeSize = Math.sqrt(Math.pow(item.h * 2, 2) + Math.pow(item.w, 2));

  let theCanvas = $('<canvas>').attr({width: maxEdgeSize, height: maxEdgeSize}) as JQuery<HTMLCanvasElement>;
  const ctx = theCanvas[0].getContext('2d');

  if(!ctx || !theCanvas)
    throw "No 2d context could be get from canvas"

  ctx.translate((theCanvas.width() || 0) / 2, (theCanvas.height() || 0) / 2);
  ctx.rotate(Math.PI/180 * item.r);
  ctx.drawImage(sourceCanvas[0], -item.w / 2, -item.h, item.w, item.h * 2);

  return theCanvas[0];
}

export const loadImage:(d:GlitchDeco)=>Promise<HTMLImageElement> = (item) => {
  // TODO declare global configuration object, and get the baseUrl from the config (so that in dev we use dev.viwoc.com)

  return new Promise((accept,reject) => {
    var newImg = $('<img>').css({
      'position': 'absolute',
      'width': item.w,
      'height': item.h,
      'left': 0,
      'top': 0
    }).attr('crossorigin', 'Anonymous') as JQuery<HTMLImageElement>;

    newImg.bind('error', reject)
    newImg.bind('load', ()=>accept(newImg[0]))
    $('.offscreenBuffer').append(newImg);
    const url = getDecoImageUrl(item)
    newImg.attr('src', url);
  })
}

export const loadFabricSvg:(src:string)=>Promise<fabric.Object> = (src) => new Promise((accept,reject) => {
  fabric.loadSVGFromURL(src, (objects:any, options, /*, error:boolean*/)=>{
    if(!objects){
      reject();
      return;
    }
    const error=false;
    if(error){
      reject();
      return;
    }
    let obj:any=fabric.util.groupSVGElements(objects, options);
    obj.naturalWidth = options.width;
    obj.naturalHeight = options.height;
    accept(obj);
  })
})

export const loadFabricImage:(src:string)=>Promise<fabric.Image> = (src) => new Promise((accept,reject) => {
  // @ts-ignore
  fabric.Image.fromURL(src, (image:any, error:boolean)=>{
    if(error){
      reject();
      return;
    }
    accept(image);
  }, {crossOrigin: 'anonymous'})
})


export const linesStyles = {
  walls: {
    stroke: 'red',
    strokeWidth: 8,
    strokeLineCap: 'round',
    opacity: .8,
  },
  ladders: {
    stroke: 'blue',
    fill: 'transparent',
    strokeWidth: 8,
    strokeLineCap: 'round',
    opacity: .8,
  },
  signpost: {
    fill: 'red',
    opacity: .8,
    radius: 10,
  },
  platform_lines: {
    stroke: 'fuchsia',
    strokeWidth: 8,
    strokeLineCap: 'round',
    opacity: .8,
  },
};

export const renderLayer = async (gameData:GlitchGame, layerId:string, canvas:fabric.Canvas) => {

  const layer:GlitchLayer = gameData.dynamic.layers[layerId];
  const stageHeight = gameData.dynamic.b - gameData.dynamic.t;

  const stageOffset = {
    x: 0,
    y: stageHeight - layer.h,
  }

  const offset = {
    x: stageOffset.x + (layer.name == 'middleground' ? layer.w/2 : 0),
    y: stageOffset.y + (layer.name == 'middleground' ? layer.h : 0)
  }

  const decos = sortBy(layer.decos,(x)=>x.z);

  for(let i=0, len=decos.length;i<len;i++){

    const item = decos[i];
    try{
      const img = await LoadManager.instance.loadFabricImage2(getDecoImageUrl(item));
      const image = deco2Fabric(item, gameData, layerId, img);
      const id = item.id || `deco_${v4()}`;

      if(item.sprites?.length){
        const sprite = item.sprites[0];
        const aseprite = await LoadManager.instance.fetch(sprite.aseprite_url).then((r)=>r.json());
        const frame= aseprite.frames['0'].frame;
        const scaleX= item.w/frame.w;
        const scaleY= item.h/frame.h;
        image.set({
          width: frame.w,
          height: frame.h,
          cropX: frame.x,
          cropY: frame.y,
          scaleX,
          scaleY,
        });

      }

      image.set({id} as any)
      canvas.add(image)
      canvas.calcOffset();
    }catch(e){
      console.error(e)
      continue;
    }

  }

  layer.walls.forEach((wall:GlitchWall,i) => {
    const {x,y,w,h, id} = wall;
    //const l= new fabric.Line([offset.x+x, offset.y+y, offset.x+x+w, offset.y+y+h], { ...linesStyles.walls })
    const l= new fabric.Rect({left: offset.x+x, top: offset.y+y, width: w, height: h,
      originX: 'center',
      originY: 'bottom',
      ...linesStyles.walls,
    })

    l.set({ id, glitch_type: 'wall', item: wall, layer_id: layerId,} as any)
    canvas.add(l)
  })

  layer.ladders.forEach((ladder,i) => {
    const {id, x,y,w,h} = ladder;
    const l= new fabric.Rect({left: offset.x+x, top: offset.y+y, width: w, height: h,
      originX: 'center',
      originY: 'bottom',
      ...linesStyles.ladders,
    })
    l.set({id, glitch_type: 'ladder', item: ladder, layer_id: layerId,} as any)
    canvas.add(l)
  })

  layer.platformLines.forEach((pf,i) => {
    const {id} = pf;
    const [start, end] = [...pf.endpoints].sort((a,b) => { return a.name == 'start' ? -1 : 1});
    const l=new fabric.Line([offset.x+start.x, offset.y+start.y, offset.x+end.x, offset.y+end.y], { ...linesStyles.platform_lines });
    l.set({id, glitch_type: 'platform_line', item: pf, layer_id: layerId,} as any)
    canvas.add(l)
  })

  layer.signposts.forEach(async (item,i) => {
    const {id,name,  x,y,w,h, url} = item;

    if(url){
      const image = await LoadManager.instance.loadFabricImage2(getDecoImageUrl(item));
      const img:HTMLImageElement = image.getElement() as HTMLImageElement;
      const scaleX= item.w/img.naturalWidth;
      const scaleY= item.h/img.naturalHeight;

      image.set({
        originX: 'center',
        originY: 'bottom',
        scaleX,
        scaleY,
        left: item.x + offset.x,
        top:  item.y + offset.y,
        angle: item.r,
        flipX: !!item.h_flip,
        flipY: !!item.v_flip,
      });

      image.set({
        //type: 'signpost',
        glitch_type: 'signpost',
        layer_id: 'middleground',
        item: item,
        [Z_IDX]: layer.z*1000+999,
      } as any)

      canvas.add(image)
      canvas.calcOffset();

    } else {
      const l= new fabric.Circle({left: offset.x+x, top: offset.y+y, ...linesStyles.signpost})
      l.set({id, glitch_type: 'signpost', item: item, layer_id: layerId,} as any)
      canvas.add(l)
    }
  })

  canvas.requestRenderAll();

  return canvas
}

export const loadFabricImage2:(src:string)=>Promise<fabric.Image> = (src) => {

  return new Promise((accept,reject) => {
    //@ts-ignore
    fabric.Image.fromURL(src, (image:any, error:boolean)=>{
      if(error){
        reject();
        return
      }
      image.needsItsOwnCache=()=>true;
      accept(image);
    }, {crossOrigin: 'anonymous'})
  })

}

export const getLayerName = (gameData,layerId) => {
  return gameData?.dynamic?.layers ? gameData.dynamic.layers[layerId]?.name : null;
}

const getUrl = (uri_or_path) => {
  if(uri_or_path.startsWith('http')){
    return new URL(uri_or_path)
  } else {
    return new URL(uri_or_path, baseUrl)
  }
}

export const getDecoImageUrl = (item:GlitchDeco| GlitchSignpost):string => {
  if(item.url){
    const url = getUrl(item.url);
    return url.toString();
  }
  
  return `${baseUrl}/glitch/assets/${item.filename}.svg?safe=true`
}

export const deco2Fabric = (deco:GlitchDeco, gameData:GlitchGame, layerId:string, image:fabric.Image) => {

  const layer = gameData.dynamic.layers[layerId];
  const stageHeight = gameData.dynamic.b - gameData.dynamic.t;
  const heightDiff = stageHeight - layer.h;
  // the layer whose ID is middleground, has each sprite's origin at the center,bottom of the layer
  const [offset_x,offset_y] = layerId == 'middleground' ? [layer.w/2, heightDiff + layer.h] : [0,heightDiff];

  const img:HTMLImageElement = image.getElement() as HTMLImageElement;
  const scaleX= deco.w/img.naturalWidth;
  const scaleY= deco.h/img.naturalHeight;

  const middlegroundWidth = gameData.dynamic.layers.middleground.w;
  const layerWidth = layer.w;
  const diff = middlegroundWidth - layerWidth;
  const parallax = diff / middlegroundWidth;

  // In glitch, the origin of the sprites is at (center,bottom), for translation and for rotation.

  image.set({
    originX: 'center',
    originY: 'bottom',
    scaleX,
    scaleY,
    left: deco.x + offset_x,
    top:  deco.y + offset_y,
    flipX: !!deco.h_flip,
    flipY: !!deco.v_flip,
  });

  // after the originX and originY are set, we can rotate the image
  image.rotate(fabric.util.degreesToRadians(deco.r));

  // now set our custom properties
  image.set({
    //type: 'deco',
    glitch_type: 'deco',
    parallax,
    layer_name: layer.name,
    layer_id: layerId,
    [Z_IDX]: layer.z*1000+deco.z,
    offset_x,
    offset_y,
    item: deco,
  } as any)
  return image
}

export const highlightSelectedObject = (canvas: fabric.Canvas) => {

  const _setFilter = (e: fabric.Object) => {
    if(e.type != 'image')return

    var clonedImage = fabric.util.object.clone(e);
    clonedImage.scale(1.1);

    const f1= [
      new fabric.Image.filters.Blur({blur: 0.5}),
      new fabric.Image.filters.BlendColor({
        color: 'rgb(0,255,0)',
        mode: 'tint'
      }),
      //@ts-ignore
      new BlendImage2({
        image: clonedImage,
        mode: 'visibility',
        alpha: 0.5
      })];
    const imgInstance = e as fabric.Image;
    imgInstance.filters=f1;
    //imgInstance.filters.push(new fabric.Image.filters.Blur());
    //imgInstance.filters.push(new fabric.Image.filters.Grayscale());
    imgInstance.applyFilters();
  }

  const setShadow = (e: fabric.Object) => {
    if(e.type != 'image')return
    var shadow = new fabric.Shadow({
      color: 'rgba(0,255,0,1)',  // Shadow color
      blur: 3,                // How much to blur the shadow
      offsetX: 2,             // Horizontal shadow offset
      offsetY: 2              // Vertical shadow offset
    });
    const imgInstance = e as fabric.Image;
    imgInstance.set('shadow', shadow);
  }

  canvas.on('selection:created', (e) => {
    console.log('Selection Created:',e)
    e.selected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return
      setShadow(obj);
      //setFilter(obj)
    })
    canvas.requestRenderAll();
  })

  canvas.on('selection:updated', (e) => {
    console.log('Selection Updated:',e.selected[0])
    e.selected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return;
      setShadow(obj)
      //setFilter(obj)
    })

    e.deselected.forEach((obj:fabric.Object) => {
      if(obj.type != 'image')return
      obj.set('shadow', null);
      //@ts-ignore
      //obj.filters= []
      ////@ts-ignore
      //obj.applyFilters();
    })
    canvas.requestRenderAll()
  })

  canvas.on('selection:cleared', (e) => {
    console.log('Selection Cleared:',e)
    canvas.getObjects().forEach((obj) => {
      if(obj.type != 'image')return
      obj.set('shadow', null);
      //@ts-ignore
      obj.filters= []
      //@ts-ignore
      obj.applyFilters();
    })
    canvas.requestRenderAll();
  })

};



